import React, { useState, useEffect } from "react";
import {
  Button,
  DatePicker,
  Form,
  Input,
  message,
  Space,
  Select,
  Typography,
  Tabs,
  Table,
  Row,
  Col,
} from "antd";
import moment from "moment";
import bookingService from "src/services/bookingService";
import { StringDate } from "src/utils";
import { Link } from "react-router-dom";
import DrawerLineHaul from "src/components/DrawerLineHaul";
import DrawerDistribute from "src/components/DrawerDistribute";
import { useHistory } from "react-router";
import { getUserSession } from "src/store/authen/action";

const TableBookedLineHaul: React.FC<{
  data: any;
}> = (props: any) => {
  const { data } = props;
  const history = useHistory();
  const columns = [
    {
      title: "ลำดับ",
      dataIndex: "seq",
      key: "seq",
      width: 50,
      align: "center" as "center",
    },
    {
      title: "เลขที่การจอง",
      dataIndex: "bookNo",
      key: "bookNo",
      render: (text: any, props: any) => {
        return (
          // <Link to={`/app/booking/bookingLineHaul/${props.id}`}>{text}</Link>
          <div
            style={{ color: "blue", cursor: "pointer" }}
            onClick={() =>
              history.push({
                pathname: `/app/booking/bookingLineHaul/${props.id}`,
                state: { bookNo: "", from: "bookingData" },
              })
            }
          >
            {text}
          </div>
        );
      },
    },
    {
      title: "วันที่จอง",
      dataIndex: "bookDate",
      key: "bookDate",
    },
    {
      title: "ขนิดรถ",
      dataIndex: "truckTypeName",
      key: "truckTypeName",
    },
    {
      title: "วันที่ใช้งาน",
      dataIndex: "startDate",
      key: "startDate",
    },
    {
      title: "รับ-ส่ง",
      dataIndex: "inoutStation",
      key: "inoutStation",
      render: (text: any, prosp: any) => {
        const result = text === "IN" ? "ภายใน" : "ภายนอก";
        return <div>{result}</div>;
      },
    },
    {
      title: "ผู้จอง",
      dataIndex: "createUsername",
      key: "createUsername",
    },
  ];

  return (
    <>
      {data.length > 0 ? (
        <Table
          dataSource={data}
          columns={columns}
          size="small"
          rowKey={(obj: any) => obj.id}
        />
      ) : (
        <Table
          // dataSource={null}
          columns={columns}
          size="small"
          // rowKey={(obj: any) => obj.id}
        />
      )}
    </>
  );
};

const TableConfirmLineHaul: React.FC<{
  data: any;
}> = (props: any) => {
  const { data } = props;
  const columns = [
    {
      title: "ลำดับ",
      dataIndex: "seq",
      key: "seq",
      width: 50,
      align: "center" as "center",
    },
    {
      title: "เลขที่การจอง",
      dataIndex: "bookNo",
      key: "bookNo",
      render: (text: any, props: any) => {
        return (
          <Link to={`/app/booking/bookingLineHaul/${props.id}`}>{text}</Link>
        );
      },
    },
    {
      title: "วันที่จอง",
      dataIndex: "bookDate",
      key: "bookDate",
    },
    {
      title: "ขนิดรถ",
      dataIndex: "truckTypeName",
      key: "truckTypeName",
    },
    {
      title: "ทะเบียนรถ",
      dataIndex: "registId",
      key: "registId",
    },
    {
      title: "พนักงานขับรถ",
      dataIndex: "driverName",
      key: "driverName",
    },
    {
      title: "หุ้นส่วน(Partner)",
      dataIndex: "partnerName",
      key: "partnerName",
    },
    {
      title: "วันที่ใช้งาน",
      dataIndex: "startDate",
      key: "startDate",
    },
    {
      title: "ผู้จอง",
      dataIndex: "createUsername",
      key: "createUsername",
    },
  ];

  return (
    <>
      {data.length > 0 ? (
        <Table
          dataSource={data}
          columns={columns}
          size="small"
          rowKey={(obj: any) => obj.id}
        />
      ) : (
        <Table
          // dataSource={null}
          columns={columns}
          size="small"
          // rowKey={(obj: any) => obj.id}
        />
      )}
    </>
  );
};

const TableDistribute: React.FC<{
  data: any;
}> = (props: any) => {
  const { data } = props;

  const columns = [
    {
      title: "ลำดับ",
      dataIndex: "seq",
      key: "seq",
      width: 50,
      align: "center" as "center",
    },
    {
      title: "เลขที่การจอง",
      dataIndex: "bookNo",
      key: "bookNo",
      render: (text: any, props: any) => {
        return (
          <Link
            to={{
              pathname: "/app/booking/bookingDistribute",
              state: {
                bookType: "bookingDistribute",
                action: "EDIT",
                id: props.id,
              },
            }}
          >
            {text}
          </Link>
        );
      },
    },
    {
      title: "วันที่จอง",
      dataIndex: "bookDate",
      key: "bookDate",
    },
    {
      title: "ขนิดรถ",
      dataIndex: "truckTypeName",
      key: "truckTypeName",
    },
    {
      title: "ทะเบียนรถ",
      dataIndex: "registId",
      key: "registId",
    },
    {
      title: "พนักงานขับรถ",
      dataIndex: "driverFullname",
      key: "driverFullname",
    },
    {
      title: "วันที่ใช้งาน",
      dataIndex: "startDate",
      key: "startDate",
    },
    {
      title: "ผู้จอง",
      dataIndex: "createUsername",
      key: "createUsername",
    },
  ];

  return (
    <>
      {data.length > 0 ? (
        <Table
          dataSource={data}
          columns={columns}
          size="small"
          rowKey={(obj: any) => obj.id}
        />
      ) : (
        <Table
          // dataSource={null}
          columns={columns}
          size="small"
          // rowKey={(obj: any) => obj.id}
        />
      )}
    </>
  );
};

const BookingData: React.FC = React.memo((props: any) => {
  const [bookedList, setBookedList] = useState<any>([]);
  const [bookedConfirmList, setBookedConfirmList] = useState<any>([]);
  const [distrubuteList, setDistributeList] = useState<any>([]);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [partnerList, setPartnerList] = useState<any>([]);
  const [truckRegistration, setTruckRegistration] = useState<any>([]);
  const [truckList, setTruckList] = useState<any>([]);

  const [formBook] = Form.useForm();
  const [formConfirm] = Form.useForm();
  const [formDistribute] = Form.useForm();

  const dateFormat = "DD/MM/YYYY";
  const { Title } = Typography;
  const { Option } = Select;
  const { TabPane } = Tabs;

  const history = useHistory();

  useEffect(() => {
    if (!loaded) {
      init();
      setLoaded(true);
    }
  }, []);

  const init = () => {
    setLoading(true);
    if (getUserSession() === null) {
      history.replace("/login");
      return;
    }
    findTruckAll();
    findPartnerAll();
    findTruckRegistrationAll();
    defaultDate();
    findBookedData();
    setLoading(false);
  };

  const defaultDate = () => {
    formBook.setFieldsValue({
      startDate: moment(new Date(), "YYYYMMDD"),
      endDate: moment(new Date(), "YYYYMMDD"),
    });

    formConfirm.setFieldsValue({
      startDate: moment(new Date(), "YYYYMMDD"),
      endDate: moment(new Date(), "YYYYMMDD"),
    });

    formDistribute.setFieldsValue({
      startDate: moment(new Date(), "YYYYMMDD"),
      endDate: moment(new Date(), "YYYYMMDD"),
    });
  };

  const findBookedData = async () => {
    let now = moment(new Date()).format("YYYYMMDD");
    let data = {
      endDate: now,
      startDate: now,
    };
    const response = await bookingService.findBookByBooked(data);
    if (response.data.length > 0) {
      for (let i = 0; i < response.data.length; i++) {
        let formatBookDate = StringDate(response.data[i].bookDate, "TH", "/");
        let formatStartDate = StringDate(response.data[i].startDate, "TH", "/");
        response.data[i].seq = i + 1;
        response.data[i].bookDate = formatBookDate;
        response.data[i].startDate = formatStartDate;
      }
      setBookedList(response.data);
    } else {
      message.warn("ไม่พบข้อมูล");
    }
  };

  const findBookedConfirm = async () => {
    let now = moment(new Date()).format("YYYYMMDD");
    let data = {
      endDate: now,
      startDate: now,
    };
    const response = await bookingService.findBookByConfirmed(data);
    if (response.data.length > 0) {
      for (let i = 0; i < response.data.length; i++) {
        let driverFname = response.data[i].driverFirstname;
        let driverLname = response.data[i].driverLastname;
        response.data[i].driverName = `${driverFname} ${driverLname}`;
        let formatBookDate = StringDate(response.data[i].bookDate, "TH", "/");
        let formatStartDate = StringDate(response.data[i].startDate, "TH", "/");
        response.data[i].bookDate = formatBookDate;
        response.data[i].startDate = formatStartDate;
      }
      setBookedConfirmList(response.data);
    } else {
      message.warn("ไม่พบข้อมูล");
    }
  };

  const findTruckAll = async () => {
    const response = await bookingService.findTruckAll();
    if (response.data) {
      setTruckList(response.data);
    }
  };

  const findPartnerAll = async () => {
    const response = await bookingService.findPartnerAll();
    if (response.data) {
      setPartnerList(response.data);
    }
  };

  const findTruckRegistrationAll = async () => {
    const response = await bookingService.findTruckRegistrationAll();
    if (response.data) {
      setTruckRegistration(response.data);
    }
  };

  const onFinishFormBook = async (fieldsValue: any) => {
    const values = {
      ...fieldsValue,
      startDate: fieldsValue["startDate"].format("YYYYMMDD"),
      endDate: fieldsValue["endDate"].format("YYYYMMDD"),
    };
    const { startDate, endDate, bookNo, truckType } = values;
    let data = {
      bookNo: bookNo === undefined ? null : bookNo,
      endDate,
      startDate,
      truckTypeId: truckType === undefined ? null : truckType,
    };
    // console.log(data);
    const response = await bookingService.findBookByBooked(data);
    if (response.data.length > 0) {
      for (let i = 0; i < response.data.length; i++) {
        let formatBookDate = StringDate(response.data[i].bookDate, "TH", "/");
        let formatStartDate = StringDate(response.data[i].startDate, "TH", "/");
        response.data[i].bookDate = formatBookDate;
        response.data[i].startDate = formatStartDate;
      }
      setBookedList(response.data);
    } else {
      message.warning("ไม่พบข้อมูล");
    }
  };

  const onFinishFormConfirm = async (fieldsValue: any) => {
    const values = {
      ...fieldsValue,
      startDate: fieldsValue["startDate"].format("YYYYMMDD"),
      endDate: fieldsValue["endDate"].format("YYYYMMDD"),
    };
    // console.log(values);
    const { bookNo, driver, endDate, partner, registId, startDate, truckType } =
      values;

    let data = {
      bookNo: bookNo === "" ? null : bookNo,
      driverName: driver === undefined ? null : driver,
      endDate,
      partnerId: partner === undefined ? null : partner,
      registId: registId === undefined ? null : registId,
      startDate,
      truckTypeId: truckType === undefined ? null : truckType,
    };
    // console.log(data);
    const response = await bookingService.findBookByConfirmed(data);
    if (response.data.length > 0) {
      for (let i = 0; i < response.data.length; i++) {
        let driverFname = response.data[i].driverFirstname;
        let driverLname = response.data[i].driverLastname;
        response.data[i].driverName = `${driverFname} ${driverLname}`;
        let formatBookDate = StringDate(response.data[i].bookDate, "TH", "/");
        let formatStartDate = StringDate(response.data[i].startDate, "TH", "/");
        response.data[i].seq = i + 1;
        response.data[i].bookDate = formatBookDate;
        response.data[i].startDate = formatStartDate;
      }
      setBookedConfirmList(response.data);
    } else {
      message.warning("ไม่พบข้อมูล");
    }
  };

  const onFinishFormDistribute = async (fieldsValue: any) => {
    const values = {
      ...fieldsValue,
      startDate: fieldsValue["startDate"].format("YYYYMMDD"),
      endDate: fieldsValue["endDate"].format("YYYYMMDD"),
    };
    const { bookNo, driver, endDate, registId, startDate, truckType } = values;

    const data = {
      bookNo: bookNo === undefined ? null : bookNo,
      startDate,
      endDate,
      truckTypeId: truckType === undefined ? null : truckType,
      registId: registId === undefined ? null : registId,
      driverName: driver === undefined ? null : driver,
    };
    // console.log(data);
    const response = await bookingService.findBookDistribute(data);
    if (response.data.length > 0) {
      // console.log(response.data);
      for (let i = 0; i < response.data.length; i++) {
        let formatBookDate = StringDate(response.data[i].bookDate, "TH", "/");
        let formatStartDate = StringDate(response.data[i].startDate, "TH", "/");
        response.data[i].seq = i + 1;
        response.data[i].bookDate = formatBookDate;
        response.data[i].startDate = formatStartDate;
      }
      setDistributeList(response.data);
    } else {
      message.warning("ไม่พบข้อมูล");
    }
  };

  const onFinishFailedFormDistribute = (errorInfo: any) => {
    console.log("Failed form distribute :", errorInfo);
  };

  const onFinishFailedFormConfirm = (errorInfo: any) => {
    console.log("Failed form confirm :", errorInfo);
  };

  const onFinishFailedFormBook = (errorInfo: any) => {
    console.log("Failed form book :", errorInfo);
  };

  const handleShowBookedDataLineHaul = (key: any) => {
    if (key === "LH1") {
      findBookedData();
    } else if (key === "LH2") {
      findBookedConfirm();
    }
  };

  const onChangeTabs = (key: any) => {
    // console.log(key);
  };

  const tabLineHaul = () => {
    return (
      <Tabs
        defaultActiveKey="1"
        onChange={onChangeTabs}
        size="small"
        onTabClick={(e) => handleShowBookedDataLineHaul(e)}
        type="card"
      >
        <TabPane tab="จอง" key="LH1">
          <div>
            <Form
              name="formBook"
              form={formBook}
              // labelCol={{ span: 8 }}
              // wrapperCol={{ span: 16 }}
              // initialValues={{ remember: true }}
              onFinish={onFinishFormBook}
              onFinishFailed={onFinishFailedFormBook}
              autoComplete="off"
            >
              <Row gutter={16} style={{ color: "#000" }}>
                <Col span={6}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      marginTop: 7,
                    }}
                  >
                    วันที่จอง
                  </div>
                </Col>
                <Col span={6}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <Form.Item
                        label="ตั้งแต่"
                        name="startDate"
                        rules={[
                          {
                            required: true,
                            message: "กรุณาระบุ วันที่จอง ตั้งแต่!",
                          },
                        ]}
                      >
                        <DatePicker
                          style={{ width: 170 }}
                          format={dateFormat}
                          placeholder="เลือกวันที่"
                        />
                      </Form.Item>
                    </div>
                  </div>
                </Col>
                <Col span={6}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <div>
                      <Form.Item label="เลขที่การจอง" name="bookNo">
                        <Input placeholder="เลขที่การจอง" />
                      </Form.Item>
                    </div>
                  </div>
                </Col>
                <Col span={6}>
                  <div style={{}}></div>
                </Col>
              </Row>

              <Row gutter={16} style={{ marginTop: -7 }}>
                <Col span={6}></Col>
                <Col span={6}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ marginLeft: 17 }}>
                      <Form.Item
                        label="ถึง"
                        name="endDate"
                        rules={[
                          {
                            required: true,
                            message: "กรุณาระบุ วันที่จอง ถึง!",
                          },
                        ]}
                      >
                        <DatePicker
                          style={{ width: 170 }}
                          format={dateFormat}
                          placeholder="เลือกวันที่"
                        />
                      </Form.Item>
                    </div>
                  </div>
                </Col>
                <Col span={6}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <div>
                      <Form.Item label="ชนิดรถ" name="truckType">
                        <Select
                          placeholder="ชนิดรถ"
                          style={{ width: 170 }}
                          // onChange={(val: any) =>
                          //   handleChangeSelect(val, "truck")
                          // }
                        >
                          {truckList.map((item: any) => (
                            <Option key={item.id} value={item.id}>
                              {item.typeName}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </Col>
                <Col span={6}>
                  <div style={{}}></div>
                </Col>
              </Row>

              <div style={{ textAlign: "center" }}>
                <Form.Item wrapperCol={{}}>
                  <Button type="primary" size="middle" htmlType="submit">
                    ค้นหา
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </div>
          <TableBookedLineHaul data={bookedList} />
        </TabPane>

        {/* Booking confirm */}
        <TabPane tab="ยืนยันการจอง" key="LH2">
          <div style={{ textAlign: "center" }}>
            <Form
              name="formConfirm"
              form={formConfirm}
              // labelCol={{ span: 8 }}
              // wrapperCol={{ span: 16 }}
              // initialValues={{ remember: true }}
              onFinish={onFinishFormConfirm}
              onFinishFailed={onFinishFailedFormConfirm}
              autoComplete="off"
            >
              <Space direction="vertical">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div style={{ width: 70, marginTop: -13 }}>วันที่จอง</div>
                  <div style={{ marginRight: "2rem" }}>
                    <Form.Item
                      label="ตั้งแต่"
                      name="startDate"
                      rules={[
                        {
                          required: true,
                          message: "กรุณาระบุ วันที่จอง ตั้งแต่!",
                        },
                      ]}
                    >
                      <DatePicker
                        style={{ width: 170 }}
                        format={dateFormat}
                        placeholder="เลือกวันที่"
                      />
                    </Form.Item>
                  </div>

                  <div style={{ marginRight: 67 }}>
                    <Form.Item label="เลขที่การจอง" name="bookNo">
                      <Input placeholder="เลขที่การจอง" />
                    </Form.Item>
                  </div>

                  <div>
                    <Form.Item label="ชนิดรถ" name="truckType">
                      <Select
                        placeholder="เลือกชนิดรถ"
                        style={{ width: 170 }}
                        // onChange={(val: any) =>
                        //   handleChangeSelect(val, "truck")
                        // }
                      >
                        {truckList.map((item: any) => (
                          <Option key={item.id} value={item.id}>
                            {item.typeName}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: -15,
                  }}
                >
                  <div style={{ width: 70 }}></div>
                  <div style={{ marginRight: 56, marginLeft: 15 }}>
                    <Form.Item
                      label="ถึง"
                      name="endDate"
                      rules={[
                        {
                          required: true,
                          message: "กรุณาระบุ วันที่จอง ถึง",
                        },
                      ]}
                    >
                      <DatePicker
                        style={{ width: 170 }}
                        format={dateFormat}
                        placeholder="เลือกวันที่"
                      />
                    </Form.Item>
                  </div>

                  <div style={{ marginRight: 50 }}>
                    <Form.Item label="Partner" name="partner">
                      <Select
                        placeholder="เลือก partner"
                        style={{ width: 170 }}
                      >
                        {partnerList.map((item: any) => (
                          <Option key={item.id} value={item.partnerUId}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>

                  <div>
                    <Form.Item label="ทะเบียนรถ" name="registId">
                      <Select style={{ width: 170 }} placeholder="ทะเบียนรถ">
                        {truckRegistration.map((item: any) => (
                          <Option key={item.id} value={item.registId}>
                            {item.registId}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: -14,
                  }}
                >
                  <div style={{ width: 70 }}></div>
                  <div
                    style={{ marginRight: 5, width: 40, textAlign: "right" }}
                  >
                    {/* ถึง */}
                  </div>
                  <div style={{ marginRight: 34, width: 170 }}>
                    {/* <DatePicker
                    style={{ width: 170 }}
                    // defaultValue={moment(new Date(), dateFormat)}
                    format={dateFormat}
                    onChange={(date: any, dateString: any) =>
                      handleChangeDatePicker(date, dateString, "endDate")
                    }
                    placeholder="เลือกวันที่"
                  /> */}
                  </div>
                  <div>
                    <Form.Item label="พนักงานขับรถ" name="driver">
                      <Input placeholder="พนักงานขับรถ" />
                    </Form.Item>
                  </div>

                  {/* <div style={{ marginRight: 5, width: 80, textAlign: "right" }}>
                  ทะเบียนรถ
                </div>
                <div>
                  <Select
                    defaultValue="-1"
                    style={{ width: 170 }}
                    onChange={(val: any) => handleChangeSelect(val, "regist")}
                  >
                    <Option value="-1">เลือกทะเบียนรถ</Option>
                    {truckRegistration.map((item: any) => (
                      <Option key={item.id} value={item.registId}>
                        {item.registId}
                      </Option>
                    ))}
                  </Select>
                </div> */}
                </div>

                <div
                  style={{
                    textAlign: "center",
                    marginTop: 20,
                    marginBottom: 40,
                  }}
                >
                  <Form.Item wrapperCol={{}}>
                    <Button type="primary" size="middle" htmlType="submit">
                      ค้นหา
                    </Button>
                  </Form.Item>
                </div>
              </Space>
            </Form>
          </div>

          <TableConfirmLineHaul data={bookedConfirmList} />
        </TabPane>
      </Tabs>
    );
  };
  /*
  
  */
  const tabDistribute = () => {
    return (
      <div>
        <Form
          name="formDistribute"
          form={formDistribute}
          // labelCol={{ span: 8 }}
          // wrapperCol={{ span: 16 }}
          // initialValues={{ remember: true }}
          onFinish={onFinishFormDistribute}
          onFinishFailed={onFinishFailedFormDistribute}
          autoComplete="off"
        >
          <Row gutter={16}>
            <Col span={6}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  marginTop: 6,
                }}
              >
                วันที่จอง
              </div>
            </Col>
            <Col span={6}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div style={{ marginRight: 20, marginLeft: 30 }}>
                  <Form.Item
                    label="ตั้งแต่"
                    name="startDate"
                    rules={[
                      {
                        required: true,
                        message: "กรุณาระบุ วันที่จอง ตั้งแต่!",
                      },
                    ]}
                  >
                    <DatePicker
                      style={{ width: 170 }}
                      format={dateFormat}
                      placeholder="เลือกวันที่"
                    />
                  </Form.Item>
                </div>
              </div>
            </Col>
            <Col span={6}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <div>
                  <Form.Item label="ชนิดรถ" name="truckType">
                    <Select placeholder="ชนิดรถ" style={{ width: 170 }}>
                      {truckList.map((item: any) => (
                        <Option key={item.id} value={item.id}>
                          {item.typeName}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>
            </Col>
            <Col span={6}></Col>
          </Row>

          <Row gutter={16} style={{ marginTop: -6 }}>
            <Col span={6}></Col>
            <Col span={6}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div style={{ marginRight: 20, marginLeft: 45 }}>
                  <Form.Item
                    label="ถึง"
                    name="endDate"
                    rules={[
                      {
                        required: true,
                        message: "กรุณาระบุ วันที่จอง ถึง!",
                      },
                    ]}
                  >
                    <DatePicker
                      style={{ width: 170 }}
                      format={dateFormat}
                      placeholder="เลือกวันที่"
                    />
                  </Form.Item>
                </div>
              </div>
            </Col>
            <Col span={6}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <div>
                  <Form.Item label="ทะเบียนรถ" name="registId">
                    <Select style={{ width: 170 }} placeholder="ทะเบียนรถ">
                      {truckRegistration.map((item: any) => (
                        <Option key={item.id} value={item.registId}>
                          {item.registId}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>
            </Col>
            <Col span={6}></Col>
          </Row>

          <Row
            gutter={16}
            style={{ fontWeight: "bold", fontSize: 14, marginTop: -6 }}
          >
            <Col span={6}></Col>
            <Col span={6}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div style={{ marginRight: 20 }}>
                  <Form.Item label="เลขที่การจอง" name="bookNo">
                    <Input placeholder="เลขที่การจอง" />
                  </Form.Item>
                </div>
              </div>
            </Col>
            <Col span={6}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <div>
                  <Form.Item label="พนักงานขับรถ" name="dirver">
                    <Input placeholder="พนักงานขับรถ" />
                  </Form.Item>
                </div>
              </div>
            </Col>
            <Col span={6}></Col>
          </Row>

          <div
            style={{
              textAlign: "center",
              marginTop: 20,
              marginBottom: 40,
            }}
          >
            <Form.Item wrapperCol={{}}>
              <Button type="primary" size="middle" htmlType="submit">
                ค้นหา
              </Button>
            </Form.Item>
          </div>

          <TableDistribute data={distrubuteList} />
        </Form>
      </div>
    );
  };

  const findDistribute = async () => {
    const data = {
      startDate: moment(new Date()).format("YYYYMMDD"),
      endDate: moment(new Date()).format("YYYYMMDD"),
    };
    // console.log(data);
    const response = await bookingService.findBookDistribute(data);
    if (response.data.length > 0) {
      // console.log(response.data);
      for (let i = 0; i < response.data.length; i++) {
        let formatBookDate = StringDate(response.data[i].bookDate, "TH", "/");
        let formatStartDate = StringDate(response.data[i].startDate, "TH", "/");
        response.data[i].seq = i + 1;
        response.data[i].bookDate = formatBookDate;
        response.data[i].startDate = formatStartDate;
      }
      setDistributeList(response.data);
    } else {
      message.warning("ไม่พบข้อมูล");
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#fff",
        borderRadius: "5",
        padding: 10,
        width: "100%",
        height: "100%",
      }}
    >
      <Title level={4} style={{ fontWeight: "bold" }}>
        ข้อมูลการจองรถบรรทุก
      </Title>
      <Tabs
        defaultActiveKey={
          props.location.state === undefined ? "1" : props.location.state.id
        }
        onChange={(key) => {
          if (key === "1") {
          } else if (key === "2") {
            findDistribute();
          }
        }}
        onTabClick={(key: any) => console.log(key)}
        size="small"
        type="card"
      >
        <TabPane tab="วิ่งระยะใกล" key="1">
          {tabLineHaul()}
        </TabPane>
        <TabPane tab="วิ่งกระจาย" key="2">
          {tabDistribute()}
        </TabPane>
      </Tabs>
    </div>
  );
});

export default BookingData;
