import React, { useState, useEffect } from "react";

import { Button, Form, Select, Space, Typography } from "antd";

import { useHistory } from "react-router-dom";
import testService from "src/services/testService";
import axios from "axios";

const BookingTest: React.FC = (props: any) => {
  const [bookNo, setBookNo] = useState<any>("");
  const [drivers, setDrivers] = useState<any>([]);
  const [holdData, setHoldData] = useState<any>([]);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [stations, setStations] = useState<any>([]);
  const [stationList, setStationList] = useState<any>([]);
  const [truckRegisters, setTruckRegisters] = useState<any>([]);
  const [trigger, setTrigger] = useState<number>(0);

  const history = useHistory();
  const [form] = Form.useForm();

  const { Title } = Typography;
  const { Option } = Select;

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!loaded) {
        init();
        setLoaded(true);
      }
    });
    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    form.setFieldsValue({ station: undefined });
  }, [trigger]);

  const init = async () => {
    const { params, action } = props.location.state;
    // const response = await testService.testFC();
    // console.log("Test from franchise : ", response.data);
    if (action === "ADD") {
      getStation();
      getTruckRegister();
      getDriver();
    } else if (action === "CANCEL") {
      const { driver, seq, truckRegist } = params;
      getTruckRegister();
      getDriver();

      form.setFieldsValue({ driver: driver, truckRegist: truckRegist });

      const response = await testService.getBooked(seq);
      if (response.data) {
        const { data } = response;
        setHoldData(data[0]);

        let arr: any = [];
        data.map((item: any) => {
          let newData: any = {
            stationCode: item.stationCode,
            stationName: item.stationName,
          };
          arr.push(newData);
        });
        setStationList(arr);
      }
    }
  };

  const getStation = async () => {
    const response = await testService.getStation();
    if (response.data) {
      setStations(response.data);
    }
  };

  const getTruckRegister = async () => {
    const response = await testService.getTruckRegister();
    if (response.data) {
      setTruckRegisters(response.data);
    }
  };

  const getDriver = async () => {
    const response = await testService.getDriver();
    if (response.data) {
      setDrivers(response.data);
    }
  };

  const onFinish = async (val: any) => {
    const { params, action } = props.location.state;
    if (action === "ADD") {
      if (stationList.length === 0) {
        return;
      }
      // console.log("Form : ", val);
      const { driver, truckRegist } = val;

      const driverData = drivers.filter(
        (item: any) => item.driverUId === driver
      );
      const { driverUId, firstname, lastname } = driverData[0];

      const truckData = truckRegisters.filter(
        (item: any) => item.registId === truckRegist
      );
      const { registId, truckUId } = truckData[0];

      // const resBookNo = await testService.getNextBookNo();

      let routes: any = [];
      stationList.map((item: any, i: number) => {
        const { stationCode, stationName } = item;
        const newData: any = {
          bookingTruckId: undefined,
          createDate: "",
          createTime: "",
          createUserid: 0,
          createUsername: "",
          refStationCode: stationCode,
          refStationName: stationName,
          seq: i + 1,
          updateDate: "",
          updateTime: "",
          updateUserid: 0,
          updateUsername: "",
        };
        routes.push(newData);
      });

      const data: any = {
        bookingType: "LINE HALL",
        bookingRoute: routes,
        createDate: "",
        createTime: "",
        createUserid: 0,
        createUsername: "",
        driverName: `${firstname} ${lastname}`,
        driverCode: driverUId,
        registId: registId,
        rmBookNo: undefined,
        savStation: "0",
        truckCode: truckUId,
        updateDate: "",
        updateTime: "",
        updateUserid: 0,
        updateUsername: "",
        useFlg: 0,
      };
      // const response = await axios.post(
      //   "https://app8.nimsoft.net/api/booking/addBooking",
      //   data
      // );
      // if (response.data) {
      const response = await testService.booking(data);
      if (response.data !== "error") {
        setBookNo(response.data);
        handlerClear();
      }
      // }
    } else if (action === "CANCEL") {
      if (holdData.useFlg !== 0) {
        console.log(
          "Can't cancel this list because is useFlg = " + holdData.useFlg
        );
        return;
      }
      const { rmBookNo } = holdData;
      let data: any = {
        bookingType: undefined,
        bookingRoute: undefined,
        createDate: "",
        createTime: "",
        createUserid: 0,
        createUsername: "",
        driverName: undefined,
        driverCode: undefined,
        registId: undefined,
        rmBookNo,
        savStation: "0",
        truckCode: undefined,
        updateDate: "",
        updateTime: "",
        updateUserid: 0,
        updateUsername: "",
        useFlg: -1, // ยกเลิก = -1, ตั้งรถ = 1
      };

      // const response = await axios.put(
      //   "https://app8.nimsoft.net/api/booking/updBookStatus",
      //   data
      // );
      // if (response.data) {
      const response = await testService.cancelBooked(data);
      if (response.data) {
        history.goBack();
      }
      // }
    }
  };

  const onFailure = (err: any) => {
    console.log("Form failure : ", err);
  };

  const handlerStation = (val: any) => {
    const isRepeat = stationList.filter(
      (item: any) => item.stationCode === val
    );
    if (isRepeat.length > 0) {
      forceRender();
      return;
    }
    const result = stations.filter((item: any) => item.code === val);
    const { code, name } = result[0];
    const data: any = {
      stationCode: code,
      stationName: name,
    };
    setStationList((prev: any) => [...prev, data]);
    forceRender();
  };

  const forceRender = () => {
    setTrigger((prev: number) => prev + 1);
  };

  const handlerClear = () => {
    setStationList([]);
    form.setFieldsValue({
      station: undefined,
      truckRegist: undefined,
      driver: undefined,
    });
  };

  const handlerClearStation = () => {
    setStationList([]);
    form.setFieldsValue({
      station: undefined,
    });
  };

  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          backgroundColor: "#fff",
          borderWidth: 1,
          borderStyle: "solid",
          borderRadius: 5,
          borderColor: "lightgrey",
          marginTop: 50,
          padding: 10,
          width: "30%",
        }}
      >
        <Title
          level={2}
          style={{ textAlign: "center", marginTop: 10, marginBottom: 20 }}
        >
          จองรถ
        </Title>
        <Form
          name="formTest"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFailure}
          autoComplete="off"
        >
          <Form.Item
            colon={false}
            label="สาขา"
            name="station"
            // rules={[{ required: true, message: "กรุณาเลือกสาขา" }]}
            style={{ marginLeft: 55, width: "100%" }}
          >
            <Select
              disabled={props.location.state.action === "CANCEL" ? true : false}
              // placeholder="สาขา"
              style={{ width: "80%" }}
              onChange={(data) => handlerStation(data)}
            >
              {stations.map((item: any) => {
                return (
                  <Option key={item.code} value={item.code}>
                    {`${item.name}`}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <div style={{ marginBottom: 30 }}>
            {stationList.length > 0 ? (
              <div>
                {stationList.map((item: any, i: number) => {
                  return (
                    <div key={item.stationCode} style={{ marginLeft: 100 }}>
                      <span style={{ marginRight: 10 }}>{i + 1}</span>
                      <span>{item.stationName}</span>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div style={{ color: "red", fontSize: 14, textAlign: "center" }}>
                ยังไม่ได้เลือกสาขา
              </div>
            )}
          </div>

          <div style={{ textAlign: "center", marginBottom: 30 }}>
            {props.location.state.action === "ADD" ? (
              <Form.Item>
                <Button
                  disabled={stationList.length > 0 ? false : true}
                  htmlType="button"
                  onClick={() => handlerClearStation()}
                  type="default"
                  style={{ marginTop: 10 }}
                >
                  ล้าง
                </Button>
              </Form.Item>
            ) : null}
          </div>

          <Form.Item
            colon={false}
            label="ทะเบียนรถ"
            name="truckRegist"
            rules={[{ required: true, message: "กรุณาเลือกทะเบียนรถ" }]}
            style={{ marginLeft: 18, width: "100%" }}
          >
            <Select
              defaultValue={
                props.location.state.action === "CANCEL"
                  ? props.location.state.params.truckRegist
                  : ""
              }
              disabled={props.location.state.action === "CANCEL" ? true : false}
              // placeholder="ทะเบียนรถ"
              style={{ width: "89%" }}
            >
              {truckRegisters.map((item: any) => {
                return (
                  <Option key={item.registId} value={item.registId}>
                    {item.registId}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            colon={false}
            label="พนักงานขับรถ"
            name="driver"
            rules={[{ required: true, message: "กรุณาเลือกพนักงานขับรถ" }]}
            style={{ width: "100%" }}
          >
            <Select
              defaultValue={
                props.location.state.action === "CANCEL"
                  ? props.location.state.params.driver
                  : ""
              }
              disabled={props.location.state.action === "CANCEL" ? true : false}
              // placeholder="พนักงานขับรถ"
              style={{ width: "94%" }}
            >
              {drivers.map((item: any) => {
                return (
                  <Option key={item.driverUId} value={item.driverUId}>
                    {`${item.firstname} ${item.lastname}`}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <div
            style={{ textAlign: "center", marginTop: 30, marginBottom: 100 }}
          >
            <Form.Item>
              <Space>
                <Button
                  htmlType="button"
                  onClick={() => history.goBack()}
                  type="default"
                  style={{ marginTop: 10 }}
                >
                  กลับ
                </Button>

                <Button
                  // disabled={holdData.useFlg !== 0 ? true : false}
                  htmlType="submit"
                  style={{ marginTop: 10 }}
                  type="primary"
                >
                  {props.location.state.action === "ADD" ? "จอง" : "ยกเลิก"}
                </Button>
              </Space>
            </Form.Item>
          </div>
        </Form>
        <div
          style={{
            marginTop: 5,
            marginBottom: 20,
            fontSize: 20,
            textAlign: "center",
          }}
        >
          {bookNo !== "" ? `เลขที่การจอง ${bookNo}` : ""}
        </div>
      </div>
    </div>
  );
};

export default BookingTest;
