import React from "react";
import { Drawer } from "antd";

interface IProps {
  children: React.ReactNode;
  placement: any;
  title: any;
  visible: any;
  setVisible: any;
  width: any;
}
const RDrawer: React.FC<IProps> = (props: any) => {
  const { children, placement, title, visible, setVisible, width } = props;
  const onClose = () => {
    setVisible(false);
  };

  return (
    <div>
      <Drawer
        title={title}
        placement={placement}
        width={width}
        onClose={onClose}
        visible={visible}
      >
        {children}
      </Drawer>
    </div>
  );
};

export default RDrawer;
