import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Select,
  Typography,
  Table,
  Space,
  Row,
  message,
} from "antd";
import moment from "moment";
import bookingService from "src/services/bookingService";
import { StringDate } from "src/utils";
import { useHistory } from "react-router";
import DrawerLineHaul from "src/components/DrawerLineHaul";
import { useForm } from "antd/lib/form/Form";
import RDrawer from "src/components/RDrawer";
import { IoCopyOutline } from "react-icons/io5";
import { getUserSession } from "src/store/authen/action";

const dummyStatus = [
  { id: 1, label: "ทั้งหมด", value: "All" },
  { id: 2, label: "จอง", value: "Booked" },
  { id: 3, label: "ยืนยันการจอง", value: "Confirmed" },
  { id: 4, label: "ยกเลิก", value: "Cancel" },
];

interface TProps {
  data: any;
  getCallBackData?: any;
}
const TableData: React.FC<TProps> = (props: any) => {
  const { data, getCallBackData } = props;
  const [copyData, setCopyData] = useState<any>();
  // const [callBackData, setCallBackData] = useState<any>();

  const history = useHistory();

  const columns = [
    {
      align: "center" as "center",
      title: "ลำดับ",
      dataIndex: "seq",
      key: "seq",
      width: 50,
    },
    {
      title: "เลขที่การจอง",
      dataIndex: "bookNo",
      key: "bookNo",
      render: (text: any, props: any) => {
        return (
          // <Link to={`/app/booking/bookingLineHaul/${props.id}`}>{text}</Link>
          <div
            style={{ color: "blue", cursor: "pointer" }}
            onClick={() =>
              history.push({
                pathname: `/app/booking/bookingLineHaul/${props.id}`,
                state: { bookNo: props.bookNo, from: "dataLineHaul" },
              })
            }
          >
            {text}
          </div>
        );
      },
    },
    {
      title: "วันที่จอง",
      dataIndex: "bookDate",
      key: "bookDate",
      width: 150,
    },
    {
      title: "ขนิดรถ",
      dataIndex: "truckTypeName",
      key: "truckTypeName",
    },
    {
      title: "วันที่ใช้งาน",
      dataIndex: "startDate",
      key: "startDate",
      width: 120,
    },
    {
      title: "สถานะการจอง",
      dataIndex: "status",
      key: "status",
      width: 150,
    },
    {
      title: "ผู้จอง",
      dataIndex: "createUsername",
      key: "createUsername",
    },
    {
      title: "คัดลอก",
      dataIndex: "copy",
      key: "copy",
      width: 100,
      align: "center" as "center",
      render: (text: any, props: any) => {
        const { id } = props;
        const loadData = async () => {
          const response = await bookingService.copyLineHaul(id);
          if (response.data.length > 0) {
            setCopyData(response.data);
          }
        };

        const getCallBackData_ = (value: any) => {
          getCallBackData(value);
        };

        return (
          <DrawerLineHaul
            data={copyData}
            loadData={loadData}
            getCallBackData={getCallBackData_}
          />
        );
      },
    },
  ];

  return (
    <>
      {data.length > 0 ? (
        <Table
          dataSource={data}
          columns={columns}
          size="small"
          rowKey={(obj: any) => obj.id}
        />
      ) : (
        <Table
          // dataSource={null}
          columns={columns}
          size="small"
          // rowKey={(obj: any) => obj.id}
        />
      )}
    </>
  );
};

interface IProps {}
const DataLineHaul: React.FC<IProps> = (props: any) => {
  const [booking, setBooking] = useState<any>([]);
  const [copyVisible, setCopyVisible] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [truckList, setTruckList] = useState<any>([]);

  const { Title } = Typography;
  const { Option } = Select;
  const history = useHistory();

  useEffect(() => {
    if (!loaded) {
      init();
      setLoaded(true);
    }
  }, []);

  const init = () => {
    setLoading(true);
    if (getUserSession() === null) {
      history.replace("/login");
      return;
    }
    // setUserSession(getUserSession());
    defaultDate();
    findTruckAll();
    searchData();
    setLoading(false);
  };

  const defaultDate = () => {
    form.setFieldsValue({
      startDate: moment(new Date(), "YYYYMMDD"),
      endDate: moment(new Date(), "YYYYMMDD"),
    });
  };

  const findTruckAll = async () => {
    const response = await bookingService.findTruckAll();
    if (response.data) {
      setTruckList(response.data);
    }
  };

  const searchData = async () => {
    let value = form.getFieldsValue();
    const values = {
      ...form.getFieldsValue(),
      startDate: value["startDate"].format("YYYYMMDD"),
      endDate: value["endDate"].format("YYYYMMDD"),
    };
    const { startDate, endDate, bookNo, truckType, status } = values;
    // let now = moment(new Date()).format("YYYYMMDD");
    let data = {
      startDate,
      endDate,
      bookNo,
      truckType,
      status,
    };
    // console.log(data);
    const response = await bookingService.findBookingLineHaul(data);
    if (response.data.length > 0) {
      for (let i = 0; i < response.data.length; i++) {
        let formatBookDate = StringDate(response.data[i].bookDate, "TH", "/");
        let formatStartDate = StringDate(response.data[i].startDate, "TH", "/");
        response.data[i].seq = i + 1;
        response.data[i].startDate = formatStartDate;
        response.data[i].bookDate = formatBookDate;
      }
      setBooking(response.data);
    } else {
      message.warning("ไม่พบข้อมูล");
    }
  };

  const [form] = useForm();
  const onFinishFormBook = (value: any) => {
    searchData();
  };

  const onFinishFailedFormBook = (error: any) => {
    console.log(error);
  };

  const getCallBackData = (value: any) => {
    if (value) {
      searchData();
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#fff",
        padding: 10,
        borderRadius: 5,
        width: "100%",
        height: "100%",
      }}
    >
      <Title level={4}>ข้อมูลการจองรถระยะใกล</Title>
      <div style={{ marginTop: 30, marginBottom: 30 }}>
        <Form
          name="dataLineHaul"
          form={form}
          // labelCol={{ span: 8 }}
          // wrapperCol={{ span: 16 }}
          // initialValues={{ remember: true }}
          onFinish={onFinishFormBook}
          onFinishFailed={onFinishFailedFormBook}
          autoComplete="off"
        >
          <Row gutter={16} style={{ color: "#000" }}>
            <Col span={6}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  marginTop: 7,
                }}
              >
                วันที่จอง
              </div>
            </Col>
            <Col span={6}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div>
                  <Form.Item
                    colon={false}
                    label="ตั้งแต่"
                    name="startDate"
                    rules={[
                      {
                        required: true,
                        message: "กรุณาระบุ วันที่จอง ตั้งแต่!",
                      },
                    ]}
                  >
                    <DatePicker
                      style={{ width: 170 }}
                      format="DD/MM/YYYY"
                      placeholder="เลือกวันที่"
                    />
                  </Form.Item>
                </div>
              </div>
            </Col>
            <Col span={6}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <div>
                  <Form.Item colon={false} label="เลขที่การจอง" name="bookNo">
                    <Input placeholder="เลขที่การจอง" />
                  </Form.Item>
                </div>
              </div>
            </Col>
            <Col span={6}>
              <div style={{}}></div>
            </Col>
          </Row>

          <Row gutter={16} style={{ marginTop: -7 }}>
            <Col span={6}></Col>
            <Col span={6}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div style={{ marginLeft: 17 }}>
                  <Form.Item
                    colon={false}
                    label="ถึง"
                    name="endDate"
                    rules={[
                      {
                        required: true,
                        message: "กรุณาระบุ วันที่จอง ถึง!",
                      },
                    ]}
                  >
                    <DatePicker
                      style={{ width: 170 }}
                      format="DD/MM/YYYY"
                      placeholder="เลือกวันที่"
                    />
                  </Form.Item>
                </div>
              </div>
            </Col>
            <Col span={6}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <div>
                  <Form.Item colon={false} label="ชนิดรถ" name="truckType">
                    <Select
                      placeholder="ชนิดรถ"
                      style={{ width: 170 }}
                      // onChange={(val: any) =>
                      //   handleChangeSelect(val, "truck")
                      // }
                    >
                      {truckList.map((item: any) => (
                        <Option key={item.id} value={item.id}>
                          {item.typeName}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>
            </Col>
            <Col span={6}>
              <div style={{}}></div>
            </Col>
          </Row>

          <Row gutter={16} style={{ marginTop: -7 }}>
            <Col span={6}></Col>
            <Col span={6}></Col>
            <Col span={6}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <div>
                  <Form.Item colon={false} label="สถานะการจอง" name="status">
                    <Select
                      //   defaultValue={`${dummyStatus[0].value}`}
                      defaultActiveFirstOption={true}
                      placeholder="สถานะการจอง"
                      style={{ width: 170 }}
                      // onChange={(val: any) =>
                      //   handleChangeSelect(val, "truck")
                      // }
                    >
                      {dummyStatus.map((item: any) => (
                        <Option key={item.id} value={item.id}>
                          {item.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>
            </Col>
            <Col span={6}>
              <div style={{}}></div>
            </Col>
          </Row>

          <div style={{ textAlign: "center", marginTop: 20 }}>
            <Form.Item>
              <Space>
                <Button type="primary" size="middle" htmlType="submit">
                  ค้นหา
                </Button>
                <Button
                  type="primary"
                  size="middle"
                  htmlType="button"
                  onClick={() =>
                    history.push({
                      pathname: "/app/booking/bookingLineHaul/0",
                      state: { bookNo: "", from: "dataLineHaul" },
                    })
                  }
                >
                  เพิ่ม
                </Button>
              </Space>
            </Form.Item>
          </div>
        </Form>
      </div>
      {/* <div style={{ height: 1, backgroundColor: "lightgray" }}></div> */}
      <div>
        <TableData data={booking} getCallBackData={getCallBackData} />
      </div>
    </div>
  );
};

export default DataLineHaul;
