import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Col,
  Form,
  Row,
  Input,
  message,
  Select,
  Typography,
  Space,
} from "antd";
import {
  DirectionsRenderer,
  GoogleMap,
  InfoWindow,
  Marker,
  withScriptjs,
  withGoogleMap,
} from "react-google-maps";
import { getUserSession } from "src/store/authen/action";
import bookingService from "src/services/bookingService";
import { generateOrderNumber } from "src/utils";
import { AiFillCloseCircle } from "react-icons/ai";
import { useHistory } from "react-router";

const RMap: React.FC<{
  centerCoordinate: any;
  callBack?: any;
  data?: any;
  directions?: any;
  setIsPin?: any;
  setData?: any;
  condition?: any;
}> = React.memo((props: any) => {
  // console.log(props);
  const {
    centerCoordinate,
    callBack,
    directions,
    data,
    setIsPin,
    setData,
    condition,
  } = props;

  const mapRef = useRef(null);

  // const handleOnClickMap = async (e: any) => {
  //   if (condition === "") {
  //     message.warning("กรุณาเลือกสีก่อน!");
  //     return;
  //   }
  //   setIsPin(true);
  //   let lat = e.latLng.lat();
  //   let lng = e.latLng.lng();
  //   const data = {
  //     id: generateOrderNumber(),
  //     description: null,
  //     lat,
  //     lng,
  //     locationName: null,
  //     place_id: null,
  //     secondary_text: null,
  //     reference: null,
  //     terms: [],
  //   };
  //   // setData((prev: any) => [...prev, data]);
  //   callBack(data);
  // };
  const Map: any = withGoogleMap((props) => (
    <GoogleMap
      defaultCenter={centerCoordinate}
      defaultZoom={15}
      // onClick={handleOnClickMap}
    >
      {/* {data &&
        data.map((item: any) => {
          if (item.lat === null && item.lng === null) {
            return;
          }
          // const { terms } = item;
          // const bonds = new google.maps.LatLngBounds();
          // bonds.extend({ lat: Number(item.lat), lng: Number(item.lng) })

          return (
            <Marker
              key={item.id}
              position={{ lat: Number(item.lat), lng: Number(item.lng) }}
              // onMouseOver={(e) => console.log(e)}
            >
              <InfoWindow>
                <div>{item.locationName}</div>
              </InfoWindow>
            </Marker>
          );
        })} */}

      {directions && (
        <DirectionsRenderer
          directions={directions}
          options={{
            polylineOptions: {
              geodesic: true,
              strokeOpacity: 1,
              strokeColor: "#0000ff",
              strokeWeight: 3,
            },
          }}
        />
      )}
    </GoogleMap>
  ));
  return (
    <Map
      ref={mapRef}
      containerElement={<div style={{ height: `750px`, width: "850px" }} />}
      mapElement={<div style={{ height: `100%` }} />}
    />
  );
});

const ListItems = (props: any) => {
  // console.log(props);
  const { items, handleDeleteItem } = props;
  return (
    <div style={{ marginTop: 10, marginBottom: 10 }}>
      {items.map((item: any, index: number) => (
        <div
          key={item.id}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: 10,
            fontWeight: "bold",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div style={{ marginRight: 10, width: 40 }}>{`จุดที่ ${
              index + 1
            }`}</div>
            <div>{item.locationName}</div>
          </div>
          <AiFillCloseCircle
            color="#ff0000"
            onClick={() => handleDeleteItem(item.id)}
            size={20}
            style={{ cursor: "pointer" }}
          />
        </div>
      ))}
    </div>
  );
};

const Wrapper: any = React.memo(() => {
  const [directions, setDirections] = useState<any>(null);
  const [distance, setDistance] = useState<any>("0 กิโลเมตร");
  const [duration, setDuration] = useState<any>("0 ชั่วโมง");
  const [distAndDura, setDistAndDura] = useState<any>({});
  const [loaded, setLoaded] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [mapData, setMapData] = useState<any>([]);
  const [stationList, setStationList] = useState<any>([]);
  const [centerCoordinate, setCenterCoordinate] = useState<any>({
    lat: Number(18.80753508304137),
    lng: Number(99.01845056588674),
  });
  const [userSession, setUserSession] = useState<any>(getUserSession());

  const history = useHistory();

  const [form] = Form.useForm();

  const { Title } = Typography;
  const { Option } = Select;

  let SUM_DISTANCE = 0;
  let SUM_DURATION = 0;

  useEffect(() => {
    if (!loaded) {
      init();
      setLoaded(true);
    }
  }, []);

  const init = () => {
    setLoading(true);
    // console.log(getUserSession());
    if (getUserSession() === null) {
      history.replace("/login");
      return;
    }
    findStationByCustId();
    setLoading(false);
  };

  useEffect(() => {
    if (mapData.length > 1) {
      calculateDistanceAndDuration();
    } else {
      setDirections(null);
      setDistance("0 กิโลเมตร");
      setDuration("0 ชั่วโมง");
    }
  }, [mapData]);

  const calculateDistanceAndDuration = async () => {
    if (mapData.length <= 1) {
      setDistance("0 กิโลเมตร");
      setDuration("0 ชั่วโมง");
    } else if (mapData.length > 1) {
      let last = mapData[mapData.length - 1];
      for (let i = 0; i < mapData.length; i++) {
        if (mapData[i].id !== last.id) {
          let origin = new google.maps.LatLng(mapData[i].lat, mapData[i].lng);
          let destination = new google.maps.LatLng(
            mapData[i + 1].lat,
            mapData[i + 1].lng
          );
          let service = new google.maps.DistanceMatrixService();
          let response = await service.getDistanceMatrix({
            origins: [origin],
            destinations: [destination],
            travelMode: google.maps.TravelMode.DRIVING,
            unitSystem: google.maps.UnitSystem.METRIC,
            avoidHighways: false,
            avoidTolls: false,
          });

          let { rows } = response;
          for (let i = 0; i < rows.length; i++) {
            for (let j = 0; j < rows[i].elements.length; j++) {
              // console.log(rows[i].elements[j]);
              SUM_DISTANCE += rows[i].elements[j].distance.value;
              SUM_DURATION += rows[i].elements[j].duration.value;
            }
          }

          let kilometer = Math.round(SUM_DISTANCE / 1000);
          let hour = Math.floor(SUM_DURATION / 3600);
          let minute = Math.round((SUM_DURATION % 3600) / 60);
          const data = {
            d: kilometer,
            h: hour,
            m: minute,
          };
          setDistAndDura(data);
          setDistance(`${kilometer} กิโลเมตร`);
          setDuration(`${hour} ชั่วโมง ${minute} นาที`);
        }
      }
      DrawingDirection();
    }
  };

  const DrawingDirection = () => {
    const origin = mapData[0];
    const dest = mapData[mapData.length - 1];

    const DirectionsServer = new google.maps.DirectionsService();
    const waypts: google.maps.DirectionsWaypoint[] = [];
    mapData.map((item: any) => {
      if (item.id !== origin.id && item.id !== dest.id) {
        const lat: any = item.lat;
        const lng: any = item.lng;
        const data = {
          location: new google.maps.LatLng(Number(lat), Number(lng)),
          stopover: true,
        };
        waypts.push(data);
      }
    });

    DirectionsServer.route(
      {
        origin: new google.maps.LatLng(
          Number(origin ? origin.lat : null),
          Number(origin ? origin.lng : null)
        ),
        destination: new google.maps.LatLng(
          Number(dest ? dest.lat : null),
          Number(dest ? dest.lng : null)
        ),
        waypoints: waypts,
        travelMode: google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        // console.log(result);
        if (status === google.maps.DirectionsStatus.OK) {
          setDirections(result);
        } else {
          console.error(`Map direction error ${result}`);
        }
      }
    );
  };

  const findStationByCustId = async () => {
    const response = await bookingService.findStationByCustId(
      userSession.customerId
    );
    if (response.data) {
      setStationList(response.data);
    }
  };

  const stationAll = () => {
    return (
      <div style={{ width: "100%" }}>
        <Select
          placeholder="ชื่อเส้นทาง"
          onChange={handleSelectStation}
          style={{ width: "100%" }}
        >
          {stationList.map((item: any) => (
            <Option key={item.id} value={item.id}>
              {item.name}
            </Option>
          ))}
        </Select>
      </div>
    );
  };

  const handleSelectStation = (val: any) => {
    const result = stationList.filter((item: any) => item.id === val);
    const { lat, lng, name } = result[0];
    const data = {
      id: generateOrderNumber(),
      // description: null,
      lat,
      lng,
      locationName: name,
      // place_id: null,
      // secondary_text: null,
      // reference: null,
      // terms: [],
    };
    setMapData((prev: any) => [...prev, data]);
  };

  const DistanceDuration = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-evenly",
          marginTop: 20,
          marginBottom: 20,
        }}
      >
        <div style={{ textAlign: "center" }}>
          <Title level={4} style={{ fontWeight: "bold" }}>
            ระยะทาง
          </Title>
          <div>{distance}</div>
        </div>
        <div style={{ textAlign: "center" }}>
          <Title level={4} style={{ fontWeight: "bold" }}>
            เวลา
          </Title>
          <div>{duration}</div>
        </div>
      </div>
    );
  };

  const onFinish = async (values: any) => {
    if (mapData.length === 0) {
      message.warn("กรุณาเพ่ิมเส้นทางก่อน");
      return;
    }
    const arrRouteDetails: any = [];
    mapData.map((item: any) => {
      const { id, locationName, lat, lng } = item;
      const routeDetails = {
        lat,
        lng,
        stationId: id,
        stationName: locationName,
      };
      arrRouteDetails.push(routeDetails);
    });

    const data = {
      createUserid: userSession.secUserId,
      createUsername: userSession.fullname,
      custUId: userSession.customerUId,
      name: values.routeName,
      routeDetail: [...arrRouteDetails],
      savStation: userSession.stationCode,
    };
    // console.log(data);
    const response = await bookingService.saveDailyRouteLineHaul(data);
    if (response.data) {
      message.success("กำหนดเส้นทางประจำสำเร็จ");
      setMapData([]);
      setDistance("0 กิโลเมตร");
      setDuration("0 ชั่วโมง");
      form.resetFields();
      init();
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const RouteName = () => {
    return (
      <Form
        form={form}
        name="routeNameForm"
        layout="vertical"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        style={{ width: "100%" }}
      >
        <Form.Item
          name="routeName"
          rules={[{ required: true, message: "กรุณาใส่ชื่อเส้นทาง" }]}
          style={{ width: "100%" }}
        >
          <Input placeholder="ชื่อเส้นทาง" style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          style={{
            textAlign: "right",
          }}
        >
          <Space direction="horizontal">
            <Button htmlType="button" onClick={() => history.goBack()}>
              ยกเลิก
            </Button>
            <Button type="primary" htmlType="submit">
              บันทึก
            </Button>
          </Space>
        </Form.Item>
      </Form>
    );
  };

  const handleDeleteItem = (id: any) => {
    const newItem = mapData.filter((item: any) => item.id !== id);
    setMapData(newItem);
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Row style={{ height: "100%" }}>
        <Col
          span={9}
          style={{ backgroundColor: "#fff", padding: 15, borderRadius: 5 }}
        >
          <Title level={4} style={{ fontWeight: "bold" }}>
            กำหนดเส้นทางรถวิ่งระยะใกล(ประจำ)
          </Title>

          {/* Station all */}
          {stationAll()}

          {/* List items */}
          <ListItems items={mapData} handleDeleteItem={handleDeleteItem} />

          {/* Distance and duration */}
          {DistanceDuration()}

          {/* Route name */}
          {RouteName()}
        </Col>

        <Col span={15}>
          <RMap centerCoordinate={centerCoordinate} directions={directions} />
        </Col>
      </Row>
    </div>
  );
});

const CreateDailyRoute: React.FC = (props: any) => {
  const MapLoader: any = withScriptjs(Wrapper);

  return (
    <MapLoader
      googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCY8tssCzJWXdG7BnWMr5EmiljzHL4zp0o&v=3.exp&libraries=geometry,drawing,places"
      loadingElement={<div style={{ height: `100%` }} />}
    />
  );
};

export default CreateDailyRoute;
