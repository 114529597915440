import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { Button, Table, Input, Typography, message } from "antd";

import { useAppSelector } from "src/store";
import { getUserSession } from "src/store/authen/action";
import bookingService from "src/services/bookingService";

const TableStation: React.FC<{
  data: any;
  callBack?: any;
}> = (props: any) => {
  const { data, callBack } = props;
  const columns = [
    {
      align: "center" as "center",
      width: 50,
      title: "ลำดับ",
      dataIndex: "seq",
      key: "seq",
    },
    {
      title: "สถานี",
      dataIndex: "name",
      key: "name",
      render: (text: any, record: any, index: any) => {
        let { id, lat, lng, name } = record;
        let data: any = {
          id,
          lat,
          lng,
          name,
        };
        return (
          <div
            onClick={() => callBack(data)}
            style={{ color: "#5DADE2", cursor: "pointer" }}
          >
            {text}
          </div>
        );
      },
    },
    {
      title: "จังหวัด",
      dataIndex: "province",
      key: "province",
    },
    {
      title: "จำนวนขอบเขต",
      dataIndex: "count",
      key: "count",
    },
    {
      width: 100,
      title: "สถานะ",
      dataIndex: "status",
      key: "status",
      render: (value: any, item: any) => (
        <div style={{ color: item.useFlg === 0 ? "#000" : "red" }}>{value}</div>
      ),
    },
  ];

  return (
    <>
      {data.length > 0 ? (
        <Table
          dataSource={data}
          columns={columns}
          size="small"
          rowKey={(obj: any) => obj.id}
        />
      ) : (
        <Table
          // dataSource={null}
          columns={columns}
          size="small"
          // rowKey={(obj: any) => obj.id}
        />
      )}
    </>
  );
};

const Station: React.FC = (props: any) => {
  // const { sessionUser } = useAppSelector((state) => state.auth);
  // const { customerId, fullname, roles, secUserId, stationId, stationName } =
  //   sessionUser;

  const [loaded, setLoaded] = useState<any>(false);
  const [loading, setLoading] = useState<any>(false);
  const [stationList, setStationList] = useState<any>([]);
  const [userSession, setUserSession] = useState<any>(getUserSession());

  useEffect(() => {
    if (!loaded) {
      init();
      setLoaded(true);
    }
  }, []);

  const history = useHistory();

  const { Title } = Typography;
  const { Search } = Input;

  const init = () => {
    setLoading(true);
    if (getUserSession() === null) {
      history.replace("/login");
      return;
    }
    // findStationAll();
    findStationReferCustId();
    setLoading(false);
  };

  // const findStationAll = async () => {
  //   const response = await bookingService.findStationAll();
  //   console.log(response.data);
  //   if (response.data) {
  //     setStationList(response.data);
  //   }
  // };

  const findStationReferCustId = async () => {
    const response = await bookingService.findStationReferCustId(
      userSession.customerId
    );
    if (response.data.length > 0) {
      setStationList(response.data);
    }
  };

  const handleSearchStation = (val: any) => {
    if (val !== "") {
      const result = stationList.filter((item: any) => item.name.match(val));
      let arr = [];
      for (let i = 0; i < result.length; i++) {
        const data = {
          id: result[i].id,
          seq: i + 1,
          name: result[i].name,
          province: result[i].province,
          count: result[i].count,
          status: result[i].status,
        };
        arr.push(data);
      }
      setStationList(arr);
    } else {
      // findStationAll();
      findStationReferCustId();
      // message.warning("กรุณาระบุสถานีที่ต้องการค้นหา!");
    }
  };

  const handleCallBack = (record: any) => {
    history.push({
      pathname: "/app/booking/manageStation",
      state: { data: record },
    });
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "#fff",
        borderRadius: 5,
        padding: 15,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Title level={4} style={{ fontWeight: "bold" }}>
          กำหนดสถานี
        </Title>
        <Button
          type="primary"
          onClick={() =>
            history.push({
              pathname: "/app/booking/createStation",
              state: { action: "create", id: null },
            })
          }
        >
          เพิ่ม
        </Button>
      </div>

      <div style={{ textAlign: "center" }}>
        <Search
          placeholder="ค้นหาสถานี"
          allowClear
          onSearch={handleSearchStation}
          style={{ width: 250, marginTop: 20 }}
        />
      </div>

      <div style={{ marginTop: "2rem" }}>
        <TableStation data={stationList} callBack={handleCallBack} />
      </div>
    </div>
  );
};

export default Station;
