import React, { memo } from "react";
import {
  DirectionsRenderer,
  GoogleMap,
  withGoogleMap,
} from "react-google-maps";

const RMap: React.FC<{
  centerCoordinate: any;
  callBack?: any;
  data?: any;
  directions?: any;
  setIsPin?: any;
  setData?: any;
  condition?: any;
}> = (props: any) => {
  const { centerCoordinate, data, directions } = props;
  const Map: any = withGoogleMap((props: any) => (
    <GoogleMap defaultCenter={centerCoordinate} defaultZoom={13}>
      {directions && (
        <DirectionsRenderer
          directions={directions}
          options={{
            polylineOptions: {
              geodesic: true,
              strokeOpacity: 1,
              strokeColor: "#0000ff",
              strokeWeight: 3,
            },
          }}
        />
      )}
    </GoogleMap>
  ));
  return (
    <Map
      containerElement={<div style={{ height: `900px`, width: "850px" }} />}
      mapElement={<div style={{ height: `100%` }} />}
    />
  );
};

export default memo(RMap);
