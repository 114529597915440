import React, { useState, useEffect, useRef } from "react";
import ColorPicker from "../../assets/icons/color.png";
import { IoCloseCircle, IoAlertCircleOutline } from "react-icons/io5";
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Select,
  Row,
  Space,
  Typography,
  Table,
  Tooltip,
  Switch,
  Popconfirm,
} from "antd";
import { useHistory } from "react-router";
import {
  DirectionsRenderer,
  GoogleMap,
  InfoWindow,
  Marker,
  withScriptjs,
  withGoogleMap,
} from "react-google-maps";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng,
} from "react-google-places-autocomplete";
import { getUserSession } from "src/store/authen/action";
import { generateOrderNumber } from "src/utils";
import bookingService from "src/services/bookingService";
import { CirclePicker } from "react-color";

let ACTION = "";
let STATION_NAME = "";
let STATION_ID = "";
let SCOPE_DETAIL_ID = "";
let PROVINCE_ID = "";
let PROVINCE_NAME = "";
let DISTRICT_ID = "";
let LAT = 0;
let LNG = 0;

const TableScope: React.FC<{
  data: any;
  remove: any;
}> = (props: any) => {
  const { data, remove } = props;
  const columns = [
    {
      width: 50,
      title: "ลำดับ",
      dataIndex: "seq",
      key: "seq",
    },
    {
      title: "ชื่อขอบเขต",
      dataIndex: "scopeName",
      key: "scopeName",
    },
    {
      title: "ตำบล",
      dataIndex: "subDistrict",
      key: "subDistrict",
    },
    {
      title: "ลบ",
      key: "action",
      render: (text: any, recode: any) => (
        <Space size="middle">
          <Popconfirm
            title={`คุณต้องการลบ ${text.subDistrict} นี้ใช่ หรือ ไม่`}
            onConfirm={(e) => confirm(text.seq)}
            onCancel={(e) => cancel(e)}
            okText="ใช่"
            cancelText="ยกเลิก"
          >
            <IoCloseCircle
              size={20}
              color="#ff0000"
              style={{ cursor: "pointer" }}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const confirm = async (id: any) => {
    remove(id);
  };

  const cancel = (e: any) => {
    console.log("Cancel");
  };

  return (
    <>
      {data.length > 0 ? (
        <Table
          dataSource={data}
          columns={columns}
          size="small"
          rowKey={(obj: any, index: any) => obj.seq}
        />
      ) : (
        <Table
          // dataSource={null}
          columns={columns}
          size="small"
          // rowKey={(obj: any) => obj.id}
        />
      )}
    </>
  );
};

const RAutocomplete = (props: any) => {
  const { autoValue, setAutoValue } = props;
  return (
    <GooglePlacesAutocomplete
      // apiKey={MAP_API}
      apiOptions={{ language: "th", region: "th" }}
      debounce={300}
      onLoadFailed={(err) => message.warning(`${err}`, 10)}
      selectProps={{
        placeholder: "ค้นหาตำแหน่งสถานี",
        autoValue,
        onChange: setAutoValue,
      }}
    />
  );
};

const RMap: React.FC<{
  centerCoordinate: any;
  callBack?: any;
  data?: any;
  directions?: any;
  setIsPin?: any;
  setData?: any;
  condition?: any;
}> = React.memo((props: any) => {
  // console.log(props);
  const { centerCoordinate, callBack, data, setIsPin, setData, condition } =
    props;

  const mapRef = useRef(null);

  const handleOnClickMap = async (e: any) => {
    if (condition === "") {
      message.warning("กรุณาเลือกสีก่อน!");
      return;
    }
    setIsPin(true);
    let lat = e.latLng.lat();
    let lng = e.latLng.lng();
    const data = {
      id: generateOrderNumber(),
      description: null,
      lat,
      lng,
      locationName: null,
      place_id: null,
      secondary_text: null,
      reference: null,
      terms: [],
    };
    callBack(data);
  };
  const Map: any = withGoogleMap((props) => (
    <GoogleMap
      ref={(map) => {
        const bounds = new window.google.maps.LatLngBounds();
        data &&
          data.map((item: any) => {
            bounds.extend(new window.google.maps.LatLng(item.lat, item.lng));
          });
        if (data.length > 0) {
          map && map.fitBounds(bounds);
        }
        // props.onMapMounted(map);
      }}
      defaultCenter={centerCoordinate}
      defaultZoom={15}
      onClick={handleOnClickMap}
    >
      {data &&
        data.map((item: any, i: any) => {
          if (item.lat === null && item.lng === null) {
            return;
          }
          const svgMarker = {
            path: "M10.453 14.016l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM12 2.016q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
            fillColor: `${condition}`,
            fillOpacity: 1,
            strokeWeight: 0,
            rotation: 0,
            scale: 1.8,
            anchor: new google.maps.Point(15, 30),
          };

          return (
            <Marker
              icon={svgMarker}
              key={i}
              position={{ lat: Number(item.lat), lng: Number(item.lng) }}
              // onMouseOver={(e) => console.log(e)}
            >
              <InfoWindow>
                <div>{item.locationName}</div>
              </InfoWindow>
            </Marker>
          );
        })}

      {/* {directions && (
          <DirectionsRenderer
            directions={directions}
            options={{
              polylineOptions: {
                geodesic: true,
                strokeOpacity: 1,
                strokeColor: "#0000ff",
                strokeWeight: 3,
              },
            }}
          />
        )} */}
    </GoogleMap>
  ));
  return (
    <Map
      ref={mapRef}
      containerElement={<div style={{ height: "100%", width: "100%" }} />}
      mapElement={<div style={{ height: `100%` }} />}
    />
  );
});

const Wrapper: any = () => {
  const [autoValue, setAutoValue] = useState<any>(null);
  const [color, setColor] = useState<any>("");
  const [centerCoordinate, setCenterCoordinate] = useState<any>({
    lat: Number(LAT), // Number(18.80753508304137)
    lng: Number(LNG), // Number(99.01845056588674)
  });
  const [directions, setDirections] = useState<any>();
  const [districtList, setDistrictList] = useState<any>([]); // Amphoe
  const [holdData, setHoldData] = useState<any>([]);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [location, setLocation] = useState<any>({});
  const [isPin, setIsPin] = useState<boolean>(false);
  const [isUse, setIsUse] = useState<boolean>(true);
  const [provinceList, setProvinceList] = useState<any>([]);
  const [mapData, setMapData] = useState<any>([]);
  const [subDistrictList, setSubDistrictList] = useState<any>([]); // Tambon
  const [stationName, setStationName] = useState<any>("");
  const [userSession, setUserSession] = useState<any>(getUserSession());

  const history = useHistory();

  const [form] = Form.useForm();

  const { Title } = Typography;
  const { Option } = Select;

  useEffect(() => {
    if (!loaded) {
      init();
      setLoaded(true);
    }
  }, []);

  const init = async () => {
    setLoading(true);
    if (ACTION === "NEW") {
      // prepareData();
      form.setFieldsValue({
        province: PROVINCE_ID,
        district: DISTRICT_ID,
      });
      setLocation({
        provinceCode: PROVINCE_ID,
      });
      findProvinceAll();
      findDistrictAll(PROVINCE_ID);
      findSubDistrictAll(PROVINCE_ID, DISTRICT_ID);
    } else if (ACTION === "EDIT") {
      const response = await bookingService.editScopeDetail(SCOPE_DETAIL_ID);
      // console.log(response.data);
      if (response.data) {
        const {
          addrPath,
          color,
          lat,
          lng,
          scopeDetailId,
          scopeCode,
          scopeName,
          tambon,
          useFlg,
        } = response.data;
        setColor(color);
        setIsUse(useFlg === 0 ? true : false);
        let provinceId = addrPath.substring(0, 2);
        let districtId = addrPath.substring(3, 7);
        let subDistrictId = addrPath.substring(8);

        form.setFieldsValue({
          scopeCode,
          scopeName,
          province: provinceId,
          district: districtId,
          subDistrict: tambon,
        });
        setLocation({
          provinceCode: addrPath.substring(0, 2),
        });
        findProvinceAll();
        findDistrictAll(provinceId);
        findSubDistrictAll(provinceId, districtId);
        const data = {
          color,
          scopeDetailId,
          lat,
          lng,
          locationName: tambon,
          term: [],
        };
        setMapData([data]);
      } else {
        console.log(response.data);
      }
    }
    setLoading(false);
  };

  // const prepareData = () => {
  //   const data = {
  //     id: "main1",
  //     isOpen: true,
  //     color: "#000",
  //     lat: Number(LAT),
  //     lng: Number(LNG),
  //     locationName: STATION_NAME,
  //     main: true,
  //     seq: 0,
  //   };
  //   setMapData([data]);
  // };

  useEffect(() => {
    (async () => {
      if (autoValue) {
        const { value } = autoValue;
        // console.log(value);
        const {
          description,
          place_id,
          reference,
          structured_formatting,
          terms,
        } = value;
        const { main_text, secondary_text } = structured_formatting;
        const placeId = value.place_id;
        const res = await geocodeByPlaceId(placeId);
        const lat_lnt = await getLatLng(res[0]);
        const { lat, lng } = lat_lnt;
        form.setFieldsValue({
          scopeName: main_text,
        });
        setIsPin(true);
        const data = {
          id: generateOrderNumber(),
          // description,
          lat,
          lng,
          locationName: main_text,
          // place_id,
          // secondary_text,
          // reference,
          // terms,
        };
        // console.log(data);
        setHoldData([data]);
        // const newData = [data];
        // setMapData(newData);
      }
    })();
  }, [autoValue]);

  const findProvinceAll = async () => {
    const response = await bookingService.findProvinceAll();
    if (response.data) {
      setProvinceList(response.data);
    }
  };

  const findDistrictAll = async (provincCode: any) => {
    const response = await bookingService.findDistrict(provincCode);
    if (response.data) {
      setDistrictList(response.data);
    }
  };

  const findSubDistrictAll = async (provinceCode: any, districtCode: any) => {
    const response = await bookingService.findSubDistrict(
      provinceCode,
      districtCode
    );
    if (response.data) {
      setSubDistrictList(response.data);
    }
  };

  const handleChangeProvince = async (val: any) => {
    setLocation({
      provinceCode: val,
    });
    const response = await bookingService.findDistrict(val);
    if (response.data) {
      setDistrictList(response.data);
    }
  };

  const handleChangeDistrict = async (districtCode: any) => {
    let { provinceCode } = location;
    const response = await bookingService.findSubDistrict(
      provinceCode,
      districtCode
    );
    if (response.data) {
      setSubDistrictList(response.data);
    }
  };

  const handleChangeSubDistrict = async (tambonCode: any) => {
    if (ACTION === "NEW") {
      const response = await bookingService.findZipCodeByTambonCode(tambonCode);
      if (response.data) {
        form.setFieldsValue({
          zipcode: response.data.zipCode,
        });
      }
    } else if (ACTION === "EDIT") {
      const result = subDistrictList.filter(
        (item: any) => item.tambonCode === tambonCode
      );
      let mapData_ = [...mapData];
      mapData_[0].locationName = result[0].tambon;
      const data = {
        color,
        lat: mapData_[0].lat,
        lng: mapData_[0].lng,
        locationName: mapData_[0].locationName,
      };
      setMapData([data]);
    }
  };

  const handleAddScope = () => {
    let values = form.getFieldsValue();
    const { district, subDistrict, province, scopeCode, scopeName } = values;
    // console.log(subDistrict);
    if (subDistrict === undefined) {
      message.warning("กรุณาเติมข้อมูลขอบเขตให้สมบูรณ์!");
      return;
    }
    if (color === "") {
      message.warning("กรุณาเลือกสีก่อน!");
      return;
    }
    if (!isPin) {
      message.warning("กรุณาปักหมุดตำแหน่งบนแผนที่ก่อน!");
      return;
    }
    let isTambonDuplicate = false;
    if (mapData.length > 0) {
      let tambon = handleSubDistrict(subDistrict);
      // console.log(tambon);
      mapData.map((item: any) => {
        if (tambon === item.subDistrict) {
          isTambonDuplicate = true;
        }
      });
    }
    if (isTambonDuplicate) {
      message.warning("ตำบลนี้มีอยู่ในขอบเขตแล้ว!");
      return;
    }
    const { id, lat, lng } = holdData[0];
    const data = {
      id,
      lat,
      lng,
      locationName: handleSubDistrict(subDistrict),
      seq: mapData.length + 1,
      scopeName,
      subDistrict: handleSubDistrict(subDistrict),
    };
    // console.log(data);
    setMapData((prev: any) => [...prev, data]);
  };

  const handleDeleteScopeItem = (id: any) => {
    const result = mapData.filter((item: any) => item.seq !== id);
    setMapData(result);
  };

  const getMapDetail = (data: any) => {
    if (ACTION === "NEW") {
      setHoldData([data]);
    } else if (ACTION === "EDIT") {
      let mapData_ = [...mapData];
      const { lat, lng } = data;
      const data_ = {
        color,
        lat,
        lng,
        locationName: mapData_[0].locationName,
        // term: [],
      };
      setMapData([data_]);
    }
  };

  const onFinish = async (values: any) => {
    const { district, subDistrict, province, scopeCode, scopeName } = values;
    const addrPath = getAddrPath(province, district, subDistrict);
    let arr: any = addrPath?.split("/");
    if (ACTION === "NEW") {
      let arrScopeD: any = [];
      mapData.map((item: any) => {
        let result = subDistrictList.filter(
          (ele: any) => ele.tambon === item.locationName
        );
        const scopeD = {
          locationCode: result[0].tambonCode,
          lat: item.lat,
          lng: item.lng,
          scopeName,
          tambon: item.locationName,
        };
        arrScopeD.push(scopeD);
      });
      const data = {
        addrPath,
        color: color,
        createUserid: userSession.secUserId,
        createUsername: userSession.fullname,
        custUId: userSession.customerUId,
        id: null,
        scopeCode,
        scopeName,
        stationId: STATION_ID,
        stationName: STATION_NAME,
        scopeDetail: arrScopeD,
        savStation: userSession.stationCode,
        useFlg: isUse === true ? 0 : -1,
      };
      // console.log(data);
      const response = await bookingService.saveScope(data);
      if (response.data) {
        message.success("กำหนดขอบเขตสำเร็จ");
      }
    } else if (ACTION === "EDIT") {
      const data = {
        addrPath,
        custUId: userSession.customerUId,
        lat: mapData[0].lat,
        lng: mapData[0].lng,
        id: SCOPE_DETAIL_ID,
        province,
        scopeCode,
        scopeName,
        tambonCode: arr[2],
        tambon: subDistrict,
        savStation: userSession.stationCode,
        useFlg: isUse === true ? 0 : -1,
        updateUserid: userSession.secUserId,
        updateUsername: userSession.fullname,
      };
      const response = await bookingService.updateScopeDetail(data);
      if (response.data) {
        message.success("แก้ไขขอบเขตสำเร็จ");
        history.goBack();
      } else {
        console.log(response.data);
      }
    }
  };

  const getAddrPath = (...val: any) => {
    if (ACTION === "NEW") {
      return `${val[0]}/${val[1]}/${val[2]}`;
    } else if (ACTION === "EDIT") {
      let provinceCode = "";
      let districtCode = "";
      let subDistrictCode = "";

      if (isNaN(Number(val[0].charAt(0)))) {
        let result = provinceList.filter(
          (item: any) => item.province === val[0]
        );
        provinceCode = result[0].provinceCode;
      } else {
        provinceCode = val[0].toString();
      }

      if (isNaN(Number(val[1].charAt(0)))) {
        let result = districtList.filter((item: any) => item.amphoe === val[1]);
        districtCode = result[0].amphoeCode;
      } else {
        districtCode = val[1].toString();
      }

      if (isNaN(Number(val[2].charAt(0)))) {
        let result = subDistrictList.filter(
          (item: any) => item.tambon === val[2]
        );
        subDistrictCode = result[0].tambonCode;
      } else {
        subDistrictCode = val[2].toString();
      }
      return `${provinceCode}/${districtCode}/${subDistrictCode}`;
    }
  };

  const handleSubDistrict = (subDistrict: any) => {
    const result = subDistrictList.filter(
      (item: any) => item.tambonCode === subDistrict
    );
    // console.log(result);
    return result[0].tambon;
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
    message.warning("กรุณาระบุข้อมูลให้สมบูรณ์!");
  };

  const handleChangeScopeCode = async (e: any) => {
    // console.log(e.target.value);
    // let val = e.target.value;
    // const response = await bookingService.findStationLastCode(val);
    // if(response.data) {
    // }
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <Row style={{ height: "100%" }}>
        <Col
          span={7}
          style={{ backgroundColor: "#fff", borderRadius: 5, padding: 15 }}
        >
          <Space direction="vertical" style={{ width: "100%", marginTop: 15 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {ACTION === "NEW" ? (
                <Title level={4}>กำหนดขอบเขต</Title>
              ) : (
                <Title level={4}>แก้ไขตำบลในขอบเขต</Title>
              )}

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {ACTION === "NEW" ? (
                  <>
                    <Switch
                      checked={isUse}
                      onChange={(e) => setIsUse(e)}
                      size="default"
                    />
                    <div style={{ marginLeft: 5, fontWeight: "bold" }}>
                      ใช้งาน
                    </div>
                  </>
                ) : null}
              </div>
            </div>
            {ACTION === "NEW" ? (
              <RAutocomplete
                autoValue={autoValue}
                setAutoValue={setAutoValue}
              />
            ) : null}

            <div style={{ marginTop: 10 }}>
              <Form
                form={form}
                name="createScope"
                labelCol={{}}
                wrapperCol={{}}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item
                  name="scopeCode"
                  rules={[{ required: true, message: "กรุณาระบุ รหัสขอบเขต!" }]}
                >
                  <Input
                    onChange={(e: any) => handleChangeScopeCode(e)}
                    placeholder="รหัสของเขต"
                    readOnly={ACTION === "EDIT" ? true : false}
                    // suffix={
                    //   <Tooltip title="Extra information">
                    //     <IoAlertCircleOutline
                    //       size={20}
                    //       style={{ color: "lightgray" }}
                    //     />
                    //   </Tooltip>
                    // }
                  />
                </Form.Item>

                <Form.Item
                  name="scopeName"
                  rules={[{ required: true, message: "กรุณาระบุ ชื่อขอบเขต!" }]}
                >
                  <Input
                    placeholder="ชื่อของเขต"
                    readOnly={ACTION === "EDIT" ? true : false}
                  />
                </Form.Item>

                <Form.Item
                  name="province"
                  rules={[{ required: true, message: "กรุณาระบุ จังหวัด!" }]}
                >
                  <Select
                    placeholder="จังหวัด"
                    onChange={handleChangeProvince}
                    disabled={ACTION === "EDIT" ? true : false}
                  >
                    {provinceList.map((item: any, index: number) => (
                      <Option key={index} value={item.provinceCode}>
                        {item.province}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="district"
                  rules={[{ required: true, message: "กรุณาระบุ อำเภอ!" }]}
                >
                  <Select
                    placeholder="อำเภอ"
                    onChange={handleChangeDistrict}
                    // disabled={ACTION === "EDIT" ? true : false}
                  >
                    {districtList.map((item: any, index: number) => (
                      <Option key={index} value={item.amphoeCode}>
                        {item.amphoe}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="subDistrict"
                  rules={[{ required: true, message: "กรุณาระบุ ตำบล!" }]}
                >
                  <Select placeholder="ตำบล" onChange={handleChangeSubDistrict}>
                    {subDistrictList.map((item: any, index: number) => (
                      <Option key={index} value={item.tambonCode}>
                        {item.tambon}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                {ACTION === "NEW" ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <CirclePicker
                        onChangeComplete={(color) => setColor(color.hex)}
                      />
                      <Button
                        type="primary"
                        style={{ marginTop: 20 }}
                        onClick={() => handleAddScope()}
                      >
                        เพิ่มตำบล
                      </Button>
                    </div>
                    <div style={{ marginTop: "2rem" }}>
                      <TableScope
                        data={mapData}
                        remove={handleDeleteScopeItem}
                      />
                    </div>
                  </>
                ) : (
                  <div></div>
                )}

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "2rem",
                  }}
                >
                  <Space>
                    <Form.Item>
                      <Button
                        htmlType="button"
                        onClick={() => history.goBack()}
                      >
                        กลับ
                      </Button>
                    </Form.Item>
                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        บันทึก
                      </Button>
                    </Form.Item>
                  </Space>
                </div>
              </Form>
            </div>
          </Space>
        </Col>
        <Col span={17}>
          <RMap
            centerCoordinate={centerCoordinate}
            callBack={getMapDetail}
            data={mapData}
            setIsPin={setIsPin}
            setData={setMapData}
            condition={color}
          />
        </Col>
      </Row>
    </div>
  );
};

const CreateScope: React.FC = (props: any) => {
  // console.log(props);
  const { action, stationId, stationName, scopeDetailId } =
    props.location.state;
  const { addrPath, province, lat, lng } = props.location.state.station;
  ACTION = action;
  STATION_NAME = stationName;
  STATION_ID = stationId;
  SCOPE_DETAIL_ID = scopeDetailId;

  let pid = addrPath.split("/");
  PROVINCE_ID = pid[0];
  PROVINCE_NAME = province;
  DISTRICT_ID = pid[1];
  LAT = lat;
  LNG = lng;

  const MapLoader: any = withScriptjs(Wrapper);
  return (
    <MapLoader
      googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCY8tssCzJWXdG7BnWMr5EmiljzHL4zp0o&v=3.exp&libraries=geometry,drawing,places"
      loadingElement={<div style={{ height: `100%` }} />}
    />
  );
};

export default CreateScope;
