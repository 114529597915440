import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { v4 as uuid } from "uuid";
import {
  Button,
  Select,
  Col,
  Form,
  Row,
  Input,
  Typography,
  InputNumber,
  message,
  Popconfirm,
  Space,
} from "antd";
import bookingService from "src/services/bookingService";
import { useAppSelector } from "src/store";
import { getUserSession } from "src/store/authen/action";
import { IoCloseCircle } from "react-icons/io5";
import { idText } from "typescript";
import { assertWhile } from "@babel/types";

const dummyRoles = [
  //{ id: "1", label: "Admin", value: "ADMIN" },
  // { id: "2", label: "Manager", value: "Manager" },
  { id: "1", label: "User", value: "USER" },
];

const AddUserInStation: React.FC = (props: any) => {
  const { ACTION: action, id } = props.location.state;
  const ACTION = action;
  const ID = id;

  const [custStation, setCustStation] = useState<any>([]);
  const [isSaved, setIsSaved] = useState<any>(false);
  const [loaded, setLoaded] = useState<any>(false);
  const [loading, setLoading] = useState<any>(false);
  const [prenameList, setPrenameList] = useState<any>([]);
  const [stationList, setStationList] = useState<any>([]);
  const [staffData, setStaffData] = useState<any>({});
  const [userUId, setUserUId] = useState<any>("");

  const history = useHistory();

  const [form] = Form.useForm();

  const { Option } = Select;

  const { Title } = Typography;

  useEffect(() => {
    if (!loaded) {
      init();
      setLoaded(true);
    }
  }, []);

  const init = async () => {
    setLoading(true);
    if (ACTION === "NEW") {
      const { customerId } = getUserSession();
      loadCustStation(customerId);
    } else if (ACTION === "EDIT") {
      setIsSaved(true);
      const { customerId } = getUserSession();
      loadCustStation(customerId);
      const response = await bookingService.findDataStaff(id);
      if (response.data) {
        const { email, secFullname, password, role, tel, userUId, username } =
          response.data;
        setUserUId(userUId);
        let arr = secFullname.split(" ");
        form.setFieldsValue({
          firstname: arr[0],
          lastname: arr[1],
          tel,
          email,
          username,
          password,
          role,
        });

        const response_ = await bookingService.findDataStationReferStaff(
          userUId
        );
        if (response_.data) {
          setStationList(response_.data);
        }
      }
    }
    setLoading(false);
  };

  const loadPrename = async () => {
    const response = await bookingService.loadPrename();
    if (response.data.length > 0) {
      setPrenameList(response.data);
    }
  };

  const loadCustStation = async (customerId: any) => {
    const response = await bookingService.loadCustStation(customerId);
    if (response.data.length > 0) {
      setCustStation(response.data);
    }
  };

  const handleChangeRole = (value: any) => {
    // console.log("Role ", value);
  };

  const handleChangeStation = async (value: any) => {
    let isRepeat = false;
    stationList.map((item: any) => {
      if (Number(item.custStationId) === value) {
        isRepeat = true;
        return;
      }
    });

    if (isRepeat) {
      message.warning("สถานี้มีอยู่แล้ว!");
      return;
    }

    const { customerId, fullname, secUserId, stationCode } = getUserSession();
    let result = custStation.filter((item: any) => item.id === value);
    const { id, name } = result[0];
    let arr: any = [];
    let data: any = {
      custStationId: id,
      name,
    };
    let st = [...stationList];
    st.push(data);

    if (ACTION === "NEW") {
      const { userUId } = staffData;
      st.map((item: any) => {
        const data: any = {
          createUserid: secUserId,
          createUsername: fullname,
          custId: customerId,
          userUId,
          secUserId,
          stationId: Number(item.custStationId),
          savStation: stationCode,
        };
        arr.push(data);
      });
      // console.log(arr);
      const response = await bookingService.saveStationReferStaff(arr);
      if (response.data) {
        setStationList((prev: any) => [...prev, data]);
        message.success("บันทึกสถานีสำเร็จ");
      } else {
        console.log(response.data);
      }
    } else if (ACTION === "EDIT") {
      st.map((item: any) => {
        const data: any = {
          createUserid: secUserId,
          createUsername: fullname,
          custId: customerId,
          userUId,
          newStaffId: ID,
          secUserId,
          savStation: stationCode,
          stationId: Number(item.custStationId),
        };
        arr.push(data);
      });
      // console.log(arr);
      const response = await bookingService.saveStationReferStaff(arr);
      if (response.data) {
        // console.log(response.data);
        setStationList((prev: any) => [...prev, data]);
        message.success("บันทึกสถานีสำเร็จ");
      } else {
        console.log(response.data);
        // message.warning("บันทึกไม่สำเร็จ")
      }
    }
  };

  const hanldeDelStation = async (value: any, stationId: any) => {
    let arr = stationList.filter((item: any) => item.id !== stationId);
    const response = await bookingService.deleteStationReferStaff(value.id);
    if (response.data) {
      message.success("ลบสถานีสำเร็จ");
      setStationList(arr);
    } else {
      console.log(response.data);
    }
  };

  const onFinish = async (values: any) => {
    if (ACTION === "NEW") {
      let uuid_ = uuid();
      let arr = uuid_.split("-");
      let userUId = "BB-" + arr.pop();
      const { fullname, secUserId, customerUId, stationCode } =
        getUserSession();
      const { email, firstname, lastname, password, role, tel, username } =
        values;
      const data: any = {
        createUserid: secUserId,
        createUsername: fullname,
        custUId: customerUId,
        savStation: stationCode,

        // sec_user
        userUId,
        secUserId,
        secFullname: `${firstname} ${lastname}`,
        username,
        password,
        tel,
        email,

        // sec_user_app
        appName: "BUSINESS_CUST",
        refUId: customerUId,

        // sec_user_business
        role,
      };
      // console.log(data);
      const response = await bookingService.saveNewStaff(data);
      if (response.data.secUserId !== null) {
        const { userUId, secUserId } = response.data;
        const data: any = {
          userUId,
          secUserId,
        };
        setStaffData(data);
        setIsSaved(true);
      } else {
        console.log("Save new staff error");
      }
    } else if (ACTION === "EDIT") {
      console.log(getUserSession());
      const {
        secUserId,
        fullname: updateUsername,
        stationCode,
      } = getUserSession();
      const { email, firstname, lastname, password, tel, username, role } =
        form.getFieldsValue();
      const data: any = {
        email,
        password,
        role,
        secUserId: ID,
        secFullname: `${firstname} ${lastname}`,
        savStation: stationCode,
        tel,
        username,
        updateUserid: secUserId,
        updateUsername: updateUsername,
      };
      // console.log(data);
      const response = await bookingService.updateStaff(data);
      // console.log(response.data);
      if (response.data) {
        message.info("แก้ข้อมูลสำเร็จ");
        init();
      }
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div
      style={{
        backgroundColor: "#fff",
        padding: 15,
        borderRadius: 5,
        height: "100%",
      }}
    >
      <Title level={4} style={{ fontWeight: "bold" }}>
        {ACTION === "NEW"
          ? "เพิ่มผู้ใช้(ภายในสถานี)"
          : "แก้ใขผู้ใช้(ภายในสถานี)"}
      </Title>
      <Row gutter={8} style={{ marginTop: 15 }}>
        <Col span={11}>
          <Form
            autoComplete="off"
            form={form}
            // initialValues={{
            //   prename:{id:2, name:"นาย"}
            // }}
            labelCol={{}}
            name="form"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            wrapperCol={{}}
          >
            <Title level={4}>ข้อมูล</Title>
            {/* <Form.Item
              name="prename"
              initialValue={"นาย"}
              // rules={[{ required: true, message: "กรุณาระบุ ชื่อ!" }]}
            >
              <Select placeholder="คำนำหน้าชื่อ">
                {prenameList.map((item: any, index: number) => (
                  <Option key={index} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item> */}

            <Form.Item
              name="firstname"
              rules={[{ required: true, message: "กรุณาระบุ ชื่อ!" }]}
            >
              <Input placeholder="ชื่อ" />
            </Form.Item>
            <Form.Item
              name="lastname"
              rules={[{ required: true, message: "กรุณาระบุ สกุล!" }]}
            >
              <Input placeholder="สกุล" />
            </Form.Item>
            <Form.Item
              name="tel"
              rules={[{ required: true, message: "กรุณาระบุ เบอร์โทร!" }]}
            >
              <Input placeholder="เบอร์โทร" type="number" />
            </Form.Item>
            <Form.Item
              name="email"
              rules={[
                {
                  type: "email",
                  message: "อีเมล์ไม่ถูกต้อง!",
                },
                { required: true, message: "กรุณาระบุ อีเมล์!" },
              ]}
            >
              <Input placeholder="อีเมล์" />
            </Form.Item>

            <Form.Item
              name="username"
              rules={[{ required: true, message: "กรุณาระบุ ชื่อผู้ใช้!" }]}
            >
              <Input placeholder="ชื่อผู้ใช้" />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[{ required: true, message: "กรุณาระบุ รหัสผ่าน!" }]}
            >
              <Input.Password placeholder="รหัสผ่าน" />
            </Form.Item>

            <Form.Item
              name="confirm"
              // label="Confirm Password"
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "กรุณายืนยันรหัสผ่าน!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("รหัสยืนยันไม่ตรงกับรหัสผ่าน !")
                    );
                  },
                }),
              ]}
            >
              <Input.Password placeholder="ยืนยันรัหสผ่าน" />
            </Form.Item>

            <Form.Item
              name="role"
              rules={[{ required: true, message: "กรุณาระบุ บทบาท!" }]}
            >
              <Select placeholder="บทบาท" onChange={handleChangeRole}>
                {dummyRoles.map((item: any, index: number) => (
                  <Option key={index} value={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Space>
                <Form.Item>
                  <Button
                    htmlType="button"
                    onClick={() => history.goBack()}
                    style={{ marginLeft: 5 }}
                  >
                    กลับ
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    {ACTION === "NEW" ? "บันทึก" : "แก้ใข"}
                  </Button>
                </Form.Item>
              </Space>
            </div>
          </Form>
        </Col>
        <Col span={2}></Col>
        <Col span={11}>
          {isSaved ? (
            <>
              <Title level={4}>สถานี</Title>
              <Select
                placeholder="สถานี"
                style={{ width: "100%" }}
                onChange={handleChangeStation}
              >
                {custStation.map((item: any, index: number) => (
                  <Option key={index} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>

              {/* Display station items */}
              <div style={{ marginTop: "1rem" }}>
                {stationList.map((item: any, index: any) => {
                  return (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginTop: 5,
                      }}
                    >
                      <div>{item.name}</div>
                      <Popconfirm
                        title={`คุณต้องการลบ ${item.name} ใช่ หรือ ไม่`}
                        onConfirm={(e) => hanldeDelStation(item, item.id)}
                        onCancel={(e) => console.log(e)}
                        okText="ใช่"
                        cancelText="ยกเลิก"
                      >
                        <IoCloseCircle
                          size={20}
                          color="#ff0000"
                          style={{ cursor: "pointer" }}
                        />
                      </Popconfirm>
                    </div>
                  );
                })}
              </div>
            </>
          ) : null}
        </Col>
      </Row>
    </div>
  );
};

export default AddUserInStation;
