import React, { useState, useEffect } from "react";
// import { useAppDispatch, useAppSelector } from "src/store";
import { useHistory } from "react-router";
import { getUserSession } from "src/store/authen/action";

const Dashboard = () => {
  // const dispatch = useAppDispatch();
  // const { sessionUser } = useAppSelector((state) => state.auth);
  const [userSession, setUserSession] = useState<any>({});
  const history = useHistory();
  useEffect(() => {
    if (getUserSession() === null) {
      history.replace("/login");
      return;
    }
    setUserSession(getUserSession());
  }, []);

  return (
    <div
      style={{
        backgroundColor: "#fff",
        padding: 15,
        borderRadius: 5,
        height: "100%",
        textAlign: "center",
      }}
    >
      <div style={{ fontSize: 30 }}>Dashboard</div>
      <div style={{ fontSize: 30 }}>
        {userSession &&
          `Hello : ${userSession.fullname} | Role : ${userSession.role} | ${userSession.stationName}`}
      </div>
    </div>
  );
};

export default Dashboard;
