import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { AppThunk } from "../index";
import { SessionUser } from "./types";

interface AuthState {
  // sessionUser: SessionUser;
  sessionUser: any;
}

const initialState: AuthState = {
  // sessionUser: { username: "", email: "" },
  sessionUser: {}
};

const slice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    getSessionUserFromLocal(
      state: AuthState,
      action: PayloadAction<any>
    ): void {
      console.log("action payload", action.payload);
      // if (action.payload.username !== "") {
        state.sessionUser = action.payload;
        // console.log("set");
      // } else {
      //   console.log("not set");
      // }
    },
    // setSessionuser(state: AuthState, action: PayloadAction<SessionUser>): void {
    //   state.sessionUser = action.payload;
    // },
    
    setSessionUser(state: AuthState, action: PayloadAction<any>): void {
      // console.log('Slice : ',action.payload)
      state.sessionUser = action.payload;
    },

    // clearSessionuser(state: AuthState, action: PayloadAction<SessionUser>) : void {
    //   state.sessionUser = action.payload;
    // }
  },
});

export const { reducer } = slice;

export const setSessionUser = slice.actions.setSessionUser;
export const getSessionUserFromLocal = slice.actions.getSessionUserFromLocal;
// export const clearSessionuser = slice.actions.clearSessionuser;
