import React, { useState, useEffect } from "react";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Select,
  Space,
  Table,
  Typography,
} from "antd";
import { useHistory } from "react-router-dom";

import testService from "src/services/testService";

import moment from "moment";
import { StringDate } from "src/utils";

const BookingList: React.FC = (props: any) => {
  const [drivers, setDrivers] = useState<any>([]);
  const [dataSource, setDataSource] = useState<any>([]);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [truckRegists, setTruckRegist] = useState<any>([]);
  const [trigger, setTrigger] = useState<number>(0);

  const history = useHistory();

  const [formDateTime] = Form.useForm();

  const { Title } = Typography;
  const { Option } = Select;

  const dateFormat = "DD/MM/YYYY";

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!loaded) {
        init();
        setLoaded(true);
      }
    });
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const init = () => {
    defaultDate();
    getTruckRegister();
    getDriver();
  };

  const getTruckRegister = async () => {
    const response = await testService.getTruckRegister();
    if (response.data) {
      setTruckRegist(response.data);
    }
  };

  const getDriver = async () => {
    const response = await testService.getDriver();
    if (response.data) {
      setDrivers(response.data);
    }
  };

  const defaultDate = () => {
    formDateTime.setFieldsValue({
      bookDateStart: moment(new Date(), dateFormat),
      bookDateEnd: moment(new Date(), dateFormat),
    });
  };

  const onFinish = async (data: any) => {
    // console.log("Data : ", data);
    let value = formDateTime.getFieldsValue();
    const values = {
      ...formDateTime.getFieldsValue(),
      bookDateStart: value["bookDateStart"].format("YYYYMMDD"),
      bookDateEnd: value["bookDateEnd"].format("YYYYMMDD"),
    };
    const { bookDateStart, bookDateEnd, driver, truckRegist } = values;
    const search: any = {
      bookDateStart,
      bookDateEnd,
      driver,
      truckRegist,
    };
    const response = await testService.getSearch(search);
    if (response.data) {
      const { data } = response;
      let arr: any = [];
      let count: number = 1;
      data.map((item: any) => {
        let dt = data.filter((ele: any) => ele.seq === item.seq);
        let f = dt[0];
        let l = dt[dt.length - 1];
        if (arr.length === 0) {
          let newData: any = {
            seq: f.seq,
            seqs: count++,
            bookDateStart: StringDate(f.createDate, "TH", "/"),
            truckRegist: f.registId,
            detailId: f.detailId,
            driver: f.driverName,
            origin: f.stationName,
            destination: l.stationName,
            status: f.useFlg,
          };
          arr.push(newData);
        } else {
          let last = arr[arr.length - 1];
          if (last.seq !== f.seq) {
            let newData: any = {
              seq: f.seq,
              seqs: count++,
              bookDateStart: StringDate(f.createDate, "TH", "/"),
              truckRegist: f.registId,
              detailId: f.detailId,
              driver: f.driverName,
              origin: f.stationName,
              destination: l.stationName,
              status: f.useFlg,
            };
            arr.push(newData);
          }
        }
      });
      setDataSource(arr);
    }
  };

  const onFailure = (err: any) => {
    console.log("Error : ", err);
  };

  const columns = [
    {
      title: "ลำดับ",
      dataIndex: "seqs",
      key: "seqs",
      width: 50,
    },
    {
      align: "center" as "center",
      title: "วันที่จอง",
      dataIndex: "bookDateStart",
      key: "bookDateStart",
      render: (text: any, props: any) => {
        const { driver, seq, truckRegist } = props;
        return (
          <a
            onClick={() =>
              history.push("BookingTest", {
                params: { driver, seq, truckRegist },
                action: "CANCEL",
              })
            }
          >
            {text}
          </a>
        );
      },
    },
    {
      title: "ทะเบียนรถ",
      dataIndex: "truckRegist",
      key: "truckRegist",
    },
    {
      title: "พขร.",
      dataIndex: "driver",
      key: "driver",
    },
    {
      title: "ต้นทาง",
      dataIndex: "origin",
      key: "origin",
    },
    {
      title: "ปลายทาง",
      dataIndex: "destination",
      key: "destination",
    },
    {
      title: "สถานะ",
      dataIndex: "status",
      key: "status",
      width: 130,
      render: (text: any, props: any) => {
        let statusText: any = "";
        let { status } = props;
        if (status === 0) {
          statusText = "สร้าง/ยืนยันจอง";
        } else if (status === 1) {
          statusText = "ใช้งาน";
        } else if (status === -1) {
          statusText = "ยกเลิก";
        } else {
          console.log("Status is incorrect !");
        }
        return <div>{statusText}</div>;
      },
    },
  ];

  return (
    <div
      style={{
        backgroundColor: "#fff",
        paddingTop: 10,
        paddingRight: 10,
        paddingBottom: 10,
        paddingLeft: 10,
      }}
    >
      <Title level={3}>โปรแกมจองรถ</Title>
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <Space>
          <Button type="default" onClick={() => history.goBack()}>
            กลับ
          </Button>
          <Button
            type="primary"
            onClick={() =>
              history.push("BookingQuickLineHall", {
                params: {},
                action: "ADD",
              })
            }
          >
            จองรถ
          </Button>
        </Space>
      </div>

      <div>
        <Form
          name="formDateTime"
          form={formDateTime}
          onFinish={onFinish}
          onFinishFailed={onFailure}
          autoComplete="off"
        >
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              marginTop: 30,
            }}
          >
            <Form.Item
              colon={false}
              label="วันที่จองรถ ตั้งแต่"
              name="bookDateStart"
              rules={[{ required: true, message: "กรุณาเลือกวันที่จองรถ" }]}
            >
              <DatePicker
                defaultValue={moment(new Date(), dateFormat)}
                format={dateFormat}
                style={{ width: 180 }}
              />
            </Form.Item>
            <Form.Item colon={false} label="ทะเบียนรถ">
              <Space>
                <Form.Item name="truckRegist">
                  <Select placeholder="ทะเบียนรถ" style={{ width: 180 }}>
                    {truckRegists.map((item: any) => {
                      return (
                        <Option key={item.registId} value={item.registId}>
                          {item.registId}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <div
                  style={{
                    marginTop: -15,
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                  onClick={() =>
                    formDateTime.setFieldsValue({ truckRegist: undefined })
                  }
                >
                  ล้าง
                </div>
              </Space>
            </Form.Item>
          </div>

          <div
            style={{
              alignItems: "center",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              marginTop: -20,
            }}
          >
            <Form.Item
              colon={false}
              label="ถึง"
              name="bookDateEnd"
              rules={[{ required: true, message: "กรุณาเลือกวันที่จองรถถึง" }]}
              style={{ marginLeft: 77 }}
            >
              <DatePicker
                defaultValue={moment(new Date(), dateFormat)}
                format={dateFormat}
                style={{ width: 180 }}
              />
            </Form.Item>
            <Form.Item
              colon={false}
              label="พนักงานขับรถ"
              style={{ marginLeft: -20 }}
            >
              <Space>
                <Form.Item name="driver">
                  <Select placeholder="พนักงานขับรถ" style={{ width: 180 }}>
                    {drivers.map((item: any) => {
                      return (
                        <Option key={item.driverUId} value={item.driverUId}>
                          {`${item.firstname} ${item.lastname}`}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <div
                  style={{
                    marginTop: -15,
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                  onClick={() =>
                    formDateTime.setFieldsValue({ driver: undefined })
                  }
                >
                  ล้าง
                </div>
              </Space>
            </Form.Item>
          </div>

          <div style={{ marginTop: 30, textAlign: "center" }}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                ค้นหา
              </Button>
            </Form.Item>
          </div>

          <div style={{ marginTop: 30 }}>
            <Table
              columns={columns}
              dataSource={dataSource}
              size="middle"
              rowKey={(item: any) => item.seqs}
            />
          </div>
        </Form>
      </div>
    </div>
  );
};

export default BookingList;
