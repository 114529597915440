import React, { memo } from "react";
import { message } from "antd";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng,
} from "react-google-places-autocomplete";

interface IProps {
  value: any;
  setValue: any;
}

const RAutoComplete: React.FC<IProps> = (props: any) => {
  const { value, setValue } = props;
  return (
    <GooglePlacesAutocomplete
      // apiKey={MAP_API}
      apiOptions={{ language: "th", region: "th" }}
      debounce={300}
      onLoadFailed={(err) => message.warning(`${err}`, 10)}
      selectProps={{
        placeholder: "ป้อนตำแหน่ง...",
        value,
        onChange: setValue,
      }}
    />
  );
};

export default memo(RAutoComplete);
