import React, { useState, useEffect, useRef } from "react";

import { Button, DatePicker, Form, message, Drawer, TimePicker } from "antd";
import { IoCopyOutline } from "react-icons/io5";
import moment from "moment";
import bookingService from "src/services/bookingService";
import { StringDate, StringDateCutSlash, StringTimeCutColon } from "src/utils";
import { getUserSession } from "src/store/authen/action";

interface IProps {
  data: any;
  getCallBackData?: any;
  loadData: any;
}

const DrawerDistribute: React.FC<IProps> = (props: any) => {
  const { data, loadData, getCallBackData } = props;
  const [visible, setVisible] = useState<any>(false);
  const [userSession, setUserSession] = useState<any>(getUserSession());

  useEffect(() => {
    defaultDate();
    return () => {};
  }, []);

  const copyFormRef = useRef(null);
  const [copyForm] = Form.useForm();

  const defaultDate = () => {
    copyForm.setFieldsValue({
      stDate: moment(new Date(), "YYYYMMDD"),
      // stTime: moment(new Date(), "HHmmss"),
    });
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  // const handleChangeDate = (data: any, dataString: any, params: any) => {
  //   // Swap MM/DD/YYYY to YYYY/MM/DD
  //   let arrDate = dataString.split("/");
  //   const temp = arrDate[0];
  //   arrDate[0] = arrDate[2];
  //   arrDate[2] = temp;
  //   let startDate = arrDate.join("/");

  //   let now = moment(new Date()).format("YYYYMMDD");
  //   let date = moment(new Date(`${startDate}`)).format("YYYYMMDD");

  //   if (Number(date) < Number(now)) {
  //     message.warning(
  //       "วันใช้งานต้องเป็นวันปัจจุบัน หรือ วันที่มากกว่าวันปัจจุบันเท่านั้น!",
  //       6
  //     );
  //     return;
  //   }

  //   let newData = { ...dateTime };
  //   if (params === "startDate") {
  //     newData.startDate = dataString;
  //   }
  //   setDateTime(newData);
  // };

  // const handleChangeTime = (data: any, dataString: any, params: any) => {
  //   let newTime = { ...dateTime };
  //   if (newTime.startDate === "" || newTime.startDate === null) {
  //     message.warning("กรุณาเลือกวันที่ใช้งานก่อน!");
  //     return;
  //   }
  //   if (params === "startTime") {
  //     newTime.startTime = dataString;
  //   }
  //   setDateTime(newTime);
  // };

  // const handleBooking = async () => {
  //   const {
  //     driverUId,
  //     driverName,
  //     paymentTypeId,
  //     registId,
  //     totalAmt,
  //     truckUId,
  //     truckTypeId,
  //     serviceAmt,
  //     scopeHeadId,
  //   } = data[0];
  //   let arr = [];
  //   for (let i = 0; i < data.length; i++) {
  //     let datas = {
  //       locationName: data[i].locationName,
  //       lat: data[i].lat,
  //       lng: data[i].lng,
  //     };
  //     arr.push(datas);
  //   }

  //   let isValid = checkBeforeSave();
  //   if (isValid) {
  //     const data = {
  //       agreementId: "0",
  //       bookType: "DISTRIBUTE",
  //       custType: "BB",
  //       createUserid: userSession.secUserId,
  //       createUsername: userSession.fullname,
  //       custUId: userSession.customerUId,
  //       custName: userSession.fullname,
  //       distance: "",
  //       driverUId: driverUId,
  //       driverName: driverName,
  //       discountAmt: 0.0,
  //       duration: "",
  //       endDate: "",
  //       endTime: "",
  //       inoutStation: "",
  //       serviceAmt: serviceAmt,
  //       startDate: StringDateCutSlash(dateTime.startDate),
  //       startTime: StringTimeCutColon(dateTime.startTime),
  //       totalAmt: totalAmt,
  //       truckTypeId: truckTypeId,
  //       truckUId: truckUId,
  //       registId: registId,
  //       releaseDate: "",
  //       releaseTime: "",
  //       paymentTypeId: paymentTypeId,
  //       savStation: userSession.stationCode,
  //       status: "01",
  //       scopeHeadId: scopeHeadId,
  //       routes: arr,
  //     };
  //     //   console.log("Booking ", data);
  //     const response = await bookingService.bookBB(data);
  //     if (response.data.bookNo) {
  //       message.success(
  //         `จองรถสำเร็จ เลขที่การจองรถของคุณ ${response.data.bookNo}`,
  //         8
  //       );
  //       setTimeout(() => {
  //         refreshPage();
  //       }, 9000);
  //     } else {
  //       console.log(response.data.bookNo);
  //     }
  //   }
  // };

  const refreshPage = () => {
    window.location.reload();
  };

  const onFinish = async (val: any) => {
    const {
      driverUId,
      driverName,
      paymentTypeId,
      registId,
      totalAmt,
      truckUId,
      truckTypeId,
      serviceAmt,
      scopeHeadId,
    } = data[0];

    let arr = [];
    for (let i = 0; i < data.length; i++) {
      let datas = {
        locationName: data[i].locationName,
        lat: data[i].lat,
        lng: data[i].lng,
      };
      arr.push(datas);
    }

    let isValid = checkBeforeSave_();
    if (isValid) {
      let value = copyForm.getFieldsValue();
      const values = {
        ...copyForm.getFieldsValue(),
        stDate: value["stDate"].format("YYYYMMDD"),
        stTime: value["stTime"].format("HHmmss"),
      };
      const { stDate, stTime } = values;

      const data = {
        agreementId: "0",
        bookType: "DISTRIBUTE",
        custType: "BB",
        createUserid: userSession.secUserId,
        createUsername: userSession.fullname,
        custUId: userSession.customerUId,
        custName: userSession.fullname,
        distance: "",
        driverUId: driverUId,
        driverName: driverName,
        discountAmt: 0.0,
        duration: "",
        endDate: "",
        endTime: "",
        inoutStation: "",
        serviceAmt: serviceAmt,
        startDate: stDate,
        startTime: stTime,
        totalAmt: totalAmt,
        truckTypeId: truckTypeId,
        truckUId: truckUId,
        registId: registId,
        releaseDate: "",
        releaseTime: "",
        paymentTypeId: paymentTypeId,
        savStation: userSession.stationCode,
        status: "05",
        scopeHeadId: scopeHeadId,
        routes: arr,
      };
      // console.log("Booking ", data);
      const response = await bookingService.bookBB(data);
      if (response.data.bookNo) {
        message.success(
          `คัดลอกจองรถสำเร็จ เลขที่การจองรถของคุณ ${response.data.bookNo}`,
          5
        );
        copyComplete();
      } else {
        console.log(response.data.bookNo);
      }
    }
  };

  const copyComplete = () => {
    onClose();
    getCallBackData({ isCopy: true });
  };

  const onFinishFailed = (err: any) => {
    console.log(err);
  };

  const checkBeforeSave_ = () => {
    let value = copyForm.getFieldsValue();
    const values = {
      ...copyForm.getFieldsValue(),
      stDate: value["stDate"].format("YYYY/MM/DD"),
    };
    const { stDate } = values;

    // let arrDate = stDate.split("/");
    // const temp = arrDate[0];
    // arrDate[0] = arrDate[2];
    // arrDate[2] = temp;
    // let startDate = arrDate.join("/");

    let now = moment(new Date()).format("YYYYMMDD");
    let date = moment(new Date(`${stDate}`)).format("YYYYMMDD");

    if (Number(date) < Number(now)) {
      message.warning(
        "วันใช้งานต้องเป็นวันปัจจุบัน หรือ วันที่มากกว่าวันปัจจุบันเท่านั้น!",
        6
      );
      return false;
    }
    return true;
  };

  return (
    <>
      <IoCopyOutline
        size={25}
        onClick={() => {
          showDrawer();
          loadData();
        }}
        style={{ cursor: "pointer" }}
      />
      <Drawer
        title={`คัดลอกข้อมูล รถกระจาย`}
        placement="right"
        onClose={onClose}
        width={500}
        visible={visible}
      >
        <Form
          name="drawerDistribute"
          form={copyForm}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          ref={copyFormRef}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Form.Item
              colon={false}
              label="วันที่ใช้งาน"
              name="stDate"
              rules={[{ required: true, message: "กรุณาเลือกวันที่!" }]}
            >
              <DatePicker format="DD/MM/YYYY" />
            </Form.Item>

            <Form.Item
              colon={false}
              label="เวลาที่ใช้งาน"
              name="stTime"
              rules={[{ required: true, message: "กรุณาเลือกเวลา!" }]}
            >
              <TimePicker format="HH:mm:ss" placeholder="เลือกเวลา" />
            </Form.Item>
            {/* <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                width: "46%",
              }}
            >
              <div style={{ fontWeight: "bold" }}>วันที่ใช้งาน</div>
              <div style={{ marginLeft: 10 }}>
                <DatePicker
                  format="DD/MM/YYYY"
                  onChange={(data, dataString) =>
                    handleChangeDate(data, dataString, "startDate")
                  }
                  placeholder="เลือกวัน"
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                width: "46%",
              }}
            >
              <div style={{ fontWeight: "bold" }}>เวลาที่ใช้งาน</div>
              <div>
                <TimePicker
                  format="HH:mm:ss"
                  onChange={(data, dataString) =>
                    handleChangeTime(data, dataString, "startTime")
                  }
                  placeholder="เลือกเวลา"
                />
              </div>
            </div> */}
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 20,
            }}
          >
            <Button
              type="default"
              htmlType="button"
              style={{ marginRight: 5 }}
              onClick={onClose}
            >
              ยกเลิก
            </Button>
            <Button type="primary" htmlType="submit">
              ตกลง
            </Button>
          </div>
        </Form>
      </Drawer>
    </>
  );
};

export default DrawerDistribute;
