import React, { useState, useEffect } from "react";
import { Button, Col, Row, Space, Typography } from "antd";
import { useHistory } from "react-router";
import { useAppDispatch } from "src/store";
import { setSessionUser } from "src/store/authen/action";

const SelectStation: React.FC = (props: any) => {
  const [loaded, setLoaded] = useState<any>(false);
  const [loading, setLoading] = useState<any>(false);
  const [stations, setStations] = useState<any>([]);
  const [pointor, setPointor] = useState<any>("");
  const [trigger, setTrigger] = useState<number>(0);

  const history = useHistory();
  const dispatch = useAppDispatch();

  const { Title } = Typography;

  useEffect(() => {
    if (!loaded) {
      init();
      setLoaded(true);
    }
  }, []);

  const init = () => {
    setLoading(true);
    let { data } = props.location.state;
    let arr: any = [];
    data.forEach((item: any, i: any) => {
      let data: any = {
        lat: item.lat,
        lng: item.lng,
        stationCode: item.custStationCode,
        stationId: item.custStationId,
        stationName: item.custStationName,
        isSelected: i === 0 ? true : false,
      };
      arr.push(data);
    });
    setStations(arr);
    setLoading(false);
  };

  useEffect(() => {
    if (pointor === "selectStation") {
      setStations(stations);
    }
  }, [trigger]);

  const handleSelectedStation = (id: any) => {
    stations.map((item: any) => {
      if (item.stationId === id) {
        item.isSelected = true;
      } else {
        item.isSelected = false;
      }
    });
    setPointor("selectStation");
    setTrigger((prev: number) => prev + 1);
  };

  const handleNext = () => {
    let { data } = props.location.state;
    let dataStation = stations.filter((item: any) => item.isSelected === true);

    const datas: any = {
      customerId: data[0].custId,
      customerUId: data[0].custUId,
      fullname: data[0].secUserFullname,
      lat: dataStation[0].lat,
      lng: dataStation[0].lng,
      role: data[0].secUserBusinessRole,
      secUserId: data[0].secUserId,
      stationId: dataStation[0].stationId,
      stationCode: dataStation[0].stationCode,
      stationName: dataStation[0].stationName,
    };
    // console.log(datas);
    dispatch(setSessionUser(datas));
    history.replace("/app");
  };

  return (
    <div style={{ height: "100%", backgroundColor: "" }}>
      <Row style={{ height: "100%" }}>
        <Col span={8}></Col>
        <Col span={8}>
          <div style={{ marginTop: "2rem", marginBottom: "2rem" }}>
            <Title level={1} style={{ fontWeight: "bold" }}>
              เลือก Station
            </Title>
            {stations.map((item: any, index: any) => {
              return (
                <div
                  key={index}
                  style={{
                    backgroundColor: item.isSelected ? "#4699B4" : "lightgray",
                    borderRadius: 5,
                    cursor: "pointer",
                    textAlign: "center",
                  }}
                  onClick={() => handleSelectedStation(item.stationId)}
                >
                  <Title level={1} style={{ color: "#fff", padding: 10 }}>
                    {item.stationName}
                  </Title>
                </div>
              );
            })}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Space>
              <Button size="large" onClick={() => history.replace("/login")}>
                ยกเลิก
              </Button>
              <Button
                htmlType="button"
                onClick={() => handleNext()}
                size="large"
                style={{
                  backgroundColor: "#4699B4",
                  borderColor: "#4699B4",
                  color: "#fff",
                }}
              >
                ถัดไป
              </Button>
            </Space>
          </div>
        </Col>
        <Col span={8}></Col>
      </Row>
    </div>
  );
};

export default SelectStation;
