import React, { useState, useEffect, useRef } from "react";
import { Button, DatePicker, Form, message, Drawer, TimePicker } from "antd";
import { IoCopyOutline } from "react-icons/io5";
import moment from "moment";
import bookingService from "src/services/bookingService";
import { StringDateCutSlash, StringTimeCutColon } from "src/utils";
import { getUserSession } from "src/store/authen/action";
import { useHistory } from "react-router";

interface IProps {
  getCallBackData?: any;
  data: any;
  loadData: any;
}

const DrawerLineHaul: React.FC<IProps> = (props: any) => {
  const { data, loadData, getCallBackData } = props;
  const [visible, setVisible] = useState<any>(false);
  const [userSession, setUserSession] = useState<any>(getUserSession());

  useEffect(() => {
    defaultDate();
    return () => {};
  }, []);

  const [copyForm] = Form.useForm();
  const copyFormRef = useRef(null);
  const history = useHistory();

  const defaultDate = () => {
    let today = new Date();
    let date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    let time = 8 + ":" + 0 + ":" + 0;
    copyForm.setFieldsValue({
      stDate: moment(new Date(), "YYYYMMDD"),
      stTime: moment(new Date(`${date} ${time}`)),
      rlDate: moment(new Date(), "YYYYMMDD"),
      // rlTime: moment(new Date(), "HHmmss"),
    });
  };
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };
  const copyComplete = () => {
    onClose();
    getCallBackData({ isCopy: true });
  };

  const calculateEndDateTime = (duration: any) => {
    let value = copyForm.getFieldsValue();
    const values = {
      ...copyForm.getFieldsValue(),
      stDate: value["stDate"].format("YYYY/MM/DD"),
      stTime: value["stTime"].format("HH:mm:ss"),
      rlDate: value["rlDate"].format("YYYY/MM/DD"),
      rlTime: value["rlTime"].format("HH:mm:ss"),
    };
    const { rlDate, rlTime } = values;
    let concat = `${rlDate} ${rlTime}`;
    let endDate = new Date(`${concat}`);
    endDate.setSeconds(duration);
    let newDate = new Date(`${endDate}`);

    let endDate_ = moment(newDate).format("YYYYMMDD HHmmss");
    let arrEndDate = endDate_.split(" ");
    return arrEndDate;
  };

  const onFinish = async (val: any) => {
    const {
      distance,
      duration,
      inoutStation,
      paymentTypeId,
      totalAmt,
      serviceAmt,
      truckTypeId,
    } = data[0];
    let arr = [];
    for (let i = 0; i < data.length; i++) {
      let datas = {
        custStationId: data[i].custStationId,
        locationName: data[i].locationName,
        lat: data[i].lat,
        lng: data[i].lng,
      };
      arr.push(datas);
    }

    let isValid = checkBeforeSave_();
    if (isValid) {
      let value = copyForm.getFieldsValue();
      const values = {
        ...copyForm.getFieldsValue(),
        stDate: value["stDate"].format("YYYYMMDD"),
        stTime: value["stTime"].format("HHmmss"),
        rlDate: value["rlDate"].format("YYYYMMDD"),
        rlTime: value["rlTime"].format("HHmmss"),
      };
      const { stDate, stTime, rlDate, rlTime } = values;

      let [edDate, edTime] = calculateEndDateTime(duration);
      const data = {
        agreementId: "0",
        bookType: "LINE-HAUL",
        custType: "BB",
        createUserid: userSession.secUserId,
        createUsername: userSession.fullname,
        custUId: userSession.customerUId,
        custName: userSession.fullname,
        distance: distance,
        driverUId: "",
        driverName: "",
        discountAmt: 0.0,
        duration: duration,
        endDate: edDate,
        endTime: edTime,
        inoutStation: inoutStation,
        serviceAmt: serviceAmt,
        startDate: stDate,
        startTime: stTime,
        totalAmt: totalAmt,
        truckTypeId: truckTypeId,
        truckUId: "",
        registId: "",
        releaseDate: rlDate,
        releaseTime: rlTime,
        paymentTypeId: paymentTypeId,
        savStation: userSession.stationCode,
        status: "00",
        routes: arr,
      };
      // console.log("Booking ", data);
      const response = await bookingService.bookBB(data);
      if (response.data.bookNo) {
        message.success(
          `คัดลอกจองรถสำเร็จ เลขที่การจองรถของคุณ ${response.data.bookNo}`,
          5
        );
        copyComplete();
      } else {
        console.log(response.data.bookNo);
      }
    }
  };

  const onFinishFailed = (err: any) => {
    console.log(err);
  };

  const checkBeforeSave_ = () => {
    let value = copyForm.getFieldsValue();
    const values = {
      ...copyForm.getFieldsValue(),
      stDate: value["stDate"].format("YYYY/MM/DD"),
      rlDate: value["rlDate"].format("YYYY/MM/DD"),
    };
    const { stDate, rlDate } = values;
    let now = moment(new Date()).format("YYYYMMDD");
    let date = moment(new Date(`${stDate}`)).format("YYYYMMDD");

    if (Number(date) < Number(now)) {
      message.warning(
        "วันใช้งานต้องเป็นวันปัจจุบัน หรือ วันที่มากกว่าวันปัจจุบันเท่านั้น!",
        6
      );
      return false;
    }

    let now_ = moment(new Date()).format("YYYYMMDD");
    let date_ = moment(new Date(`${rlDate}`)).format("YYYYMMDD");

    if (Number(date_) < Number(now_)) {
      message.warning(
        "วันที่ปล่อยรถต้องเป็นวันปัจจุบัน หรือ วันที่มากกว่าวันปัจจุบันเท่านั้น!",
        6
      );
      return false;
    }

    return true;
  };

  return (
    <>
      <IoCopyOutline
        size={25}
        onClick={() => {
          showDrawer();
          loadData();
        }}
        style={{ cursor: "pointer" }}
      />

      <Drawer
        title={`คัดลอกข้อมูล`}
        placement="right"
        onClose={onClose}
        width={500}
        visible={visible}
      >
        <Form
          name="drawerLineHual"
          form={copyForm}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          ref={copyFormRef}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              marginLeft: 9,
            }}
          >
            <Form.Item
              colon={false}
              label="วันที่ใช้งาน"
              name="stDate"
              rules={[{ required: true, message: "กรุณาเลือกวันที่!" }]}
            >
              <DatePicker
                format="DD/MM/YYYY"
                onChange={() => {
                  let value = copyForm.getFieldsValue();
                  copyForm.setFieldsValue({
                    ...copyForm.getFieldsValue(),
                    rlDate: value["stDate"],
                  });
                }}
              />
            </Form.Item>

            <Form.Item
              colon={false}
              label="เวลาที่ใช้งาน"
              name="stTime"
              rules={[{ required: true, message: "กรุณาเลือกเวลา!" }]}
            >
              <TimePicker format="HH:mm:ss" />
            </Form.Item>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: -5,
            }}
          >
            <Form.Item
              colon={false}
              label="วันที่ปล่อยรถ"
              name="rlDate"
              rules={[{ required: true, message: "กรุณาเลือกวันที่!" }]}
            >
              <DatePicker format="DD/MM/YYYY" />
            </Form.Item>

            <Form.Item
              colon={false}
              label="เวลาที่ปล่อยรถ"
              name="rlTime"
              rules={[{ required: true, message: "กรุณาเลือกเวลา!" }]}
            >
              <TimePicker format="HH:mm:ss" placeholder="เลือกเวลา" />
            </Form.Item>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 20,
            }}
          >
            <Form.Item>
              <Button
                htmlType="button"
                type="default"
                style={{ marginRight: 5 }}
                onClick={onClose}
              >
                ยกเลิก
              </Button>
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                ตกลง
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Drawer>
    </>
  );
};

export default DrawerLineHaul;
