import http from "../lib/http";

// const url = "http://localhost:8080/booking";
const url = "https://api12.nimsoft.xyz/booking";

// Booking test //
const getStation = () => {
  return http.get(url + "/bookingTest");
};

const bookBB = (data: any) => {
  return http.post(url + "/bookBB", data);
};

const copyLineHaul = (bookingId: any) => {
  return http.get(url + "/copyLineHaul", {
    params: { bookingId },
  });
};

const bookingStatus = (id: any, type: any) => {
  return http.get(url + "/bookingStatus", {
    params: { id, type },
  });
};

const copyDistribute = (bookingId: any) => {
  return http.get(url + "/copyDistribute", {
    params: { bookingId },
  });
};

const confirmBookingDistributeById = (data: any) => {
  return http.put(url + "/confirmBookingDistributeById", data);
};

const editScopeDetail = (id: any) => {
  return http.get(url + "/editScopeDetail", {
    params: { id },
  });
};

const findRouteHead = () => {
  return http.get(url + "/findRouteHead");
};

const findTruckAll = () => {
  return http.get(url + "/findTruckAll");
};

const findRouteHeadById = (id: number) => {
  return http.get(url + "/findRouteHeadById", {
    params: {
      id,
    },
  });
};

const findBookByBooked = (data: any) => {
  return http.get(url + "/findBookByBooked", {
    params: data,
  });
};

const findBookByConfirmed = (data: any) => {
  return http.get(url + "/findBookByConfirmed", {
    params: data,
  });
};

const findBookingLineHaul = (data: any) => {
  return http.get(url + "/findBookingLineHaul", {
    params: data,
  });
};

const findPartnerAll = () => {
  return http.get(url + "/findPartnerAll");
};

const findTruckRegistrationAll = () => {
  return http.get(url + "/findTruckRegistrationAll");
};

const findStationAll = () => {
  return http.get(url + "/findStationAll");
};

const findProvinceAll = () => {
  return http.get(url + "/findProvinceAll");
};

// find Amphoe
const findDistrict = (provinceCode: any) => {
  return http.get(url + "/findDistrict", {
    params: {
      provinceCode,
    },
  });
};

// find Tambon
const findSubDistrict = (provinceCode: any, districtCode: any) => {
  return http.get(url + "/findSubDistrict", {
    params: {
      provinceCode,
      districtCode,
    },
  });
};

const findZipCodeByTambonCode = (tambonCode: any) => {
  return http.get(url + "/findZipCodeByTambonCode", {
    params: {
      tambonCode,
    },
  });
};

const findStationById = (id: any) => {
  return http.get(url + "/findStationById", {
    params: {
      id,
    },
  });
};

const findScopeHead = () => {
  return http.get(url + "/findScopeHead");
};

const findScopeDetail = (scopeId: any) => {
  return http.get(url + "/findScopeDetail", {
    params: {
      scopeId,
    },
  });
};

const findScopeById = (id: any) => {
  return http.get(url + "/findScopeById", {
    params: {
      id,
    },
  });
};

const findDriverAll = () => {
  return http.get(url + "/findDriverAll");
};

const findBookDistribute = (data: any) => {
  return http.get(url + "/findBookDistribute", {
    params: data,
  });
};

const findBookingDistribute = (data: any) => {
  return http.get(url + "/findBookingDistribute", {
    params: data,
  });
};

const findViewDataLineHaulById = (id: any) => {
  return http.get(url + "/findViewDataLineHaulById", {
    params: { id },
  });
};

const findViewDataDistributeById = (id: any) => {
  return http.get(url + "/findViewDataDistributeById", {
    params: { id },
  });
};

const findScopeDetailById = (id: any) => {
  return http.get(url + "/findScopeDetailById", {
    params: { id },
  });
};

const findStationLastCode = (value: any) => {
  return http.get(url + "/findStationLastCode", {
    params: { value },
  });
};

const findPreviousTambon = (scopeId: any) => {
  return http.get(url + "/findPreviousTambon", {
    params: { scopeId },
  });
};

const findStationReferCustId = (id: any) => {
  return http.get(url + "/findStationReferCustId", {
    params: { id },
  });
};

const findDataStaff = (id: any) => {
  return http.get(url + "/findDataStaff", {
    params: { id },
  });
};

const findDataStationReferStaff = (userUId: any) => {
  return http.get(url + "/findDataStationReferStaff", {
    params: { userUId },
  });
};

const findStationByCustId = (id: any) => {
  return http.get(url + "/findStationByCustId", {
    params: { id },
  });
};

const findRouteHeadByCustUId = (uId: any) => {
  return http.get(url + "/findRouteHeadByCustUId", {
    params: { uId },
  });
};

const findAllTruckType = () => {
  return http.get(url + "/findAllTruckType");
};

const findAllPayment = () => {
  return http.get(url + "/findAllPayment");
};

const findLocationByProvinceName = (name: any) => {
  return http.get(url + "/findLocationByProvinceName", {
    params: { name },
  });
};

const loadScopeData = (id: any) => {
  return http.get(url + "/loadScopeData", {
    params: {
      id,
    },
  });
};

const loginBB = (username: any, password: any, custStationId: any) => {
  return http.get(url + "/loginBB", {
    params: { username, password, custStationId },
  });
};

const loadCustStation = (customerId: any) => {
  return http.get(url + "/loadCustStation", {
    params: { customerId },
  });
};

const loadPrename = () => {
  return http.get(url + "/loadPrename");
};

const loadStaffInStation = (custUId: any, stationId: any) => {
  return http.get(url + "/loadStaffInStation", {
    params: { custUId, stationId },
  });
};

const saveUserRole = (data: any) => {
  return http.post(url + "/saveUserRole", data);
};

const sendEmailWithLink = (data: any) => {
  return http.post(url + "/sendEmailWithLink", data);
};

const saveDailyRouteLineHaul = (data: any) => {
  return http.post(url + "/saveDailyRouteLineHaul", data);
};

const saveStation = (data: any) => {
  return http.post(url + "/saveStation", data);
};

const saveScope = (data: any) => {
  return http.post(url + "/saveScope", data);
};

const saveScopeDetail = (data: any) => {
  return http.post(url + "/saveScopeDetail", data);
};

const saveNewStaff = (data: any) => {
  return http.post(url + "/saveNewStaff", data);
};

const saveStationReferStaff = (data: any) => {
  return http.post(url + "/saveStationReferStaff", data);
};

const updateStation = (data: any) => {
  return http.put(url + "/updateStation", data);
};

const updateScopeDetail = (data: any) => {
  return http.put(url + "/updateScopeDetail", data);
};

const updateStaff = (data: any) => {
  return http.put(url + "/updateStaff", data);
};

const cancelBooking = (data: any) => {
  return http.put(url + "/cancelBooking", data);
};

const deleteRouteHeadById = (id: any) => {
  return http.delete(url + "/deleteRouteHeadById/" + id);
};

const deleteScopeDetaiById = (id: any) => {
  return http.delete(url + "/deleteScopeDetaiById/" + id);
};

const deleteStationReferStaff = (id: any) => {
  return http.delete(url + "/deleteStationReferStaff/" + id);
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  bookBB,
  copyLineHaul,
  copyDistribute,
  confirmBookingDistributeById,
  cancelBooking,
  bookingStatus,
  editScopeDetail,
  deleteRouteHeadById,
  deleteScopeDetaiById,
  deleteStationReferStaff,
  findRouteHead,
  findRouteHeadById,
  findTruckAll,
  findBookByBooked,
  findBookByConfirmed,
  findBookingLineHaul,
  findPartnerAll,
  findTruckRegistrationAll,
  findStationAll,
  findProvinceAll,
  findDistrict,
  findSubDistrict,
  findZipCodeByTambonCode,
  findStationById,
  findScopeById,
  findScopeHead,
  findScopeDetail,
  findDriverAll,
  findBookDistribute,
  findBookingDistribute,
  findViewDataLineHaulById,
  findViewDataDistributeById,
  findScopeDetailById,
  findStationLastCode,
  findPreviousTambon,
  findStationReferCustId,
  findDataStaff,
  findDataStationReferStaff,
  findStationByCustId,
  findRouteHeadByCustUId,
  findAllTruckType,
  findAllPayment,
  findLocationByProvinceName,
  loadScopeData,
  loginBB,
  loadCustStation,
  loadPrename,
  loadStaffInStation,
  saveUserRole,
  sendEmailWithLink,
  saveDailyRouteLineHaul,
  saveStation,
  saveScope,
  saveScopeDetail,
  saveNewStaff,
  saveStationReferStaff,
  updateStation,
  updateScopeDetail,
  updateStaff,
};
