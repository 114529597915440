import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { useHistory } from "react-router";

interface IProps {
  status: any;
  title: string;
  subTitle: string;
  extra: any;
}

const Result: React.FC<IProps> = (props: any) => {
  const { status, title, subTitle } = props;
  const [loaded, setLoaded] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);
      init();
    }
  }, []);

  const init = () => {
    setLoading(true);
    console.log("Result init...");
    setLoading(false);
  };

  const history = useHistory();

  return (
    <div>
      <Result
        status={status}
        title={title}
        subTitle={subTitle}
        extra={[
          <Button type="primary" key="console" onClick={() => history.goBack()}>
            กลับ
          </Button>,
          //   <Button key="buy">Buy Again</Button>,
        ]}
      />
    </div>
  );
};

export default Result;
