import { message } from "antd";

export function showMessage(content: string) {
  message.error({
    content: content,
    duration: 1.8,
    style: {
      maxWidth: "450px",
      marginLeft: "auto",
      marginRight: "auto",
    },
  });
}

export const round2DecimalPoint = (value: number) =>
  Math.round((value + Number.EPSILON) * 100) / 100;

export const generateOrderNumber = () =>
  Math.floor(100000 + Math.random() * 900000).toString();

export const findSymbolInString = (str: any, symbol: any) => {
  if (str.length === 0) {
    return false;
  }
  for (let i = 0; i < str.length; i++) {
    if (str.charAt(i) === symbol) {
      return true;
    }
  }
  return false;
};

export const GetFileExtention = (filename: any) => {
  return filename.split(".").pop();
};

export const GetCurrentDate = () => {
  let today: any = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  let yyyy = today.getFullYear();
  return `${yyyy}${mm}${dd}`;
};

export const GetCurrentDateTH = () => {
  let today: any = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  let yyyy: number = today.getFullYear() + 543;
  return `${dd}${mm}${yyyy}`;
};

export const GetCurrentTime = () => {
  let today: any = new Date();
  let hh = today.getHours() < 10 ? "0" + today.getHours() : today.getHours();
  let mm =
    today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes();
  let ss =
    today.getSeconds() < 10 ? "0" + today.getSeconds() : today.getSeconds();
  return `${hh}${mm}${ss}`;
};

// getYear(years: พ.ศ. || ค.ศ. , fullyear: true||false, front: true||false --> true เอาสองตัวหน้า)
export const getYearsTH = (years: any, fullYear: boolean, front: boolean) => {
  if (years.trim().length < 4) {
    return "ปีไม่ถูกต้อง ปีต้องมี 4 หลัก";
  }
  // let today: any = new Date();
  let yyyy: number = Number(years) + 543;
  if (Number(years) === yyyy) {
    if (fullYear) {
      return yyyy;
    }
    if (front) {
      // เอาสองตัวหน้า
      return yyyy.toString().substring(0, 2);
    } else {
      // เอาสองตัวหลัง
      return yyyy.toString().substring(2);
    }
  } else {
    if (fullYear) {
      return yyyy;
    }
    if (front) {
      // เอาสองตัวหน้า
      return yyyy.toString().substring(0, 2);
    } else {
      // เอาสองตัวหลัง
      return yyyy.toString().substring(2);
    }
  }
};

export const GetCurrentMonth = (currMonth: any) => {
  return thmonth[Number(currMonth)];
};

export const GetCurrentMonthSorth = (currMonth: any) => {
  return thmonthShort[Number(currMonth)];
};

export const generateUUID = (digits: number) => {
  let str = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVXZ";
  let uuid = [];
  for (let i = 0; i < digits; i++) {
    uuid.push(str[Math.floor(Math.random() * str.length)]);
  }
  return uuid.join("");
};

export const minuteToHours = (m: number) => {
  let num = m;
  let hours = num / 60;
  let rhours = Math.floor(hours);
  let minutes = (hours - rhours) * 60;
  let rminutes = Math.round(minutes);
  let fullTime = {
    h: rhours,
    m: rminutes,
  };
  // return num + " minutes = " + rhours + " hour(s) and " + rminutes + " minute(s).";
  return fullTime;
};

export const numberWithCommas = (num: string) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

// PASSWORD //
/*
How does is work!
Math.random()                        // Generate random number, eg: 0.123456
             .toString(36)           // Convert  to base-36 : "0.4fzyo82mvyr"
                          .slice(-8);// Cut off last 8 characters : "yo82mvyr"
*/
export const RandomPassword = (len: number) => {
  const randomstring = Math.random().toString().slice(-len);
  return randomstring;
};
// PASSWORD //

export const ReplaceCharAt = (
  str: string,
  startIndex: number,
  endIndex: number,
  symbol: any
) => {
  let convert: any = [];
  let rs = "";

  if (str === null || str === "" || str === undefined) {
    return "ไม่พบข้อมูล";
  }

  for (let i = 0; i < str.length; i++) {
    if (i < startIndex) {
      convert.push(str[i]);
    } else if (i > endIndex) {
      convert.push(str[i]);
    } else {
      convert.push(symbol);
    }
  }
  for (let j = 0; j < convert.length; j++) {
    rs += convert[j];
  }
  return rs;
};

export const StringDate = (strDate: string, type: string, symbol: any) => {
  // symbo : -, /
  let result = "";
  let yyyy: any = strDate.substring(0, 4);
  let mm: any = strDate.substring(4, 6);
  let dd: any = strDate.substring(6);
  if (type === "TH") {
    yyyy = Number(yyyy) + 543;
    result = `${dd}${symbol}${mm}${symbol}${yyyy}`;
  }
  if (type === "EN") {
    result = `${yyyy}${symbol}${mm}${symbol}${dd}`;
  }
  return result;
};

export const StringTime = (strTime: string) => {
  let result = "";
  let hh: any = strTime.substring(0, 2);
  let mm: any = strTime.substring(2, 4);
  let ss: any = strTime.substring(4);
  return (result = `${hh}:${mm}:${ss}`);
};

export const StringDateCutSlash = (data: any) => {
  let arr = data.split("/");
  return `${arr[2]}${arr[1]}${arr[0]}`;
};

export const StringTimeCutColon = (data: any) => {
  let arr = data.split(":");
  return `${arr[0]}${arr[1]}${arr[2]}`;
};

export const thday = new Array(
  "อาทิตย์",
  "จันทร์",
  "อังคาร",
  "พุธ",
  "พฤหัสบดี",
  "ศุกร์",
  "เสาร์"
);

export const thdayShort = new Array("อา.", "จ.", "อ.", "พ.", "พฤ.", "ศ.", "ส.");

export const thmonth = new Array(
  "มกราคม",
  "กุมภาพันธ์",
  "มีนาคม",
  "เมษายน",
  "พฤษภาคม",
  "มิถุนายน",
  "กรกฎาคม",
  "สิงหาคม",
  "กันยายน",
  "ตุลาคม",
  "พฤศจิกายน",
  "ธันวาคม"
);

export const thmonthShort = new Array(
  "ม.ค.",
  "ก.พ.",
  "มี.ค.",
  "เม.ย.",
  "พ.ค.",
  "มิ.ย.",
  "ก.ค.",
  "ส.ค.",
  "ก.ย.",
  "ต.ค.",
  "พ.ย.",
  "ธ.ค."
);

export const ValidateEmail = (email: any) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};
