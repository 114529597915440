import React, { useState, useEffect } from "react";
import Truck1 from "../../assets/images/truck1.png";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Image,
  Row,
  Space,
  Typography,
  TimePicker,
  Modal,
  Select,
  Checkbox,
  message,
  Switch,
  Input,
  Popconfirm,
} from "antd";
import moment from "moment";
import {
  IoWallet,
  IoFilter,
  IoCloseCircle,
  IoCheckmarkCircle,
  IoChevronForward,
} from "react-icons/io5";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng,
} from "react-google-places-autocomplete";
import { withScriptjs } from "react-google-maps";
import { getUserSession } from "src/store/authen/action";
import { paymentDummy, serviceDummy } from "src/constant";
import {
  generateOrderNumber,
  GetCurrentDate,
  GetCurrentTime,
  StringDate,
  StringTime,
} from "src/utils";
import { useHistory } from "react-router";
import { useForm } from "antd/lib/form/Form";
import RDrawer from "src/components/RDrawer";
import bookingService from "src/services/bookingService";
import RMap from "src/components/RMap";
import RAutocomplete from "src/components/RAutoComplete";
import Item from "antd/lib/list/Item";

let ID: any = "";
let FROM: any = "";
let CANCEL_ID = "";
let BOOK_NO = "";

const ListItems: React.FC<{
  action: any;
  data: any;
  handleDelete: (data: any) => void;
}> = (props: any) => {
  const { action, data, handleDelete } = props;
  return (
    <div style={{ marginTop: 10, marginBottom: 10 }}>
      {data.map((item: any, index: number) => (
        <div
          key={item.id}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: 10,
            fontWeight: "bold",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div style={{ marginRight: 10, width: 40 }}>{`จุดที่ ${
              index + 1
            }`}</div>
            <div>{item.locationName}</div>
          </div>
          {action === 0 ? null : (
            <IoCloseCircle
              color="#ff0000"
              onClick={() => handleDelete(item.id)}
              size={20}
              style={{ cursor: "pointer" }}
            />
          )}
        </div>
      ))}
    </div>
  );
};

const Wrapper: any = React.memo(() => {
  const [action, setAction] = useState<any>(0);
  const [autoValue, setAutoValue] = useState<any>(null);
  const [bookHead, setBookHead] = useState<any>({});
  const [bookRoutes, setBookRoutes] = useState<any>([]);
  const [centerCoordinate, setCenterCoordinate] = useState<any>({
    lat: Number(18.80753508304137),
    lng: Number(99.01845056588674),
  });

  const [directions, setDirections] = useState<any>();
  const [distance, setDistance] = useState<any>("0 กิโลเมตร");
  const [duration, setDuration] = useState<any>("0 ชั่วโมง");
  const [durationSec, setDurationSec] = useState<any>(0); // Unit is second
  const [distAndDura, setDistAndDura] = useState<any>({});
  const [drawerCancelVisible, setDrawerCancelVisible] =
    useState<boolean>(false);
  const [drawerTruckVisible, setDrawerTruckVisible] = useState<boolean>(false);
  const [drawerServiceAndPaymentVisible, setDrawerServiceAndPaymentVisible] =
    useState<boolean>(false);
  const [drawerResultVisible, setDrawerResultVisible] =
    useState<boolean>(false);
  const [haveRoute, setHaveRoute] = useState<any>(true);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isAccept, setIsAccept] = useState<boolean>(true);
  const [isInside, setIsInside] = useState<boolean>(true);
  const [mapData, setMapData] = useState<any>([]);
  const [modalAgreement, setModalAgreenment] = useState<boolean>(false);
  const [pointer, setPointer] = useState<any>("");
  const [payment, setPayment] = useState<any>({});
  const [paymentList, setPaymentList] = useState<any>([]);
  const [routeHead, setRouteHead] = useState<any>([]);
  const [stationList, setStationList] = useState<any>([]);
  const [serviceList, setServiceList] = useState<any>(serviceDummy);
  const [services, setServices] = useState<any>([]);
  const [trigger, setTrigger] = useState<number>(0);
  const [truckList, setTruckList] = useState<any>([]);
  const [truck, setTruck] = useState<any>([]);
  const [userSession, setUserSession] = useState<any>(getUserSession());

  const [CancelForm] = useForm();
  const history = useHistory();

  const { Title } = Typography;
  const { Option } = Select;

  let SUM_DISTANCE = 0;
  let SUM_DURATION = 0;

  useEffect(() => {
    if (!loaded) {
      init();
      setLoaded(true);
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (autoValue) {
        setAction(1);
        const { value } = autoValue;
        const {
          description,
          place_id,
          reference,
          structured_formatting,
          terms,
        } = value;
        const { main_text, secondary_text } = structured_formatting;
        const placeId = value.place_id;
        const res = await geocodeByPlaceId(placeId);
        const lat_lnt = await getLatLng(res[0]);
        const { lat, lng } = lat_lnt;
        const provinceName = terms[4].value;
        let provinceCode = "";
        const response = await bookingService.findLocationByProvinceName(
          provinceName
        );
        if (response.data) {
          provinceCode = response.data.provinceCode;
        }
        const data = {
          id: generateOrderNumber(),
          custStationId: provinceCode,
          description,
          lat,
          lng,
          locationName: main_text,
          // place_id,
          // secondary_text,
          // reference,
          // terms,
        };
        console.log(data);
        setMapData((prev: any) => [...prev, data]);
      }
    })();
  }, [autoValue]);

  useEffect(() => {
    if (mapData.length > 1) {
      calculateDistanceAndDuration();
    } else {
      setDirections(null);
      setDistance("0 กิโลเมตร");
      setDuration("0 ชั่วโมง");
    }
  }, [mapData]);

  useEffect(() => {
    if (pointer === "truck") {
      setTruckList(truckList);
    } else if (pointer === "service") {
      let arrService: any = [];
      setServiceList(serviceList);
      serviceList.map((item: any) => {
        if (item.isSelect) {
          const data = {
            id: item.id,
            value: item.value,
            cost: item.cost,
          };
          arrService.push(data);
        }
      });
      setServices((prev: any) => [...arrService]);
    } else if (pointer === "payment") {
      setPaymentList(paymentList);
    }
  }, [trigger]);

  const init = () => {
    setLoading(true);
    if (getUserSession() === null) {
      history.replace("/login");
      return;
    }
    // setUserSession(getUserSession());
    // getCurrPosition();
    if (ID === "0") {
      findRouteHeadByCustUId();
      findStationReferCustId();
      findAllTruckType();
      findAllPayment();
      defaultDate();
    } else {
      findViewDataLineHaulById();
      findAllPayment();
    }
    setLoading(false);
  };

  const getCurrPosition = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        // console.log(position.coords.latitude);
        // console.log(position.coords.longitude)
        const { latitude: lat, longitude: lng } = position.coords;
        let data = {
          lat: Number(lat),
          lng: Number(lng),
        };
        setCenterCoordinate(data);
      },
      (error) => {
        // console.error("Error Code = " + error.code + " - " + error.message);
        message.error(`${error.code} ${error.message}`);
      }
    );
  };

  const findAllPayment = async () => {
    const response = await bookingService.findAllPayment();
    if (response.data) {
      let data = {
        id: response.data[1].id,
        value: response.data[1].paymentName,
      };
      setPayment(data);

      let arr: any = [];
      response.data.map((item: any) => {
        let dataPayment = {
          id: item.id,
          paymentName: item.paymentName,
          isSelect: item.id === 2 ? true : false,
        };
        arr.push(dataPayment);
      });
      setPaymentList(arr);
    }
  };

  const findAllTruckType = async () => {
    const response = await bookingService.findAllTruckType();
    if (response.data) {
      // console.log(response.data);
      let arr: any = [];
      response.data.map((item: any, index: number) => {
        let data: any = {
          id: item.id,
          img: Truck1,
          typeName: item.typeName,
          remark: item.remark,
          isSelect: index === 0 ? true : false,
        };
        arr.push(data);
      });
      setTruck(arr[0]);
      setTruckList(arr);
    }
  };

  const defaultDate = () => {
    let today = new Date();
    let date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    let time = 8 + ":" + 0 + ":" + 0;
    formDateTime.setFieldsValue({
      stDate: moment(new Date(), "YYYYMMDD"),
      stTime: moment(new Date(`${date} ${time}`)),
      rlDate: moment(new Date(), "YYYYMMDD"),
      // rlTime: moment(new Date(), "HHmmss"),
    });
  };

  const findViewDataLineHaulById = async () => {
    const response = await bookingService.findViewDataLineHaulById(ID);
    console.log(response.data);
    if (response.data) {
      const {
        id,
        startDate,
        startTime,
        releaseDate,
        releaseTime,
        endDate,
        endTime,
        paymentName,
        truckTypeId,
        truckTypeName,
        truckRemark,
      } = response.data[0];
      CANCEL_ID = id;

      formDateTime.setFieldsValue({
        edDate: moment(endDate, "YYYYMMDD"),
        edTime: moment(endTime, "HHmmss"),
        rlDate: moment(releaseDate, "YYYYMMDD"),
        rlTime: moment(releaseTime, "HHmmss"),
        stDate: moment(startDate, "YYYYMMDD"),
        stTime: moment(startTime, "HHmmss"),
      });

      let truckDesc = {
        id: null,
        img: Truck1,
        typeName: truckTypeName,
        remark: truckRemark,
      };
      setTruck(truckDesc);

      let paymentDesc = {
        id: null,
        value: paymentName,
      };
      setPayment(paymentDesc);

      let arr: any = [];
      response.data.map((item: any, i: any) => {
        const data = {
          id: i,
          lat: item.lat,
          lng: item.lng,
          seq: item.seq,
          locationName: item.locationName,
        };
        arr.push(data);
      });
      setMapData(arr);
    }
  };

  const findRouteHeadByCustUId = async () => {
    const response = await bookingService.findRouteHeadByCustUId(
      userSession.customerUId
    );
    if (response.data) {
      setRouteHead(response.data);
    }
  };

  const findStationReferCustId = async () => {
    const response = await bookingService.findStationReferCustId(
      userSession.customerId
    );
    // console.log(response.data);
    if (response.data) {
      setStationList(response.data);
    }
  };

  const calculateDistanceAndDuration = async () => {
    if (mapData.length <= 1) {
      setDistance("0 กิโลเมตร");
      setDuration("0 ชั่วโมง");
    } else if (mapData.length > 1) {
      let last = mapData[mapData.length - 1];
      for (let i = 0; i < mapData.length; i++) {
        if (mapData[i].id !== last.id) {
          let origin = new google.maps.LatLng(mapData[i].lat, mapData[i].lng);
          let destination = new google.maps.LatLng(
            mapData[i + 1].lat,
            mapData[i + 1].lng
          );
          let service = new google.maps.DistanceMatrixService();
          let response = await service.getDistanceMatrix({
            origins: [origin],
            destinations: [destination],
            travelMode: google.maps.TravelMode.DRIVING,
            unitSystem: google.maps.UnitSystem.METRIC,
            avoidHighways: false,
            avoidTolls: false,
          });
          // console.log(response);
          let { rows } = response;
          for (let i = 0; i < rows.length; i++) {
            for (let j = 0; j < rows[i].elements.length; j++) {
              // console.log(rows[i].elements[j]);
              SUM_DISTANCE += rows[i].elements[j].distance.value;
              SUM_DURATION += rows[i].elements[j].duration.value;
            }
          }

          let kilometer = Math.round(SUM_DISTANCE / 1000);
          let hour = Math.floor(SUM_DURATION / 3600);
          let minute = Math.round((SUM_DURATION % 3600) / 60);
          const data = {
            d: kilometer,
            h: hour,
            m: minute,
          };
          setDistAndDura(data);
          setDistance(`${kilometer} กิโลเมตร`);
          setDuration(`${hour} ชั่วโมง ${minute} นาที`);
          setHaveRoute(false);
        }
      }
      DrawingDirection();
    }
  };

  const DrawingDirection = () => {
    const origin = mapData[0];
    const dest = mapData[mapData.length - 1];

    const DirectionsService = new google.maps.DirectionsService();
    const waypts: google.maps.DirectionsWaypoint[] = [];
    mapData.map((item: any) => {
      if (item.id !== origin.id && item.id !== dest.id) {
        const lat: any = item.lat;
        const lng: any = item.lng;
        const data = {
          location: new google.maps.LatLng(Number(lat), Number(lng)),
          stopover: true,
        };
        waypts.push(data);
      }
    });

    DirectionsService.route(
      {
        origin: new google.maps.LatLng(
          Number(origin ? origin.lat : null),
          Number(origin ? origin.lng : null)
        ),
        destination: new google.maps.LatLng(
          Number(dest ? dest.lat : null),
          Number(dest ? dest.lng : null)
        ),
        waypoints: waypts,
        travelMode: google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        // console.log(result);
        if (status === google.maps.DirectionsStatus.OK) {
          setDirections(result);
        } else {
          console.error(`Map direction error ${result}`);
        }
      }
    );
  };

  const MyRouteName = () => {
    return (
      <div style={{ marginBottom: 10 }}>
        {isInside ? (
          <Select
            placeholder="เส้นทางรถวิ่งประจำ"
            // defaultValue="routeName"
            style={{ width: "100%" }}
            onChange={(val: any) => handleChangeMyRouteName(val)}
          >
            {routeHead.map((item: any) => {
              return (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              );
            })}
          </Select>
        ) : (
          <RAutocomplete value={autoValue} setValue={setAutoValue} />
        )}
      </div>
    );
  };

  const handleChangeMyRouteName = async (val: any) => {
    setAction(0);
    const response = await bookingService.findRouteHeadById(val);
    // console.log(response.data);
    if (response.data) {
      let arr: any = [];
      response.data.map((item: any) => {
        const data = {
          id: item.id,
          lat: item.lat,
          lng: item.lng,
          routeHeadId: item.routeHeadId,
          seq: item.seq,
          stationId: item.stationId,
          locationName: item.stationName,
        };
        arr.push(data);
      });
      setMapData(arr);
    }
  };

  const RouteName = () => {
    return (
      <div style={{ marginBottom: 10 }}>
        <Select
          placeholder="เลือกเส้นทาง"
          style={{ width: "100%" }}
          onChange={(val: any) => handleChangeRouteName(val)}
        >
          {stationList.map((item: any) => {
            return (
              <Option key={item.id} value={item.id}>
                {item.name}
              </Option>
            );
          })}
        </Select>
      </div>
    );
  };

  const handleChangeRouteName = async (val: any) => {
    setAction(1);
    const result = stationList.filter((item: any) => item.id === val);
    const { id, lat, lng, name } = result[0];
    const data = {
      id,
      custStationId: id,
      lat,
      lng,
      locationName: name,
    };
    setMapData((prev: any) => [...prev, data]);
  };

  /* 
  
  COMPONENTS 
  
  */
  const DistanceAndDuration = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-evenly",
          marginTop: 30,
          marginBottom: 20,
        }}
      >
        <div style={{ textAlign: "center" }}>
          <Title level={4}>ระยะทาง</Title>
          <div>{distance}</div>
        </div>
        <div style={{ textAlign: "center" }}>
          <Title level={4}>เวลา</Title>
          <div>{duration}</div>
        </div>
      </div>
    );
  };

  const [formDateTime] = useForm();
  const onFinishDateTime = async (val: any) => {
    let checkBeforeSave = checkBeforeSave_();
    if (checkBeforeSave) {
      let value = formDateTime.getFieldsValue();
      const values = {
        ...formDateTime.getFieldsValue(),
        stDate: value["stDate"].format("YYYYMMDD"),
        stTime: value["stTime"].format("HHmmss"),
        rlDate: value["rlDate"].format("YYYYMMDD"),
        rlTime: value["rlTime"].format("HHmmss"),
        edDate: value["edDate"].format("YYYYMMDD"),
        edTime: value["edTime"].format("HHmmss"),
      };
      const { stDate, stTime, rlDate, rlTime, edDate, edTime } = values;
      let sumService = services.reduce((prev: any, curr: any) => {
        return prev + curr.cost;
      }, 0);
      let isIn = isInside ? "IN" : "OUT";
      const data = {
        agreementId: "0",
        bookType: "LINE-HAUL",
        custType: "BB",
        createUserid: userSession.secUserId,
        createUsername: userSession.fullname,
        custUId: userSession.customerUId,
        custName: userSession.fullname,
        distance: distAndDura.d,
        duration: durationSec,
        driverUId: "",
        driverName: "",
        discountAmt: 0.0,
        endDate: edDate,
        endTime: edTime,
        inoutStation: isIn,
        serviceAmt: sumService,
        startDate: stDate,
        startTime: stTime,
        totalAmt: Number(distAndDura.d * 10),
        truckTypeId: truck.id,
        truckUId: "",
        registId: "",
        releaseDate: rlDate,
        releaseTime: rlTime,
        paymentTypeId: payment.id,
        savStation: userSession.stationCode,
        status: "00",
        routes: mapData,
      };
      // console.log("Booking ", data);
      let rspayment = paymentList.filter((item: any) => item.id === payment.id);
      let rstruck = truckList.filter((item: any) => item.id === truck.id);
      let copyData: any = { ...data };
      copyData.bookNo = "";
      copyData.truck = rstruck[0];
      copyData.payment = rspayment[0];
      delete copyData.routes;
      // setBookHead(copyData);
      // setBookRoutes(mapData);
      // console.log(copyData);
      // console.log(data);
      const response = await bookingService.bookBB(data);
      if (response.data.bookNo) {
        copyData.bookNo = response.data.bookNo;
        setBookHead(copyData);
        setBookRoutes(mapData);
        setDrawerResultVisible(true);
        // message.success(
        //   `จองรถสำเร็จ เลขที่การจองรถของคุณ ${response.data.bookNo}`,
        //   8
        // );
        // setTimeout(() => {
        //   refreshPage();
        //   init();
        // }, 4000);
      } else {
        console.log(response.data.bookNo);
      }
    }
  };

  const onFinishFailedDateTime = (error: any) => {
    console.log(error);
  };

  const checkBeforeSave_ = () => {
    if (mapData.length <= 0) {
      message.warning("กรุณาระบุ จุดรับ-จุดส่ง");
      return false;
    }

    let value = formDateTime.getFieldsValue();
    const values = {
      ...formDateTime.getFieldsValue(),
      stDate: value["stDate"].format("YYYY/MM/DD"),
      rlDate: value["rlDate"].format("YYYY/MM/DD"),
    };
    const { stDate, rlDate } = values;

    let arrDate = stDate.split("/");
    const temp = arrDate[0];
    arrDate[0] = arrDate[2];
    arrDate[2] = temp;
    let startDate = arrDate.join("/");

    let now = moment(new Date()).format("YYYYMMDD");
    let date = moment(new Date(`${startDate}`)).format("YYYYMMDD");

    if (Number(date) < Number(now)) {
      message.warning(
        "วันใช้งานต้องเป็นวันปัจจุบัน หรือ วันที่มากกว่าวันปัจจุบันเท่านั้น!",
        6
      );
      return false;
    }

    let arrDate_ = rlDate.split("/");
    const temp_ = arrDate[0];
    arrDate_[0] = arrDate[2];
    arrDate_[2] = temp_;
    let realeseDate_ = arrDate.join("/");

    let now_ = moment(new Date()).format("YYYYMMDD");
    let date_ = moment(new Date(`${realeseDate_}`)).format("YYYYMMDD");

    if (Number(date_) < Number(now_)) {
      message.warning(
        "วันที่ปล่อยรถต้องเป็นวันปัจจุบัน หรือ วันที่มากกว่าวันปัจจุบันเท่านั้น!",
        6
      );
      return false;
    }

    return true;
  };

  const DateAndTime = () => {
    return (
      <div style={{ marginTop: "1rem" }}>
        <Title level={4} style={{ fontWeight: "bold" }}>
          วันเวลาใช้งาน
        </Title>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: 20,
          }}
        >
          <Form.Item
            colon={false}
            label="วันที่ใช้งาน"
            name="stDate"
            rules={[{ required: true, message: "กรุณาเลือกวันที่!" }]}
            style={{ marginLeft: 9 }}
          >
            {ID === "0" ? (
              <DatePicker
                format="DD/MM/YYYY"
                disabled={haveRoute}
                onChange={() => {
                  let value = formDateTime.getFieldsValue();
                  formDateTime.setFieldsValue({
                    ...formDateTime.getFieldsValue(),
                    rlDate: value["stDate"],
                  });
                }}
              />
            ) : (
              <DatePicker format="DD/MM/YYYY" disabled />
            )}
          </Form.Item>
          <Form.Item
            colon={false}
            label="เวลาที่ใช้งาน"
            name="stTime"
            rules={[{ required: true, message: "กรุณาเลือกเวลา!" }]}
          >
            {ID === "0" ? (
              <TimePicker format="HH:mm:ss" disabled={haveRoute} />
            ) : (
              <TimePicker format="HH:mm:ss" disabled />
            )}
          </Form.Item>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: -5,
          }}
        >
          <Form.Item
            colon={false}
            label="วันที่ปล่อยรถ"
            name="rlDate"
            rules={[{ required: true, message: "กรุณาเลือกวันที่!" }]}
          >
            {ID === "0" ? (
              <DatePicker format="DD/MM/YYYY" disabled={haveRoute} />
            ) : (
              <DatePicker format="DD/MM/YYYY" disabled />
            )}
          </Form.Item>
          <Form.Item
            colon={false}
            label="เวลาที่ปล่อยรถ"
            name="rlTime"
            rules={[{ required: true, message: "กรุณาเลือกเวลา!" }]}
          >
            {ID === "0" ? (
              <TimePicker
                format="HH:mm:ss"
                disabled={haveRoute}
                placeholder="เลือกเวลา"
                onChange={(data, dataString) =>
                  handleChangeTime_(data, dataString, "rlTime")
                }
              />
            ) : (
              <TimePicker format="HH:mm:ss" disabled />
            )}
          </Form.Item>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: -5,
          }}
        >
          <Form.Item
            colon={false}
            label="วันที่จบงาน"
            name="edDate"
            style={{ marginLeft: 18 }}
          >
            <DatePicker format="DD/MM/YYYY" placeholder="-" disabled />
          </Form.Item>
          <Form.Item colon={false} label="เวลาที่จบงาน" name="edTime">
            <TimePicker placeholder="-" format="HH:mm:ss" disabled />
          </Form.Item>
        </div>
      </div>
    );
  };

  const handleChangeTime_ = (data: any, dataString: any, params: any) => {
    if (params === "rlTime") {
      let result = calculateEndDateTime();
      formDateTime.setFieldsValue({
        edDate: moment(`${result[0]}`, "YYYY/MM/DD"),
        edTime: moment(`${result[1]}`, "HH:mm:ss"),
      });
    }
  };

  const calculateEndDateTime = () => {
    let value = formDateTime.getFieldsValue();
    const values = {
      ...formDateTime.getFieldsValue(),
      stDate: value["stDate"].format("YYYY/MM/DD"),
      stTime: value["stTime"].format("HH:mm:ss"),
      rlDate: value["rlDate"].format("YYYY/MM/DD"),
      rlTime: value["rlTime"].format("HH:mm:ss"),
    };
    const { rlDate, rlTime } = values;

    let arrDura = duration.split(" ");
    let hSecond = Number(arrDura[0] * 60) * 60; // Convert hours to second
    let mSecond = Number(arrDura[2] * 60); // Convert minute to second
    let estimateEndDate: number = hSecond + mSecond;
    setDurationSec(hSecond + mSecond);

    let concat = `${rlDate} ${rlTime}`;
    let endDate = new Date(`${concat}`);
    endDate.setSeconds(estimateEndDate);
    let newDate = new Date(`${endDate}`);
    let endDate_ = moment(newDate).format("YYYYMMDD HHmmss");
    let arrEndDate = endDate_.split(" ");
    return arrEndDate;
  };

  const handleSelectTruck = (id: any) => {
    truckList.map((item: any) => {
      if (item.id === id) {
        setTruck(item);
        item.isSelect = true;
      } else {
        item.isSelect = false;
      }
    });
    setPointer("truck");
    setTrigger((prev: number) => prev + 1);
  };

  /**
   *
   * @returns
   *
   *
   */

  const TruckType = () => {
    return (
      <div style={{ marginTop: 20 }}>
        <Title level={4} style={{ fontWeight: "bold" }}>
          ประเภทรถบรรทุก
        </Title>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Image width={250} src={truck.img} alt={truck.typeName} />
            {ID === "0" ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: 15,
                  marginRight: 15,
                  cursor: "pointer",
                }}
                // onClick={() => toggleModalTruck()}
                onClick={() => setDrawerTruckVisible(true)}
              >
                <div
                  style={{ fontWeight: "bold", fontSize: 14, marginBottom: 5 }}
                >
                  {truck.typeName}
                </div>
                <div style={{ color: "gray", fontSize: 12 }}>
                  {truck.remark}
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: 15,
                  marginRight: 15,
                }}
                // onClick={() => toggleModalTruck()}
              >
                <div
                  style={{ fontWeight: "bold", fontSize: 16, marginBottom: 5 }}
                >
                  {truck.typeName}
                </div>
                <div style={{ color: "gray", fontSize: 12 }}>
                  {truck.remark}
                </div>
              </div>
            )}
          </div>
          <div>
            {ID === "0" ? (
              <IoChevronForward
                size={30}
                style={{ cursor: "pointer" }}
                // onClick={() => toggleModalTruck()}
                onClick={() => setDrawerTruckVisible(true)}
              />
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  // const handleSelectService = (id: any) => {
  //   serviceList.map((item: any) => {
  //     if (item.id === id) {
  //       item.isSelect = !item.isSelect;
  //     }
  //   });
  //   setPointer("service");
  //   setTrigger((prev: number) => prev + 1);
  // };

  // const handleSelectPayment = (id: any) => {
  //   paymentList.map((item: any) => {
  //     if (item.id === id) {
  //       setPayment(item);
  //       item.isSelect = true;
  //     } else {
  //       item.isSelect = false;
  //     }
  //   });
  //   setPointer("payment");
  //   setTrigger((prev: number) => prev + 1);
  // };

  const ServiceAndPayment = () => {
    return (
      <div style={{ marginTop: 20 }}>
        <Title level={4} style={{ fontWeight: "bold" }}>
          บริการเสริมและการชำระเงิน
        </Title>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: 10,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              cursor: "pointer",
            }}
            onClick={() => setDrawerServiceAndPaymentVisible(true)}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <IoFilter size={40} color="gray" style={{ marginRight: 10 }} />
              <div>
                <div style={{ fontWeight: "bold", marginBottom: 5 }}>
                  บริการเสริม
                </div>
                {services.length > 0 ? (
                  <div>
                    {services.map((item: any) => (
                      <div key={item.id}>{item.value}</div>
                    ))}
                  </div>
                ) : (
                  <div>ไม่ระบุ</div>
                )}
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              cursor: "pointer",
            }}
            onClick={() => setDrawerServiceAndPaymentVisible(true)}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <IoWallet size={40} color="gray" style={{ marginRight: 10 }} />
              <div>
                <div style={{ fontWeight: "bold", marginBottom: 5 }}>
                  การชำระเงิน
                </div>
                <div>{payment.value}</div>
              </div>
            </div>
            {ID === "0" ? (
              <IoChevronForward
                size={30}
                style={{ cursor: "pointer" }}
                onClick={() => setDrawerServiceAndPaymentVisible(true)}
              />
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  const agreementModal = () => {
    return (
      <Modal
        title="เงื่อนใขการใช้บริการ"
        visible={modalAgreement}
        onOk={toggleModalAgreenment}
        onCancel={toggleModalAgreenment}
        okText="ตกลง"
        cancelText="ยกเลิก"
      >
        <ul style={{ marginLeft: -20, fontSize: 14 }}>
          <li>
            เมื่อลูกค้าดำเนินการจองรถเสร็จสิ้นแล้ว
            ระบบจะจัดหาพนักงานขับรถในเครือข่ายที่เหมาะสมกับงานของลูกค้า
            และจะมีอีเมล์ยืนยันการจองเพื่อยืนยันว่าลูกค้าได้ทำการจอง
            เรียบร้อยแล้ว
          </li>
          <li>
            เจ้าหน้าที่บริการลูกค้า ทำหน้าที่ติดต่อ ประสานงาน รับจองรถจากลูกค้า
            เมื่อลูกค้าต้องการจะใช้รถจะต้องแจ้งล่วงหน้า 1 วันภายใน 12.00 น.
            กรณีที่ลูกค้าต้องการใช้รถในวันอาทิตย์ต้องจองรถภายในวันศุกร์ก่อน
            15.00 น.
          </li>
          <li>การแจ้งยกเลิกการใช้บริการรถบรรทุก</li>
          <ol style={{ marginLeft: -10 }}>
            <li>
              กรณีที่เป็นงานขึ้นค้างส่งเช้าจะต้องแจ้งยกเลิก ก่อนวันใช้รถล่างหน้า
              1 วันภายใน 13.00 น.
            </li>
            <li>
              กรณีที่เป็นงานขึ้นแล้วส่งสินค้าในวันต้องยกเลิกล่วงหน้าก่อนวันใช้รถภายในเวลา
              15.00 น.
            </li>
            <li>กรณีที่ยกเลิกในวันที่ใช้รถจะมีค่าใข้จ่าย 50% ของค่าขนส่ง</li>
          </ol>
        </ul>
        <div style={{ marginTop: 20 }}>
          <Checkbox
            // onChange={(e) => {
            //   setIsAccept(e.target.checked);
            // }}
            checked={isAccept}
          >
            <Title level={4}>ยอมรับข้อตกลง</Title>
          </Checkbox>
        </div>
      </Modal>
    );
  };

  const toggleModalAgreenment = () => {
    setModalAgreenment((prev: any) => !prev);
  };

  const Agreenment = () => {
    return (
      <div style={{ marginTop: 20 }}>
        <Title level={4} style={{ fontWeight: "bold" }}>
          ข้อตกลง
        </Title>
        <div style={{ marginTop: 15 }}>
          <Checkbox
            onChange={onChangeAgreenment}
            checked={ID === "0" ? isAccept : true}
          >
            ข้อตกลง
          </Checkbox>
          <span
            style={{
              color: "blue",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => toggleModalAgreenment()}
          >
            {ID === "0" ? <>อ่านข้อตกลง</> : null}
          </span>
        </div>
      </div>
    );
  };

  const onChangeAgreenment = (e: any) => {
    // console.log(e.target.checked);
  };

  const handleDelete = (id: number) => {
    const newItem = mapData.filter((item: any) => item.id !== id);
    setMapData(newItem);
  };

  const refreshPage = () => {
    window.location.reload();
  };

  const hr = () => {
    return (
      <div
        style={{
          marginTop: "3rem",
          marginBottom: 10,
          height: 1,
          backgroundColor: "lightgray",
        }}
      />
    );
  };

  const handleCancelBooking = async () => {
    const response = await bookingService.bookingStatus(CANCEL_ID, "lineHaul");
    if (response.data) {
      const { status } = response.data;
      if (status === "00" || status === "01") {
        setDrawerCancelVisible(true);
      }
      // else if(status === "CC"){
      //   message.warning(`การจองนี้ถูกยกเลิกอยู่แล้ว!`, 4);
      // }
      else {
        message.warning(`การจองนี้ไม่สามารถยกเลิกได้!`, 4);
      }
    }
  };

  const onFinish = async (value: any) => {
    let data = {
      bookNo: BOOK_NO,
      cancelRemark: value.text,
      cancelDate: GetCurrentDate(),
      cancelTime: GetCurrentTime(),
      cancelUserId: userSession.secUserId,
      cancelUserName: userSession.fullname,
      createUserid: userSession.secUserId,
      createUsername: userSession.fullname,
      cancelType: "lineHaul",
      id: CANCEL_ID,
      updateUserid: userSession.secUserId,
      updateUsername: userSession.fullname,
      savStation: userSession.stationCode,
    };
    // console.log(data);
    const response = await bookingService.cancelBooking(data);
    if (response.data) {
      message.success("ยกเลิกสำเร็จ");
      history.goBack();
    } else {
      console.error(response.data);
    }
  };

  const onFinishFailed = (error: any) => {
    console.log(error);
  };

  const RDrawerCancel_ = () => {
    return (
      <RDrawer
        placement="right"
        title="ยกเลิกการจอง"
        visible={drawerCancelVisible}
        setVisible={setDrawerCancelVisible}
        width={400}
      >
        <div>
          <Form
            name="CancelForm"
            form={CancelForm}
            // labelCol={{ span: 8 }}
            // wrapperCol={{ span: 16 }}
            // initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              name="text"
              rules={[{ required: true, message: "กรุณาใส่หมายเหตุ!" }]}
            >
              <Input.TextArea placeholder="หมายเหตุ" />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Space>
                <Button
                  type="default"
                  htmlType="button"
                  onClick={() => setDrawerCancelVisible(false)}
                >
                  ยกเลิก
                </Button>
                <Button type="primary" htmlType="submit">
                  ตกลง
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </div>
      </RDrawer>
    );
  };

  const RDrawerTruck = () => {
    return (
      <RDrawer
        placement="right"
        title="ประเภทรถบรรทุก"
        visible={drawerTruckVisible}
        setVisible={setDrawerTruckVisible}
        width={500}
      >
        {truckList.map((item: any, index: any) => (
          <div
            key={item.id}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: 15,
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src={item.img}
                alt={item.typeName}
                width={80}
                style={{ marginRight: 10 }}
              />
              <div>
                <div
                  style={{ fontWeight: "bold", fontSize: 14, marginBottom: 2 }}
                >
                  {item.typeName}
                </div>
                <div style={{ color: "grey", fontSize: 12 }}>{item.remark}</div>
              </div>
            </div>
            <div>
              <IoCheckmarkCircle
                size={20}
                style={{ cursor: "pointer" }}
                color={item.isSelect ? "green" : "#EBEDEF"}
                onClick={() => handleSelectTruck(item.id)}
              />
            </div>
          </div>
        ))}
      </RDrawer>
    );
  };

  const RDrawerService = () => {
    return (
      <RDrawer
        placement="right"
        title="บริการเสริมและการชำระเงิน"
        visible={drawerServiceAndPaymentVisible}
        setVisible={setDrawerServiceAndPaymentVisible}
        width={300}
      >
        {/* {serviceList.map((item: any) => (
          <div
            key={item.id}
            style={{
              marginBottom: 10,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div>
                <IoChevronForward size={20} color="gray" />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: 15,
                  marginRight: 10,
                }}
              >
                <div> {item.value}</div>
              </div>
            </div>
            <div>
              <IoCheckmarkCircle
                size={20}
                style={{ cursor: "pointer" }}
                color={item.isSelect ? "green" : "#EBEDEF"}
                onClick={() => handleSelectService(item.id)}
              />
            </div>
          </div>
        ))}
        {hr()} */}
        {paymentList.map((item: any) => (
          <div
            key={item.id}
            style={{
              marginBottom: 5,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {/* <div>
                <IoChevronForward size={20} color="gray" />
              </div> */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  // marginLeft: 15,
                  marginRight: 10,
                }}
              >
                <div> {item.paymentName}</div>
              </div>
            </div>
            <div>
              <IoCheckmarkCircle
                size={20}
                style={{ cursor: "pointer" }}
                color={item.isSelect ? "green" : "#EBEDEF"}
                // onClick={() => handleSelectPayment(item.id)}
              />
            </div>
          </div>
        ))}
      </RDrawer>
    );
  };

  const RDrawerResult = () => {
    return (
      <RDrawer
        placement="right"
        title="รายละเอียดการจอง"
        visible={drawerResultVisible}
        setVisible={setDrawerResultVisible}
        width={400}
      >
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: 20,
            }}
          >
            <div style={{ fontWeight: "bold", fontSize: 18 }}>เลขที่การจอง</div>
            <div style={{ fontWeight: "bold", fontSize: 18 }}>
              {bookHead.bookNo}
            </div>
          </div>
          <div style={{ marginBottom: 10 }}>
            <div style={{ fontWeight: "bold" }}>จุดรับ-ส่ง</div>
            {bookRoutes.map((item: any, idx: any) => {
              return (
                <div
                  key={idx}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div style={{ marginRight: 10 }}>จุดที่ {idx + 1}</div>
                  <div>{item.locationName}</div>
                </div>
              );
            })}
          </div>
          <div style={{ marginBottom: 10 }}>
            <div style={{ fontWeight: "bold" }}>ระยะทาง/เวลา</div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 5,
              }}
            >
              <div style={{ marginRight: 10 }}>ระยะทาง {distance}</div>
              <div>เวลา {duration}</div>
            </div>
          </div>
          <div style={{ marginBottom: 10 }}>
            <div style={{ fontWeight: "bold" }}>วันเวลา/ใช้งาน</div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 5,
              }}
            >
              <div style={{ marginRight: 10 }}>
                {`วันที่ใช้งาน ${
                  bookHead.startDate
                    ? moment(
                        new Date(StringDate(bookHead.startDate, "EN", "/"))
                      ).format("L")
                    : ""
                }`}
              </div>
              <div>{`เวลาที่ใช้งาน ${
                bookHead.startTime ? StringTime(bookHead.startTime) : ""
              }`}</div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 5,
              }}
            >
              <div style={{ marginRight: 10 }}>
                {`วันที่ปล่อยรถ  ${
                  bookHead.releaseDate
                    ? moment(
                        new Date(StringDate(bookHead.releaseDate, "EN", "/"))
                      ).format("L")
                    : ""
                }`}
              </div>
              <div>{`เวลาที่ปล่อยรถ ${
                bookHead.releaseTime ? StringTime(bookHead.releaseTime) : ""
              }`}</div>
            </div>
          </div>

          <div style={{ marginBottom: 10 }}>
            <div style={{ fontWeight: "bold" }}>ประเภทรถ</div>
            <div style={{ marginRight: 10 }}>
              {bookHead.truck ? bookHead.truck.typeName : null}
            </div>
            <div>{bookHead.truck ? bookHead.truck.remark : null}</div>
          </div>

          <div style={{ marginBottom: 10 }}>
            <div style={{ fontWeight: "bold" }}>การชำระเงิน</div>
            <div>{bookHead.payment ? bookHead.payment.paymentName : null}</div>
          </div>
        </div>
        <div style={{ marginTop: 30, textAlign: "center" }}>
          <Button
            type="primary"
            onClick={() => {
              refreshPage();
              init();
            }}
          >
            กลับ
          </Button>
        </div>
      </RDrawer>
    );
  };

  const confirm = () => {
    handleCancelBooking();
  };
  const cancel = () => {};

  const handleInOutSite = () => {
    setIsInside((prev: any) => (prev = !prev));
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      {agreementModal()}
      {RDrawerCancel_()}
      {RDrawerTruck()}
      {RDrawerService()}
      {RDrawerResult()}
      <Form
        name="formDateTime"
        form={formDateTime}
        // layout="horizontal"
        // {...formItemLayout}
        // labelCol={{ span: 8 }}
        // wrapperCol={{ span: 8 }}
        // initialValues={{ remember: true }}
        onFinish={onFinishDateTime}
        onFinishFailed={onFinishFailedDateTime}
        autoComplete="off"
      >
        <Row style={{ height: "100%" }}>
          <Col
            span={9}
            style={{ backgroundColor: "#fff", padding: 10, borderRadius: 5 }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Title level={4} style={{ fontWeight: "bold" }}>
                รถวิ่งระยะใกล
              </Title>
              <Space>
                <Button type="primary" onClick={() => history.goBack()}>
                  กลับ
                </Button>
                {ID !== "0" && (
                  <Popconfirm
                    title="คุณต้องการยกเลิกการจองนี้ใช่หรือไม่?"
                    onConfirm={confirm}
                    onCancel={cancel}
                    okText="ใช่"
                    cancelText="ไม่"
                  >
                    {FROM !== "bookingData" && (
                      <Button type="default">ยกเลิก</Button>
                    )}
                  </Popconfirm>
                )}
              </Space>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: 10,
              }}
            >
              <Title level={4} style={{ fontWeight: "bold", marginBottom: 10 }}>
                จุดรับ-ส่งสินค้า
              </Title>
              {ID === "0" ? (
                <Switch
                  checked={isInside}
                  checkedChildren="ภายในสาขา"
                  unCheckedChildren="ภายนอก"
                  onChange={() => handleInOutSite()}
                />
              ) : null}
            </div>

            {/* Select my route name */}
            {ID === "0" ? MyRouteName() : null}

            {/* Route name */}
            {ID === "0" ? RouteName() : null}

            {/* Liste item position */}
            <ListItems
              action={action}
              data={mapData}
              handleDelete={handleDelete}
            />

            {/* Distance and duration */}
            {DistanceAndDuration()}
            {hr()}

            {/* Date and time */}
            {DateAndTime()}
            {hr()}

            {/* Truck type */}
            {TruckType()}
            {hr()}

            {/* Service and payment */}
            {ServiceAndPayment()}

            {/* Agreement */}
            {Agreenment()}

            <div style={{ textAlign: "center", marginTop: 30 }}>
              {ID === "0" ? (
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    บันทึกการจอง
                  </Button>
                </Form.Item>
              ) : (
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="button"
                    onClick={() => {
                      if (FROM === "dataLineHaul") {
                        history.push({
                          pathname: "/app/booking/dataLineHaul",
                          state: {},
                        });
                      } else if (FROM === "bookingData") {
                        history.push({
                          pathname: "/app/booking/bookingData",
                          state: { id: "1" },
                        });
                      }
                    }}
                  >
                    กลับ
                  </Button>
                </Form.Item>
              )}
            </div>
          </Col>
          <Col span={15}>
            <RMap centerCoordinate={centerCoordinate} directions={directions} />
          </Col>
        </Row>
      </Form>
    </div>
  );
});

const BookingLineHaul: React.FC = (props: any) => {
  const { bookNo, from } = props.location.state;
  ID = props.match.params.id;
  FROM = from;
  BOOK_NO = bookNo;
  const MapLoader: any = withScriptjs(Wrapper);

  return (
    <div>
      <MapLoader
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCY8tssCzJWXdG7BnWMr5EmiljzHL4zp0o&v=3.exp&libraries=geometry,drawing,places"
        loadingElement={<div style={{ height: `100%` }} />}
      />
    </div>
  );
};

export default BookingLineHaul;
