import React from "react";
import classes from "./SideNavMenu.module.css";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import {
  AppstoreOutlined,
  SettingOutlined,
  DesktopOutlined,
} from "@ant-design/icons";

interface IProps {
  menuItems: { id: number; name: string; path: string }[];
}

const menus = (
  <Menu
    // onClick={this.handleClick}
    style={{ width: "225px" }}
    defaultSelectedKeys={["1"]}
    defaultOpenKeys={["sub1"]}
    mode="inline"
    className="sideNavMenuContainer"
  >
    <Menu.Item key="1">Option 1</Menu.Item>
    <Menu.Item key="2">Option 2</Menu.Item>
  </Menu>
);

const SideNavMenu: React.FC<IProps> = (props) => {
  const { SubMenu } = Menu;

  const menuList = () => {
    return (
      <Menu
        // onClick={this.handleClick}
        style={{ width: "225px", fontSize: 14 }}
        defaultSelectedKeys={["1"]}
        defaultOpenKeys={["sub1"]}
        mode="inline"
        className="sideNavMenuContainer"
      >
        {props.menuItems.map((menu) => (
          <Menu.Item key={menu.id}>
            <Link to={menu.path}>{menu.name}</Link>
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  return (
    <div className={classes["sideNavMenu"]}>
      <div className={classes.sideNavMenuBrandName}>FLEET BOOKING</div>
      {menuList()}
    </div>
  );
};

export default SideNavMenu;
