import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Popconfirm,
  Row,
  Select,
  Space,
  Typography,
  Table,
} from "antd";
import {
  DirectionsRenderer,
  GoogleMap,
  InfoWindow,
  Marker,
  withScriptjs,
  withGoogleMap,
} from "react-google-maps";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng,
} from "react-google-places-autocomplete";
import { getUserSession } from "src/store/authen/action";
import { IoCloseCircle } from "react-icons/io5";
import { RiMapPin2Fill } from "react-icons/ri";
import { generateOrderNumber } from "src/utils";
import bookingService from "src/services/bookingService";
import { useHistory } from "react-router";

let SCOPE_ID = "";
let SCOPE_NAME = "";

const TableScope: React.FC<{
  data: any;
  remove: any;
}> = (props: any) => {
  const { data, remove } = props;
  const columns = [
    {
      width: 50,
      title: "ลำดับ",
      dataIndex: "seq",
      key: "seq",
      // render: (text: any, record: any, i: any) => <div>{i + 1}</div>,
    },
    // {
    //   title: "ชื่อขอบเขต",
    //   dataIndex: "scopeName",
    //   key: "scopeName",
    // },
    {
      title: "ตำบล",
      dataIndex: "subDistrict",
      key: "subDistrict",
    },
    {
      width: 50,
      title: "",
      key: "action",
      render: (text: any, record: any, i: any) => {
        return (
          <>
            {text.previous === true ? (
              <RiMapPin2Fill size={20} color={text.color} />
            ) : (
              <Space size="middle">
                <Popconfirm
                  title={`คุณต้องการลบ ${text.subDistrict} นี้ใช่ หรือ ไม่`}
                  onConfirm={(e) => confirm(i)}
                  onCancel={(e) => cancel(e)}
                  okText="ใช่"
                  cancelText="ยกเลิก"
                >
                  <IoCloseCircle
                    size={20}
                    color="#ff0000"
                    style={{ cursor: "pointer" }}
                  />
                </Popconfirm>
              </Space>
            )}
          </>
        );
      },
    },
  ];

  const confirm = async (id: any) => {
    remove(id);
  };

  const cancel = (e: any) => {
    console.log("Cancel");
  };

  return (
    <>
      {data.length > 0 ? (
        <Table
          dataSource={data}
          columns={columns}
          size="small"
          rowKey={(obj: any) => obj.seq}
        />
      ) : (
        <Table
          // dataSource={null}
          columns={columns}
          size="small"
          // rowKey={(obj: any) => obj.id}
        />
      )}
    </>
  );
};

const RAutocomplete = (props: any) => {
  const { autoValue, setAutoValue } = props;
  return (
    <GooglePlacesAutocomplete
      // apiKey={MAP_API}
      apiOptions={{ language: "th", region: "th" }}
      debounce={300}
      onLoadFailed={(err) => message.warning(`${err}`, 10)}
      selectProps={{
        placeholder: "ค้นหาตำแหน่ง...",
        autoValue,
        onChange: setAutoValue,
      }}
    />
  );
};

const RMap: React.FC<{
  centerCoordinate: any;
  callBack?: any;
  data?: any;
  dragEnd?: any;
  directions?: any;
  setIsPin?: any;
  setData?: any;
  condition?: any;
}> = React.memo((props: any) => {
  // console.log(props);
  const {
    centerCoordinate,
    callBack,
    dragEnd,
    data,
    setIsPin,
    setData,
    condition,
  } = props;

  const mapRef = useRef(null);

  const handleOnClickMap = async (e: any) => {
    if (condition === undefined) {
      message.warning("กรุณาเลือกตำบลก่อน!");
      return;
    }

    setIsPin(true);
    let lat = e.latLng.lat();
    let lng = e.latLng.lng();
    const data = {
      id: generateOrderNumber(),
      description: null,
      lat,
      lng,
      locationName: null,
      place_id: null,
      secondary_text: null,
      reference: null,
      terms: [],
    };
    callBack(data);
  };
  const Map: any = withGoogleMap((props) => (
    <GoogleMap
      ref={(map) => {
        const bounds = new window.google.maps.LatLngBounds();
        data &&
          data.map((item: any) => {
            bounds.extend(new window.google.maps.LatLng(item.lat, item.lng));
          });
        if (data.length > 0) {
          map && map.fitBounds(bounds);
        }
        // props.onMapMounted(map);
      }}
      defaultCenter={centerCoordinate}
      defaultZoom={15}
      onClick={handleOnClickMap}
    >
      {data &&
        data.map((item: any, i: any) => {
          if (item.lat === null && item.lng === null) {
            return;
          }
          const svgMarker = {
            path: "M10.453 14.016l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM12 2.016q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
            fillColor: `${item.color}`,
            fillOpacity: 1,
            strokeWeight: 0,
            rotation: 0,
            scale: 1.8,
            anchor: new google.maps.Point(15, 30),
          };

          return (
            <Marker
              icon={svgMarker}
              key={i}
              position={{ lat: Number(item.lat), lng: Number(item.lng) }}
              draggable={item.previous === false ? true : false}
              onDragEnd={(e: any) => dragEnd(e, i)}
            >
              <InfoWindow>
                <div>{item.locationName}</div>
              </InfoWindow>
            </Marker>
          );
        })}

      {/* {directions && (
            <DirectionsRenderer
              directions={directions}
              options={{
                polylineOptions: {
                  geodesic: true,
                  strokeOpacity: 1,
                  strokeColor: "#0000ff",
                  strokeWeight: 3,
                },
              }}
            />
          )} */}
    </GoogleMap>
  ));
  return (
    <Map
      ref={mapRef}
      containerElement={<div style={{ height: "100%", width: "100%" }} />}
      mapElement={<div style={{ height: `100%` }} />}
    />
  );
});

const Wrapper: any = React.memo(() => {
  const [autoValue, setAutoValue] = useState<any>(null);
  const [color, setColor] = useState<any>("");
  const [condition, setCondition] = useState<any>();
  const [centerCoordinate, setCenterCoordinate] = useState<any>({
    lat: Number(18.80753508304137),
    lng: Number(99.01845056588674),
  });
  const [directions, setDirections] = useState<any>();
  const [holdData, setHoldData] = useState<any>([]);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [location, setLocation] = useState<any>({});
  const [isPin, setIsPin] = useState<boolean>(false);
  const [provinceList, setProvinceList] = useState<any>([]);
  const [districtList, setDistrictList] = useState<any>([]); // Amphoe
  const [mapData, setMapData] = useState<any>([]);
  const [subDistrictList, setSubDistrictList] = useState<any>([]); // Tambon
  const [stationName, setStationName] = useState<any>("");
  const [trigger, setTrigger] = useState<number>(0);
  const [userSession, setUserSession] = useState<any>(getUserSession());

  const history = useHistory();

  const [form] = Form.useForm();

  const { Title } = Typography;
  const { Option } = Select;

  useEffect(() => {
    if (!loaded) {
      init();
      setLoaded(true);
    }
  }, []);

  const init = () => {
    setLoading(true);
    findProvinceAll();
    findPreviousTambon();
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      if (autoValue) {
        const { value } = autoValue;
        const {
          description,
          place_id,
          reference,
          structured_formatting,
          terms,
        } = value;
        const { main_text, secondary_text } = structured_formatting;
        const placeId = value.place_id;
        const res = await geocodeByPlaceId(placeId);
        const lat_lnt = await getLatLng(res[0]);
        const { lat, lng } = lat_lnt;

        console.log(terms);
        console.log(main_text);
        console.log(secondary_text);

        form.setFieldsValue({
          subDistrict: main_text,
        });
        // setStationName(main_text);
        // setIsPin(true);
        const seq = mapData.length + 1;
        const { subDistrict } = form.getFieldsValue();
        // console.log(subDistrict);
        // const tCode = findTambonCode(main_text);
        // const result = findTambonName(tCode);
        // console.log(result);
        const data = {
          color,
          locationName: main_text,
          lat,
          lng,
          subDistrict: main_text,
          seq,
          previous: false,
        };
        // console.log(data);
        setMapData((prev: any) => [...prev, data]);
      }
    })();
  }, [autoValue]);

  useEffect(() => {
    setMapData(mapData);
  }, [trigger]);

  const findPreviousTambon = async () => {
    const response = await bookingService.findPreviousTambon(SCOPE_ID);
    // console.log(response.data);
    const { addrPath, scopeCode, scopeName, tambon, color } = response.data[0];
    setColor(color);
    let provinceId = addrPath.substring(0, 2);
    let districtId = addrPath.substring(3, 7);
    let subDistrictId = addrPath.substring(8);

    form.setFieldsValue({
      scopeCode,
      scopeName,
      province: provinceId,
      district: districtId,
      // subDistrict: tambon,
    });
    setLocation({
      provinceCode: addrPath.substring(0, 2),
    });
    findProvinceAll();
    findDistrictAll(provinceId);
    findSubDistrictAll(provinceId, districtId);

    let arr: any = [];
    response.data.map((item: any, i: number) => {
      const data = {
        color,
        locationName: item.tambon,
        lat: item.lat,
        lng: item.lng,
        seq: i + 1,
        scopeId: item.scopeId,
        scopeDetailId: item.scopeDetailId,
        // scopeName: item.scopeName,
        subDistrict: item.tambon,
        previous: true,
      };
      arr.push(data);
    });
    setMapData(arr);
  };

  const findProvinceAll = async () => {
    const response = await bookingService.findProvinceAll();
    if (response.data) {
      setProvinceList(response.data);
    }
  };

  const findDistrictAll = async (provincCode: any) => {
    const response = await bookingService.findDistrict(provincCode);
    if (response.data) {
      setDistrictList(response.data);
    }
  };

  const findSubDistrictAll = async (provinceCode: any, districtCode: any) => {
    const response = await bookingService.findSubDistrict(
      provinceCode,
      districtCode
    );
    if (response.data) {
      setSubDistrictList(response.data);
    }
  };

  const handleChangeProvince = async (val: any) => {
    setLocation({
      provinceCode: val,
    });
    const response = await bookingService.findDistrict(val);
    if (response.data) {
      setDistrictList(response.data);
    }
  };

  const handleChangeDistrict = async (districtCode: any) => {
    let { provinceCode } = location;
    const response = await bookingService.findSubDistrict(
      provinceCode,
      districtCode
    );
    if (response.data) {
      setSubDistrictList(response.data);
    }
  };

  const handleChangeSubDistrict = async (tambonCode: any) => {
    setCondition(tambonCode);
    const response = await bookingService.findZipCodeByTambonCode(tambonCode);
    if (response.data) {
      form.setFieldsValue({
        zipcode: response.data.zipCode,
      });
    }
  };

  const handleCallbackMap = (values: any) => {
    let tambonCode = form.getFieldValue("subDistrict");
    if (tambonCode === undefined) {
      message.warning("กรุณาเลือกตำบล!");
      return;
    }
    let { tambon } = findTambonName(tambonCode);
    let rs = mapData.some((item: any) => item.locationName === tambon);
    if (rs) {
      message.warning("ตำบลนี้มีในขอบเขตแล้ว กรุณาเลือกตำบลที่ไม่ซ้ำ!");
      return;
    }
    // setHoldData([values]);
    const seq = mapData.length + 1;
    const { lat, lng } = values;
    const { subDistrict } = form.getFieldsValue();
    const result = findTambonName(subDistrict);
    const data = {
      color,
      locationName: result.tambon,
      lat,
      lng,
      // scopeId: item.scopeId,
      // scopeDetailId: item.scopeDetailId,
      // scopeName: item.scopeName,
      subDistrict: result.tambon,
      seq,
      previous: false,
    };
    // console.log(data);
    setMapData((prev: any) => [...prev, data]);
  };

  const handleDeleteScopeItem = (id: any) => {
    const result = mapData.filter((item: any, i: any) => i !== id);
    setMapData(result);
  };

  // const handleAddTambon = () => {
  //   let tambonCode = form.getFieldValue("subDistrict");
  //   if (tambonCode === undefined) {
  //     message.warning("กรุณาเลือกตำบล!");
  //     return;
  //   }
  //   let { tambon } = findTambonName(tambonCode);
  //   let rs = mapData.some((item: any) => item.locationName === tambon);
  //   if (rs) {
  //     message.warning("ตำบลนี้มีในขอบเขตแล้ว กรุณาเลือกตำบลที่ไม่ซ้ำ!");
  //     return;
  //   }
  //   const seq = mapData.length + 1;
  //   const { lat, lng } = holdData[0];
  //   const { subDistrict } = form.getFieldsValue();
  //   const result = findTambonName(subDistrict);
  //   const data = {
  //     color,
  //     locationName: result.tambon,
  //     lat,
  //     lng,
  //     // scopeId: item.scopeId,
  //     // scopeDetailId: item.scopeDetailId,
  //     // scopeName: item.scopeName,
  //     subDistrict: result.tambon,
  //     seq,
  //     previous: false,
  //   };
  //   // console.log(data);
  //   setMapData((prev: any) => [...prev, data]);
  // };

  const findTambonCode = (tambonName: any) => {
    let result = subDistrictList.filter(
      (item: any) => item.tambon === tambonName
    );
    return result[0];
  };

  const findTambonName = (tambonCode: any) => {
    let result = subDistrictList.filter(
      (item: any) => item.tambonCode === tambonCode
    );
    return result[0];
  };

  const handleDragEnd = (e: any, idx: any) => {
    let lat: any = e.latLng.lat();
    let lng: any = e.latLng.lng();

    for (let i = 0; i < mapData.length; i++) {
      if (i === idx) {
        mapData[i].lat = lat;
        mapData[i].lng = lng;
      }
    }
    setTrigger((prev: number) => prev + 1);
  };

  const onFinish = async (values: any) => {
    const result = mapData.some((item: any) => item.previous === false);
    if (!result) {
      message.warning("กรุณาเพิ่มตำบลใหม่!");
      return;
    }
    const count = mapData.filter((item: any) => item.previous === true).length;
    const { district, subDistrict, province, scopeCode, scopeName } = values;
    let arrScopeD: any = [];
    mapData.map((item: any) => {
      let result = subDistrictList.filter(
        (ele: any) => ele.tambon === item.locationName
      );
      if (!item.previous) {
        const scopeD = {
          locationCode: result[0].tambonCode,
          lat: item.lat,
          lng: item.lng,
          previous: item.previous,
          scopeId: SCOPE_ID,
          scopeName,
          seq: Number(count),
          savStation: "00",
          tambon: item.locationName,
        };
        arrScopeD.push(scopeD);
      }
    });

    const data = {
      createUserid: userSession.secUserId,
      createUsername: userSession.fullname,
      scopeDetail: arrScopeD,
      savStation: userSession.stationCode,
    };
    // console.log(data);
    const response = await bookingService.saveScopeDetail(data);
    if (response.data) {
      message.success("เพิ่มตำบลใหม่สำเร็จ");
      init();
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log(errorInfo);
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <Row style={{ height: "100%" }}>
        <Col
          span={7}
          style={{ backgroundColor: "#fff", borderRadius: 5, padding: 15 }}
        >
          <Title level={4} style={{ marginBottom: 20 }}>
            เพิ่มตำบลในขอบเขต {SCOPE_NAME}
          </Title>
          {/* <RAutocomplete autoValue={autoValue} setAutoValue={setAutoValue} /> */}
          <div style={{ marginTop: 15 }}>
            <Form
              form={form}
              name="createScope"
              labelCol={{}}
              wrapperCol={{}}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                name="scopeCode"
                rules={[{ required: true, message: "กรุณาระบุ รหัสขอบเขต!" }]}
              >
                <Input placeholder="รหัสของเขต" readOnly />
              </Form.Item>

              <Form.Item
                name="scopeName"
                rules={[{ required: true, message: "กรุณาระบุ ชื่อขอบเขต!" }]}
              >
                <Input placeholder="ชื่อของเขต" readOnly />
              </Form.Item>

              <Form.Item
                name="province"
                rules={[{ required: true, message: "กรุณาระบุ จังหวัด!" }]}
              >
                <Select
                  placeholder="จังหวัด"
                  onChange={handleChangeProvince}
                  disabled
                >
                  {provinceList.map((item: any, index: number) => (
                    <Option key={index} value={item.provinceCode}>
                      {item.province}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="district"
                rules={[{ required: true, message: "กรุณาระบุ อำเภอ!" }]}
              >
                <Select
                  placeholder="อำเภอ"
                  onChange={handleChangeDistrict}
                  disabled
                >
                  {districtList.map((item: any, index: number) => (
                    <Option key={index} value={item.amphoeCode}>
                      {item.amphoe}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="subDistrict"
                rules={[{ required: true, message: "กรุณาระบุ ตำบล!" }]}
              >
                <Select placeholder="ตำบล" onChange={handleChangeSubDistrict}>
                  {subDistrictList.map((item: any, index: number) => (
                    <Option key={index} value={item.tambonCode}>
                      {item.tambon}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              {/* <div style={{ textAlign: "center" }}>
                <Button
                  type="primary"
                  size="small"
                  onClick={() => handleAddTambon()}
                >
                  เพิ่ม
                </Button>
              </div> */}

              <div style={{ marginTop: "2rem" }}>
                <TableScope data={mapData} remove={handleDeleteScopeItem} />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "2rem",
                }}
              >
                <Space>
                  <Form.Item>
                    <Button htmlType="button" onClick={() => history.goBack()}>
                      กลับ
                    </Button>
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      บันทึก
                    </Button>
                  </Form.Item>
                </Space>
              </div>
            </Form>
          </div>
        </Col>
        <Col span={17}>
          <RMap
            centerCoordinate={centerCoordinate}
            callBack={handleCallbackMap}
            dragEnd={handleDragEnd}
            data={mapData}
            setIsPin={setIsPin}
            setData={setMapData}
            condition={condition}
          />
        </Col>
      </Row>
    </div>
  );
});

const CreateTambonInScope: React.FC = (props: any) => {
  //   console.log(props);
  const { scopeId, scopeName, stationId } = props.location.state;
  SCOPE_ID = scopeId;
  SCOPE_NAME = scopeName;

  const MapLoader: any = withScriptjs(Wrapper);
  return (
    <MapLoader
      googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCY8tssCzJWXdG7BnWMr5EmiljzHL4zp0o&v=3.exp&libraries=geometry,drawing,places"
      loadingElement={<div style={{ height: `100%` }} />}
    />
  );
};

export default CreateTambonInScope;
