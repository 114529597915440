import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Home from "src/views/Home";
import LoginForm from "src/views/LoginForm";
import ModernAppLayout from "./layouts/ModernAppLayout";
import AppRoute from "./views/AppRoute";
import BookingQuickListLineHall from "./views/BookingQuickListLineHall";
import BookingQuickLineHall from "./views/BookingQuickLineHall";
import CreateUserRole from "./views/fleet/CreateUserRole";
import SelectStation from "./views/SelectStation";

const App: React.FC = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/login" component={LoginForm} />
        <Route path="/selectStation" component={SelectStation} />
        <Route path="/app" component={AppRoute} />
        <Route path="/bookingQuickLineHall" component={BookingQuickLineHall} />
        <Route
          path="/bookingQuickListLineHall"
          component={BookingQuickListLineHall}
        />

        <Redirect path="/" to="/login" />
      </Switch>
    </Router>
  );
};

export default App;
