import Truck1 from "../assets/images/truck1.png";

export const MAP_API = "AIzaSyCY8tssCzJWXdG7BnWMr5EmiljzHL4zp0o";

export const agreenment = [
  { id: "1", value: "" },
  { id: "2", value: "" },
  { id: "3", value: "" },
  { id: "4", value: "" },
  { id: "5", value: "" },
  { id: "6", value: "" },
];

export const paymentDummy = [
  // {id:"1", value:"เงินสด", isSelect: true},
  // {id:"2", value:"บัตรเครดิต", isSelect: false},
  // {id:"3", value:"บัตรเดบิล", isSelect: false},
  { id: "1", value: "เงินเชื่อ", isSelect: true },
];

export const routeDummy = [
  {
    id: "1",
    distance: 184,
    duration: { h: 2, m: 59 },
    routeValue: "route1",
    routeLabel: "ไปเชียงราย",
    routes: [
      {
        rid: "1",
        name: "กาดเมืองใหม่",
        lat: 18.796421617310266,
        lng: 98.99924541258005,
        routeType: "P",
      },
      {
        rid: "2",
        name: "โรงพยาบาลเชียงรายประชานุเคราะห์",
        lat: 19.900935661351337,
        lng: 99.82919512608805,
        routeType: "D",
      },
      {
        rid: "3",
        name: "โรงแรมอยู่สุขวิลล่า",
        lat: 19.89858265383188,
        lng: 99.83008110560687,
        routeType: "D",
      },
    ],
  },
  {
    id: "2",
    distance: 671,
    duration: { h: 8, m: 53 },
    routeValue: "route2",
    routeLabel: "ไปตลาดไท, สี่มุมเมือง",
    routes: [
      {
        rid: "1",
        name: "กาดเมืองใหม่",
        lat: 18.796421617310266,
        lng: 98.99924541258005,
        routeType: "P",
      },
      {
        rid: "2",
        name: "นิ่มซี่เส็งขนส่ง 1988 สาขาสำนักงานใหญ่",
        lat: 18.8093937725925,
        lng: 99.00956049724027,
        routeType: "P",
      },
      {
        rid: "3",
        name: "ตลาดไท",
        lat: 14.080948726035114,
        lng: 100.62330372972013,
        routeType: "D",
      },
      {
        rid: "4",
        name: "ตลาดสี่มุมเมือง",
        lat: 13.96274547424976,
        lng: 100.6198689260294,
        routeType: "D",
      },
    ],
  },
];

export const serviceDummy = [
  { id: "1", value: "พนักงานยกของ 1 คน", cost: 200, isSelect: false },
  { id: "2", value: "พนักงานยกของ 2 คน", cost: 400, isSelect: false },
  { id: "3", value: "พนักงานยกของ 3 คน", cont: 600, sSelect: false },
];

export const testDummy = [
  { id: "1", name: "Apple" },
  { id: "2", name: "Samsung" },
  { id: "3", name: "Microsoft" },
  { id: "4", name: "Google" },
];

export const truckDummy = [
  {
    id: "1",
    image: Truck1,
    title: "6 ล้อคอก",
    details:
      "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.",
    isSelect: true,
  },
  {
    id: "2",
    image: Truck1,
    title: "6 ล้อทึบ",
    details:
      "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.",
    isSelect: false,
  },
  {
    id: "3",
    image: Truck1,
    title: "10 ล้อคอก",
    details:
      "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.",
    isSelect: false,
  },
  {
    id: "4",
    image: Truck1,
    title: "10 ล้อทึบ",
    details:
      "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.",
    isSelect: false,
  },
  {
    id: "5",
    image: Truck1,
    title: "หัวเทรเลอร์",
    details:
      "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.",
    isSelect: false,
  },
  {
    id: "6",
    image: Truck1,
    title: "หัวเทรเลอร์",
    details:
      "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.",
    isSelect: false,
  },
  {
    id: "7",
    image: Truck1,
    title: "10 ล้อพ่วงแม่",
    details:
      "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.",
    isSelect: false,
  },
  {
    id: "8",
    image: Truck1,
    title: "10 ล้อพ่วงลูก",
    details:
      "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.",
    isSelect: false,
  },
];
