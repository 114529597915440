import React, { memo } from "react";
import { Spin } from "antd";

interface IProps {
  text?: string;
  size?: any;
}
const RSpin: React.FC<IProps> = (props: any) => {
  const { text = "กำลังประมวลผล...", size } = props;
  return (
    <div
      style={{
        // height: "100vh",
        // width: "100vw",
        width: "100%",
        height: "100%",

        position: "fixed",
        top: 0,
        left: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",

        textAlign: "center",
      }}
    >
      <Spin size={size} />
      <div>{text}</div>
    </div>
  );
};

export default memo(RSpin);
