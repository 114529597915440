import type { AppThunk } from "../index";
import { SessionUser } from "./types";
import {
  setSessionUser as setSessionUserAction,
  getSessionUserFromLocal as getSessionUserFromLocalAction,
} from "./slice";

export const getSessionUserFromLocal =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    // const username = localStorage.getItem("sessionUser.username");
    // const email = localStorage.getItem("sessionUser.email");

    // console.log("username, email", username, email);
    // const sessionUser: SessionUser = {
    //   username: username as string,
    //   email: email as string,
    // };

    const sessionUser: any = localStorage.getItem("sessionUser");
    dispatch(getSessionUserFromLocalAction(sessionUser));
  };

export const setSessionUser =
  (sessionUser: any): AppThunk =>
  async (dispatch): Promise<void> => {
    // localStorage.setItem("sessionUser.username", sessionUser.username);
    // localStorage.setItem("sessionUser.email", sessionUser.email);
    // localStorage.setItem("sessionUser", sessionUser);
    // console.log("action::setSessionUser", sessionUser);
    // dispatch(setSessionUserAction(sessionUser));
    try {
      const jsonValue = await JSON.stringify(sessionUser)
      localStorage.setItem("sessionUser", jsonValue);
      dispatch(setSessionUserAction(sessionUser));
      // console.log('Action : ',sessionUser)
      
    } catch (error) {
        console.log("Location storage set item : ", error);
    }
      
  };

export const getUserSession = () => {
  try {
      const jsonValue = localStorage.getItem("sessionUser");
      // dispatch(getSessionUserFromLocalAction(jsonValue != null ? JSON.parse(jsonValue) : null));
      return jsonValue != null ? JSON.parse(jsonValue) : null
  } catch (error) {
      console.log("Location storage get item : ", error);
  }
}

export const clearSessionUser = (): AppThunk => async (dispatch): Promise<void> => {
  localStorage.removeItem("sessionUser");
  console.log("Logout...")
}
