import React, { useState, useEffect, useRef, useCallback } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Drawer,
  Col,
  Row,
  message,
  Radio,
  Space,
  Switch,
  Table,
  Typography,
  Input,
  Descriptions,
  Popconfirm,
} from "antd";
import {
  DirectionsRenderer,
  GoogleMap,
  InfoWindow,
  Marker,
  withScriptjs,
  withGoogleMap,
} from "react-google-maps";
import { RiMapPin2Fill } from "react-icons/ri";
import { IoCloseCircle, IoPencil } from "react-icons/io5";
import LocationMarker from "../../assets/icons/location_marker.png";
import bookingService from "src/services/bookingService";
import Item from "antd/lib/list/Item";

let ID = "";
let SCOPE_ID = "";
let SCOPE_NAME = "";
let LAT = "";
let LNG = "";
let NAME = "";

const TableScope: React.FC<{
  data: any;
  showDrawer: any;
  callBackData: any;
}> = (props: any) => {
  const { data, showDrawer, callBackData } = props;
  const columns = [
    {
      //   align: "right" as "right",
      width: 50,
      title: "ลำดับ",
      dataIndex: "seq",
      key: "seq",
    },
    {
      width: 100,
      title: "รหัส",
      dataIndex: "scopeCode",
      key: "scopeCode",
    },
    {
      title: "ชื่อขอบเขต",
      dataIndex: "scopeName",
      key: "scopeName",
      render: (text: any, values: any) => {
        return (
          <div
            onClick={() => {
              showDrawer();
              callBackData(values);
            }}
            style={{ cursor: "pointer", color: "#5DADE2" }}
          >
            {text}
          </div>
        );
      },
    },
    {
      width: 90,
      title: "จำนวนตำบล",
      dataIndex: "scopeAmt",
      key: "scopeAmt",
      render: (text: any, record: any) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <RiMapPin2Fill
              size={15}
              color={record.color}
              style={{ marginRight: 5 }}
            />
            <div>{text}</div>
          </div>
        );
      },
    },
    {
      width: 80,
      title: "สถานะ",
      dataIndex: "status",
      key: "status",
    },
  ];
  return (
    <>
      {data.length > 0 ? (
        <Table
          dataSource={data}
          columns={columns}
          size="small"
          rowKey={(obj: any) => obj.id}
        />
      ) : (
        <Table columns={columns} size="small" />
      )}
    </>
  );
};

const RMap: React.FC<{
  centerCoordinate: any;
  callBack?: any;
  data?: any;
  directions?: any;
  onClickMarker?: any;
  onCloseInfo?: any;
  setIsPin?: any;
  setData?: any;
  condition?: any;
}> = React.memo((props: any) => {
  const {
    centerCoordinate,
    callBack,
    data,
    setIsPin,
    onClickMarker,
    onCloseInfo,
    setData,
    condition,
  } = props;
  const mapRef = useRef(null);

  const Map: any = withGoogleMap((props) => (
    <GoogleMap
      ref={(map) => {
        const bounds = new window.google.maps.LatLngBounds();
        data &&
          data.map((item: any) => {
            bounds.extend(new window.google.maps.LatLng(item.lat, item.lng));
          });
        map && map.fitBounds(bounds);
        // props.onMapMounted(map);
      }}
      defaultCenter={centerCoordinate}
      defaultZoom={13}
      // onClick={handleOnClickMap}
    >
      {data &&
        data.map((item: any, index: any) => {
          if (item.lat === null && item.lng === null) {
            return;
          }

          const svgMarker = {
            // path: "M10.453 14.016l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM12 2.016q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
            path: "M390 924 c-145 -40 -239 -144 -265 -295 -9 -49 17 -146 62 -237 39 -78 160 -243 242 -332 l51 -55 51 55 c80 86 203 254 242 333 54 107 72 187 58 256 -16 82 -46 135 -107 190 -90 83 -222 116 -334 85z m171 -187 c106 -71 106 -203 0 -274 -97 -66 -241 15 -241 135 0 125 141 206 241 139z",
            fillColor: `${item.color}`,
            fillOpacity: 1,
            strokeWeight: 0,
            rotation: 180,
            scale: 0.03,
            anchor: new google.maps.Point(15, 30),
          };
          return (
            <Marker
              icon={svgMarker}
              key={index}
              position={{ lat: Number(item.lat), lng: Number(item.lng) }}
              onClick={() => onClickMarker(item.id)}
              // onMouseOut={() => onMouseOut(item.id)}
            >
              {item.isOpen ? (
                <InfoWindow onCloseClick={() => onCloseInfo(item.id)}>
                  <>{item.locationName}</>
                </InfoWindow>
              ) : null}
            </Marker>
          );
        })}

      {/* {directions && (
          <DirectionsRenderer
            directions={directions}
            options={{
              polylineOptions: {
                geodesic: true,
                strokeOpacity: 1,
                strokeColor: "#0000ff",
                strokeWeight: 3,
              },
            }}
          />
        )} */}
    </GoogleMap>
  ));
  return (
    <Map
      ref={mapRef}
      containerElement={<div style={{ height: `750px`, width: "750px" }} />}
      mapElement={<div style={{ height: `100%` }} />}
    />
  );
});

const Wrapper: any = () => {
  const [centerCoordinate, setCenterCoordinate] = useState<any>({
    lat: Number(18.80753508304137),
    lng: Number(99.01845056588674),
  });
  const [isDeleteTambon, setIsDeleteTambon] = useState<any>(false);
  const [isOpen, setIsOpen] = useState<any>(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [mapData, setMapData] = useState<any>([]);
  const [station, setStation] = useState<any>({});
  const [scopeList, setScopeList] = useState<any>([]);
  const [scopeDetailList, setScopeDetailList] = useState<any>([]);
  const [scopeTitle, setScopeTitle] = useState<any>("");
  const [trigger, setTrigger] = useState<number>(0);
  const [pointer, setPointer] = useState<string>("");
  const [visible, setVisible] = useState<any>(false);
  const [selectTambon, setSelectTambon] = useState<any>(1);

  useEffect(() => {
    if (!loaded) {
      init();
      setLoaded(true);
    }
  }, []);

  const history = useHistory();
  const { Title } = Typography;

  const init = () => {
    setLoading(true);
    setIsDeleteTambon(false);
    prepareData();
    findStationById();
    findScopeById();
    laodScopeData();
    setLoading(false);
  };

  const prepareData = () => {
    const data = {
      id: "main1",
      isOpen: true,
      color: "#000",
      lat: Number(LAT),
      lng: Number(LNG),
      locationName: NAME,
      main: true,
    };
    setMapData([data]);
  };

  const laodScopeData = async () => {
    const response = await bookingService.loadScopeData(ID);
    if (response.data) {
      const arr: any = [];
      response.data.map((item: any) => {
        const data = {
          color: item.color,
          id: item.id,
          isOpen: false,
          lat: Number(item.lat),
          lng: Number(item.lng),
          locationName: `${item.scopeName} : ${item.tambon}`,
        };
        arr.push(data);
      });
      setMapData((prev: any) => [...prev, ...arr]);
    }
  };

  const findStationById = async () => {
    let id = ID; //props.match.params.id
    const response = await bookingService.findStationById(id);
    if (response.data) {
      let address = handleAddress(response.data);
      response.data.address = address;
      setStation(response.data);
    }
  };

  const findScopeById = async () => {
    let id = ID; //props.match.params.id
    const response = await bookingService.findScopeById(id);
    if (response.data) {
      setScopeList(response.data);
    }
  };

  const handleAddress = (data: any) => {
    const { address, province, amphoe, tambon, zipcode } = data;
    return `${address} ต.${tambon} อ.${amphoe.substring(
      5
    )} จ.${province} ${zipcode}`;
  };

  const drawerScope = () => {
    return (
      <Drawer
        width={450}
        title={scopeTitle}
        placement="right"
        onClose={onClose}
        visible={visible}
        headerStyle={{ fontSize: 16, fontWeight: "bold" }}
      >
        {scopeDetailList.map((item: any, i: any) => (
          <div
            key={i}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: 10,
              fontWeight: "bold",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div style={{ marginRight: 10 }}>{i + 1}</div>
              <div
              // style={{ cursor: "pointer", color: "#5DADE2" }}
              // onClick={() =>
              //   history.push({
              //     pathname: "/app/booking/createScope",
              //     state: {
              //       action: "EDIT",
              //       stationId: ID, //props.match.params.id
              //       // stationName: null,
              //       scopeDetailId: item.id,
              //     },
              //   })
              // }
              >
                {item.tambon}
              </div>
            </div>
            <Popconfirm
              title={`คุณต้องการลบตำบล ${item.tambon} ใช่ หรือ ไม่?`}
              onConfirm={(e: any) => confirm(e, item.id)}
              onCancel={cancel}
              okText="ใช่"
              cancelText="ไม่"
              placement="left"
            >
              <IoCloseCircle
                size={20}
                color="red"
                style={{ cursor: "pointer" }}
              />
            </Popconfirm>
          </div>
        ))}
        <div style={{ marginTop: 20, textAlign: "center" }}>
          <Button
            type="primary"
            size="small"
            onClick={() =>
              history.push({
                pathname: `/app/booking/createTambonInScope`,
                state: {
                  stationId: ID, //props.match.params.id
                  scopeName: SCOPE_NAME,
                  scopeId: SCOPE_ID,
                },
              })
            }
          >
            เพิ่มขอบเขต
          </Button>
        </div>
      </Drawer>
    );
  };

  const confirm = async (e: any, id: any) => {
    const result = scopeDetailList.filter((item: any) => item.id !== id);
    setScopeDetailList(result);
    const response = await bookingService.deleteScopeDetaiById(id);
    if (response.data) {
      console.log(response.data);
      message.success("ลบข้อมูลสำเร็จ");
      setIsDeleteTambon(true);
    }
  };

  const cancel = (e: any) => {
    // console.log("Cancel ", e);
  };

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    if (isDeleteTambon) {
      console.log(isDeleteTambon);
      init();
    }
    setVisible(false);
  };

  const handleCallBackData = async (values: any) => {
    const { id, scopeName } = values;
    SCOPE_ID = id;
    SCOPE_NAME = scopeName;
    setScopeTitle(`ขอบเขต ${scopeName}`);
    const response = await bookingService.findScopeDetailById(id);
    if (response.data.length > 0) {
      setScopeDetailList(response.data);
    } else {
      // message.warning("")
    }
  };

  const handleShowInfowindow = (checked: any) => {
    for (let i = 0; i < mapData.length; i++) {
      mapData[i].isOpen = checked;
    }
    setMapData([...mapData]);
    setIsOpen(checked);
  };

  const handleOnClickMarker = (id: any) => {
    let index = mapData.findIndex((item: any) => item.id === id);
    for (let i = 0; i < mapData.length; i++) {
      if (index === i) {
        mapData[i].isOpen = true;
      }
    }
    setMapData([...mapData]);
  };

  const handleOnCloseInfo = (id: any) => {
    let index = mapData.findIndex((item: any) => item.id === id);
    for (let i = 0; i < mapData.length; i++) {
      if (index === i) {
        mapData[i].isOpen = false;
      }
    }
    setMapData([...mapData]);
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "#fff",
        borderRadius: 5,
        padding: 15,
      }}
    >
      {drawerScope()}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Title level={4} style={{ fontWeight: "bold" }}>
          จัดการสถานี
        </Title>
      </div>
      <div>
        <Row>
          <Col
            span={10}
            style={{ paddingRight: 15, paddingTop: 15, borderRadius: 5 }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                // justifyContent: "space-between",
              }}
            >
              <Space>
                <Button
                  style={{ width: 60 }}
                  type="primary"
                  onClick={() =>
                    history.push({
                      pathname: "/app/booking/createStation",
                      state: { action: "edit", id: ID }, // props.match.params.id
                    })
                  }
                >
                  แก้ไข
                </Button>
                <Button style={{ width: 60 }} onClick={() => history.goBack()}>
                  กลับ
                </Button>
              </Space>
            </div>

            <Descriptions
              column={2}
              bordered
              layout="vertical"
              labelStyle={{ fontWeight: "bold" }}
              style={{ marginTop: "1rem" }}
            >
              <Descriptions.Item label="รหัส">{station.code}</Descriptions.Item>
              <Descriptions.Item label="สถานี">
                {station.name}{" "}
                {station.useFlg === 0 ? null : (
                  <span style={{ color: "red" }}>[ไม่ใช้งาน]</span>
                )}
              </Descriptions.Item>
              <Descriptions.Item label="ที่อยู่" span={2}>
                {station.address}
              </Descriptions.Item>
              <Descriptions.Item label="เบอร์โทร" style={{ width: 140 }}>
                {station.tel}
              </Descriptions.Item>
              <Descriptions.Item label="หมายเหตุ">
                {station.remark}
              </Descriptions.Item>
            </Descriptions>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: "2rem",
              }}
            >
              <Button
                type="primary"
                style={{ width: 60 }}
                onClick={() =>
                  history.push({
                    pathname: "/app/booking/createScope",
                    state: {
                      action: "NEW",
                      stationId: ID, //props.match.params.id
                      stationName: station.name,
                      scopeDetailId: null,
                      station,
                    },
                  })
                }
              >
                เพิ่ม
              </Button>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Switch checked={isOpen} onChange={handleShowInfowindow} />
                <div style={{ marginLeft: 10 }}>แสดงชื่อสาย</div>
              </div>
            </div>
            <div style={{ marginTop: 20 }}>
              <TableScope
                data={scopeList}
                showDrawer={showDrawer}
                callBackData={handleCallBackData}
              />
            </div>
          </Col>
          <Col span={14} style={{ marginTop: 15 }}>
            <RMap
              centerCoordinate={centerCoordinate}
              data={mapData}
              onClickMarker={handleOnClickMarker}
              onCloseInfo={handleOnCloseInfo}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

const ManageStation: React.FC = (props: any) => {
  console.log(props);
  const { id, lat, lng, name } = props.location.state.data;
  ID = id;
  LAT = lat;
  LNG = lng;
  NAME = name;

  const MapLoader: any = withScriptjs(Wrapper);

  return (
    <MapLoader
      googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCY8tssCzJWXdG7BnWMr5EmiljzHL4zp0o&v=3.exp&libraries=geometry,drawing,places"
      loadingElement={<div style={{ height: `100%` }} />}
    />
  );
};

export default ManageStation;
