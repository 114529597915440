import { Menu } from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import React, { useState } from "react";
import classes from "./ModernAppLayout.module.css";
import SideNavModule from "./SideNavModule";
import SideNavMenu from "./SideNavMenu";

interface IProps {
  children?: React.ReactNode;
}

const menuModules = [
  { id: 1, name: "dashboard", icon: "FeatherHome", code: "001" },
  { id: 2, name: "home", icon: "TableOutlined", code: "002" },
  { id: 3, name: "booking", icon: "TeamOutlined", code: "003" },
  // { id: 3, name: "Post", icon: "BookOutlined", code: "002" },
  // { id: 4, name: "Counter", icon: "TableOutlined", code: "003" },
  // { id: 5, name: "School", icon: "TeamOutlined", code: "004" },
];

const menus = [
  {
    moduleCode: "001",
    moduleMenus: [{ id: 1, name: "Dashboard", path: "/app/dashboard" }],
  },
  {
    moduleCode: "002",
    moduleName: "กำหนดข้อมูล",
    moduleMenus: [
      { id: 1, name: "กำหนดผู้ใช้", path: "/app/booking/createUser" },
      {
        id: 2,
        name: "กำหนดจุดรับส่งสินค้า(ระยะใกล)",
        path: "/app/booking/createDailyRoute",
      },
      { id: 3, name: "กำหนดสถานี", path: "/app/booking/station" },
    ],
  },
  {
    moduleCode: "003",
    moduleMenus: [
      { id: 1, name: "ข้อมูลการจองรถ", path: "/app/booking/bookingData" },
      {
        id: 2,
        name: "จองรถวิ่งระยะใกล",
        path: "/app/booking/dataLineHaul",
        // path: `/app/booking/bookingLineHaul/0`,
      },
      {
        id: 3,
        name: "จองรถวิ่งกระจาย",
        path: "/app/booking/dataDistribute",
        // path: "/app/booking/bookingDistribute",
      },
    ],
  },
  // {
  //   moduleCode: "003",
  //   moduleMenus: [{ id: 5, name: "Counter", path: "/app/counter" }],
  // }
];

const ModernAppLayout: React.FC<IProps> = (props) => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [menuItems, setMenuItems] = useState<any>({});

  const showHandler = () => {
    setShowMenu((prev) => !prev);
  };

  const handleSelectModule = (moduleCode: string) => {
    // console.log("module code", moduleCode);
    const moduleItem = menus.find((module) => module.moduleCode === moduleCode);
    setMenuItems(moduleItem?.moduleMenus);
    if (!showMenu) {
      setShowMenu(true);
    }
  };

  return (
    <div style={{ display: "flex" }}>
      <div className={classes.sideNav}>
        <SideNavModule
          showMenu={showHandler}
          onSelectModule={handleSelectModule}
          menuModules={menuModules}
        />
        {showMenu && <SideNavMenu menuItems={menuItems} />}
      </div>

      <div
        style={{ backgroundColor: "#f1f2f7", width: "100%", padding: "20px" }}
      >
        {props.children}
      </div>
    </div>
  );
};

export default ModernAppLayout;
