import React, { useState, useEffect, useRef, memo } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Col,
  Form,
  Image,
  Row,
  Modal,
  message,
  Typography,
  TimePicker,
  Space,
  Select,
  Input,
  Popconfirm,
  Spin,
} from "antd";
import {
  DirectionsRenderer,
  Marker,
  InfoWindow,
  GoogleMap,
  withScriptjs,
  withGoogleMap,
} from "react-google-maps";
import moment from "moment";
import { AiOutlineRight, AiOutlineCheckCircle } from "react-icons/ai";
import {
  IoWallet,
  IoFilter,
  IoCloseCircle,
  IoCheckmarkCircle,
  IoChevronForward,
  IoReload,
} from "react-icons/io5";
import { getUserSession } from "src/store/authen/action";
import { paymentDummy, serviceDummy, truckDummy } from "src/constant";
import Truck1 from "../../assets/images/truck1.png";
import bookingService from "src/services/bookingService";
import {
  GetCurrentDate,
  GetCurrentTime,
  StringDate,
  StringDateCutSlash,
  StringTime,
  StringTimeCutColon,
} from "src/utils";
import { useHistory } from "react-router";
import { useForm } from "antd/lib/form/Form";
import RDrawer from "src/components/RDrawer";
import RSpin from "src/components/RSpin";

let PARAMS: any = {};
let SCOPE_HEAD_ID: any = null;
let CANCEL_ID = "";

const RMap: React.FC<{
  centerCoordinate: any;
  callBack?: any;
  data?: any;
  directions?: any;
  mapRef?: any;
  setIsPin?: any;
  setData?: any;
  condition?: any;
}> = memo((props: any) => {
  const { centerCoordinate, data, directions, mapRef } = props;

  const Map: any = withGoogleMap((props: any) => (
    <GoogleMap
      ref={(map) => {
        const bounds = new window.google.maps.LatLngBounds();
        data &&
          data.map((item: any) => {
            bounds.extend(new window.google.maps.LatLng(item.lat, item.lng));
          });
        map && map.fitBounds(bounds);
        // props.onMapMounted(map);
      }}
      defaultCenter={centerCoordinate}
      defaultZoom={13}
    >
      {data &&
        data.map((item: any, index: number) => {
          if (item.lat === null && item.lng === null) {
            return;
          }

          return (
            <Marker
              key={index}
              position={{ lat: Number(item.lat), lng: Number(item.lng) }}
              // onMouseOver={(e) => console.log(e)}
            >
              <InfoWindow>
                <div>{item.locationName}</div>
              </InfoWindow>
            </Marker>
          );
        })}

      {/* {directions && (
        <DirectionsRenderer
          directions={directions}
          options={{
            polylineOptions: {
              geodesic: true,
              strokeOpacity: 1,
              strokeColor: "#0000ff",
              strokeWeight: 3,
            },
          }}
        />
      )} */}
    </GoogleMap>
  ));
  return (
    <Map
      containerElement={<div style={{ height: `950px`, width: "850px" }} />}
      mapElement={<div style={{ height: `100%` }} />}
    />
  );
});

const Wrapper: any = React.memo(() => {
  const [action, setAction] = useState<number>(0);
  const [bookHead, setBookHead] = useState<any>({});
  const [bookRoutes, setBookRoutes] = useState<any>([]);
  const [centerCoordinate, setCenterCoordinate] = useState<any>({
    lat: Number(18.80753508304137),
    lng: Number(99.01845056588674),
  });
  // const [bookedData, setBookedData] = useState<any>({});
  const [directions, setDirections] = useState<any>();
  const [distance, setDistance] = useState<any>("0 กิโลเมตร");
  const [duration, setDuration] = useState<any>("0 ชั่วโมง");
  const [distAndDura, setDistAndDura] = useState<any>({});
  const [driverList, setDriverList] = useState<any>([]);
  const [driverSelect, setDriverSelect] = useState<any>("");
  const [drawerCancelVisible, setDrawerCancelVisible] =
    useState<boolean>(false);
  const [drawerTruckVisible, setDrawerTruckVisible] = useState<boolean>(false);
  const [drawerServiceAndPaymentVisible, setDrawerServiceAndPaymentVisible] =
    useState<boolean>(false);
  const [drawerResultVisible, setDrawerResultVisible] =
    useState<boolean>(false);
  const [isAccept, setIsAccept] = useState<boolean>(false);
  const [jobNo, setJobNo] = useState<any>("");
  const [truckRegistrationList, setTruckRegistrationList] = useState<any>([]);
  const [registrationSelect, setRegistrationSelect] = useState<any>("");
  const [endDateTime, setEndDateTime] = useState<any>(null);
  const [isReadAgreement, setIsReadAgreement] = useState<boolean>(false);
  const [isBooked, setIsBooked] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [modalTruck, setModalTruck] = useState<boolean>(false);
  const [modalServiceAndPayment, setModalServiceAndPayment] =
    useState<boolean>(false);
  const [modalAgreement, setModalAgreenment] = useState<boolean>(false);
  const [mapData, setMapData] = useState<any>([]);
  const [pointer, setPointer] = useState<any>("");
  const [payment, setPayment] = useState<any>({});
  const [paymentList, setPaymentList] = useState<any>([]);
  const [routeHead, setRouteHead] = useState<any>([]);
  const [services, setServices] = useState<any>([]);
  const [serviceList, setServiceList] = useState<any>(serviceDummy);
  const [scopeHeadList, setScopeHeadList] = useState<any>([]);
  const [truckList, setTruckList] = useState<any>(truckDummy);
  const [trigger, setTrigger] = useState<number>(0);
  const [truck, setTruck] = useState<any>([]);
  const [userSession, setUserSession] = useState<any>(getUserSession());

  const mapRef: any = useRef();
  const history = useHistory();
  const [CancelForm] = Form.useForm();

  const { Title } = Typography;
  const { Option } = Select;

  let SUM_DISTANCE = 0;
  let SUM_DURATION = 0;

  useEffect(() => {
    if (!loaded) {
      init();
      setLoaded(true);
    }
  }, []);

  const init = () => {
    setLoading(true);
    if (getUserSession() === null) {
      history.replace("/login");
      return;
    }
    if (PARAMS.action === "NEW") {
      prepareData();
      findScopeHead();
      findScopeById();
      findDriver();
      findTruckRegistration();
      defaultDate();
      findAllPayment();
      findAllTruckType();
    } else if (PARAMS.action === "EDIT") {
      prepareData();
      findAllPayment();
      findAllTruckType();
      findViewDataDistribute(PARAMS.id);
    }
    setLoading(false);
  };

  const findAllTruckType = async () => {
    const response = await bookingService.findAllTruckType();
    if (response.data) {
      // console.log(response.data);
      let arr: any = [];
      response.data.map((item: any, index: number) => {
        let data: any = {
          id: item.id,
          img: Truck1,
          typeName: item.typeName,
          remark: item.remark,
          isSelect: index === 0 ? true : false,
        };
        arr.push(data);
      });
      setTruck(arr[0]);
      setTruckList(arr);
    }
  };

  const findAllPayment = async () => {
    const response = await bookingService.findAllPayment();
    if (response.data) {
      let data = {
        id: response.data[1].id,
        value: response.data[1].paymentName,
      };
      setPayment(data);

      let arr: any = [];
      response.data.map((item: any) => {
        let dataPayment = {
          id: item.id,
          paymentName: item.paymentName,
          isSelect: item.id === 2 ? true : false,
        };
        arr.push(dataPayment);
      });
      setPaymentList(arr);
    }
  };

  const defaultDate = () => {
    formDateTime.setFieldsValue({
      stDate: moment(new Date(), "YYYYMMDD"),
      stTime: moment(new Date(), "HHmmss"),
    });
  };

  const findViewDataDistribute = async (id: any) => {
    const response = await bookingService.findViewDataDistributeById(id);
    if (response.data) {
      const {
        id,
        driverFullname,
        locationName,
        lat,
        lng,
        paymentName,
        registId,
        startDate,
        startTime,
        scopeHeadName,
        truckTypeName,
        truckRemark,
      } = response.data[0];

      formDateTime.setFieldsValue({
        routeName: `${locationName} ${scopeHeadName}`,
        stDate: moment(startDate, "YYYYMMDD"),
        stTime: moment(startTime, "HHmmss"),
        registId,
        driverId: driverFullname,
      });

      let truckDesc = {
        id: null,
        img: Truck1,
        typeName: truckTypeName,
        remark: truckRemark,
      };
      setTruck(truckDesc);

      let paymentDesc = {
        id: null,
        value: paymentName,
      };
      setPayment(paymentDesc);

      let arr = [];
      let arr1 = [];
      let lastIndex = response.data.length - 1;
      arr = response.data.slice(0, lastIndex);
      for (let i = 0; i < arr.length; i++) {
        const data = {
          lat: arr[i].lat,
          lng: arr[i].lng,
          locationName: arr[i].locationName,
        };
        arr1.push(data);
      }
      setMapData(arr1);
      // setViewData(newData);
    }
  };

  const findDriver = async () => {
    const response = await bookingService.findDriverAll();
    if (response.data) {
      setDriverList(response.data);
    }
  };

  const findTruckRegistration = async () => {
    const response = await bookingService.findTruckRegistrationAll();
    if (response.data) {
      setTruckRegistrationList(response.data);
    }
  };

  const prepareData = () => {
    const data = {
      lat: Number(userSession.lat),
      lng: Number(userSession.lng),
      locationName: userSession.stationName,
      custStationId: userSession.stationId,
      main: true,
    };
    setMapData([data]);
  };

  const findScopeHead = async () => {
    const response = await bookingService.findScopeHead();
    if (response.data) {
      setScopeHeadList(response.data);
    }
  };

  const findScopeById = async () => {
    const response = await bookingService.findScopeById(userSession.stationId);
    if (response.data) {
      setScopeHeadList(response.data);
    }
  };

  useEffect(() => {
    if (pointer === "truck") {
      setTruckList(truckList);
    } else if (pointer === "service") {
      let arrService: any = [];
      setServiceList(serviceList);
      serviceList.map((item: any) => {
        if (item.isSelect) {
          const data = {
            id: item.id,
            value: item.value,
          };
          arrService.push(data);
        }
      });
      setServices((prev: any) => [...arrService]);
    } else if (pointer === "payment") {
      setPaymentList(paymentList);
    }
    setPointer("");
  }, [trigger]);

  // const handleChangeDate = (data: any, dataString: any, params: any) => {
  //   // console.log(dataString);
  //   let newData = { ...dateTime };
  //   if (params === "startDate") {
  //     newData.startDate = dataString;
  //   } else if (params === "endDate") {
  //     newData.endDate = dataString;
  //   }
  //   setDateTime(newData);
  // };

  // const handleChangeTime = (data: any, dataString: any, params: any) => {
  //   // console.log(dataString);
  //   let newTime = { ...dateTime };
  //   if (params === "startTime") {
  //     newTime.startTime = dataString;
  //     setPointer("calculateEndDateTime");
  //     setTrigger((prev: any) => prev + 1);
  //   } else if (params === "endTime") {
  //     newTime.endTime = dataString;
  //   }
  //   setDateTime(newTime);
  // };

  // const warning = (msg: any) => {
  //   message.warning(`${msg}`);
  // };

  const handleSelectTruck = (id: any) => {
    truckList.map((item: any) => {
      if (item.id === id) {
        setTruck(item);
        item.isSelect = true;
      } else {
        item.isSelect = false;
      }
    });
    setPointer("truck");
    setTrigger((prev: number) => prev + 1);
  };

  // const handleSelectService = (id: any) => {
  //   serviceList.map((item: any) => {
  //     if (item.id === id) {
  //       item.isSelect = !item.isSelect;
  //     }
  //   });
  //   setPointer("service");
  //   setTrigger((prev: number) => prev + 1);
  // };

  // const handleSelectPayment = (id: any) => {
  //   paymentList.map((item: any) => {
  //     if (item.id === id) {
  //       setPayment(item);
  //       item.isSelect = true;
  //     } else {
  //       item.isSelect = false;
  //     }
  //   });
  //   setPointer("payment");
  //   setTrigger((prev: number) => prev + 1);
  // };

  const agreementModal = () => {
    return (
      <Modal
        title="เงื่อนใขการใช้บริการ"
        visible={modalAgreement}
        onOk={toggleModalAgreenment}
        onCancel={toggleModalAgreenment}
        okText="ตกลง"
        cancelText="ยกเลิก"
      >
        <ul style={{ marginLeft: -20, fontSize: 14 }}>
          <li>
            เมื่อลูกค้าดำเนินการจองรถเสร็จสิ้นแล้ว
            ระบบจะจัดหาพนักงานขับรถในเครือข่ายที่เหมาะสมกับงานของลูกค้า
            และจะมีอีเมล์ยืนยันการจองเพื่อยืนยันว่าลูกค้าได้ทำการจอง
            เรียบร้อยแล้ว
          </li>
          <li>
            เจ้าหน้าที่บริการลูกค้า ทำหน้าที่ติดต่อ ประสานงาน รับจองรถจากลูกค้า
            เมื่อลูกค้าต้องการจะใช้รถจะต้องแจ้งล่วงหน้า 1 วันภายใน 12.00 น.
            กรณีที่ลูกค้าต้องการใช้รถในวันอาทิตย์ต้องจองรถภายในวันศุกร์ก่อน
            15.00 น.
          </li>
          <li>การแจ้งยกเลิกการใช้บริการรถบรรทุก</li>
          <ol style={{ marginLeft: -10 }}>
            <li>
              กรณีที่เป็นงานขึ้นค้างส่งเช้าจะต้องแจ้งยกเลิก ก่อนวันใช้รถล่างหน้า
              1 วันภายใน 13.00 น.
            </li>
            <li>
              กรณีที่เป็นงานขึ้นแล้วส่งสินค้าในวันต้องยกเลิกล่วงหน้าก่อนวันใช้รถภายในเวลา
              15.00 น.
            </li>
            <li>กรณีที่ยกเลิกในวันที่ใช้รถจะมีค่าใข้จ่าย 50% ของค่าขนส่ง</li>
          </ol>
        </ul>
        <div style={{ marginTop: 20 }}>
          <Checkbox
            onChange={(e) => {
              setIsAccept(e.target.checked);
            }}
            checked={true}
          >
            <Title level={4}>ยอมรับข้อตกลง</Title>
          </Checkbox>
        </div>
      </Modal>
    );
  };

  const toggleModalAgreenment = () => {
    setModalAgreenment((prev: any) => !prev);
  };

  const refreshPage = () => {
    window.location.reload();
  };

  ////// End maodal /////

  const RootAndDestination = () => {
    return (
      <div>
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginBottom: 5,
            }}
          >
            <div style={{ fontWeight: "bold", width: "20%" }}>จุดเริ่มต้น</div>
            <div>{userSession.stationName}</div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginBottom: 5,
            }}
          >
            <div style={{ fontWeight: "bold", width: "20%" }}>จุดปลายทาง</div>
            <div>{userSession.stationName}</div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: 5,
          }}
        >
          {/* <div style={{ fontWeight: "bold", width: "20%" }}>ขอบเขตการวิ่ง</div>
          <div style={{ width: "80%" }}> */}
          <Form.Item
            colon={false}
            label="ขอบเขตการวิ่ง"
            name="routeName"
            style={{ width: "100%" }}
            rules={[{ required: true, message: "กรุณาเลือกขอบเขต!" }]}
            // wrapperCol={{ offset: 1 }}
          >
            {PARAMS.action === "NEW" ? (
              <Select
                placeholder="เลือกขอบเขต"
                // style={{ width: "100%" }}
                onChange={(val: any) => handleChangeScope(val)}
              >
                {scopeHeadList.map((item: any) => (
                  <Option
                    key={item.id}
                    value={item.id}
                  >{`${item.stationName} : ${item.scopeName}`}</Option>
                ))}
              </Select>
            ) : (
              <Input readOnly />
            )}
          </Form.Item>
          {/* </div> */}
        </div>
      </div>
    );
  };

  const handleChangeScope = async (id: any) => {
    SCOPE_HEAD_ID = id;
    mapData.length = 1; // Remove array without index 1
    const response = await bookingService.findScopeDetail(id);
    if (response.data) {
      let arr: any = [];
      response.data.map((item: any) => {
        const data = {
          lat: item.lat,
          lng: item.lng,
          locationName: `${item.scopeName} : ${item.tambon}`,
          main: false,
        };
        arr.push(data);
      });
      setMapData((prev: any) => [...prev, ...arr]);
    }
  };

  const [formDateTime] = useForm();

  const onFinishDateTime = async (val: any) => {
    // console.log(val);
    let checkBeforeSave = checkBeforeSave_();
    if (checkBeforeSave) {
      // setLoading(true);
      let value = formDateTime.getFieldsValue();
      const values = {
        ...formDateTime.getFieldsValue(),
        stDate: value["stDate"].format("YYYYMMDD"),
        stTime: value["stTime"].format("HHmmss"),
      };
      const { stDate, stTime, routeName, registId, driverId } = values;

      let sumService = services.reduce(
        (prev: any, curr: any) => prev + curr.const || 0,
        0
      );

      let driver = driverList.filter((item: any) => item.id === driverId);
      let truckRegist = truckRegistrationList.filter(
        (item: any) => item.id === registId
      );

      let truckType = truckList.filter((item: any) => item.isSelect === true);
      // console.log(truckType);
      let firstRow = mapData[0];
      let lastRow = mapData[mapData.length - 1];
      if (firstRow.locationName !== lastRow.locationName) {
        mapData.push(firstRow);
      }

      const data = {
        agreementId: "0",
        bookType: "DISTRIBUTE",
        custType: "BB",
        createUserid: userSession.secUserId,
        createUsername: userSession.fullname,
        custUId: userSession.customerUId,
        custName: userSession.fullname,
        distance: "",
        driverUId: driver[0].driverUId,
        driverName: `${driver[0].firstname} ${driver[0].lastname}`,
        discountAmt: 0.0,
        duration: "",
        endDate: "",
        endTime: "",
        inoutStation: "",
        serviceAmt: sumService,
        startDate: stDate,
        startTime: stTime,
        totalAmt: "",
        truckTypeId: truckType[0].id,
        truckUId: truckRegist[0].truckUId,
        registId: truckRegist[0].registId,
        releaseDate: "",
        releaseTime: "",
        paymentTypeId: payment.id,
        savStation: userSession.stationCode,
        status: "05", // 05 = create job
        scopeHeadId: SCOPE_HEAD_ID,
        routes: mapData,
      };
      // console.log("Booking distribute ", data);
      let rspayment = paymentList.filter((item: any) => item.id === payment.id);
      let rstruck = truckList.filter((item: any) => item.id === truck.id);
      let copyData: any = { ...data };
      copyData.bookNo = "";
      copyData.truck = rstruck[0];
      copyData.payment = rspayment[0];
      // console.log(copyData);
      const response = await bookingService.bookBB(data);
      if (response.data.bookNo) {
        // setLoading(false);
        copyData.bookNo = response.data.bookNo;
        setBookHead(copyData);
        setBookRoutes(mapData);
        setDrawerResultVisible(true);
        // setIsBooked(true);
        // message.success(
        //   `จองรถกระจายสำเร็จ เลขที่การจองรถของคุณ ${response.data.bookNo}`,
        //   8
        // );
        // setTimeout(() => {
        //   refreshPage();
        //   init();
        // }, 4000);
      } else {
        console.log(response.data.bookNo);
        setLoading(false);
      }
    }
  };

  const checkBeforeSave_ = () => {
    let value = formDateTime.getFieldsValue();
    const values = {
      ...formDateTime.getFieldsValue(),
      stDate: value["stDate"].format("YYYY/MM/DD"),
    };
    const { stDate } = values;

    // let arrDate = stDate.split("/");
    // const temp = arrDate[0];
    // arrDate[0] = arrDate[2];
    // arrDate[2] = temp;
    // let startDate = arrDate.join("/");

    let now = moment(new Date()).format("YYYYMMDD");
    let date = moment(new Date(`${stDate}`)).format("YYYYMMDD");

    if (Number(date) < Number(now)) {
      message.warning(
        "วันใช้งานต้องเป็นวันปัจจุบัน หรือ วันที่มากกว่าวันปัจจุบันเท่านั้น!",
        6
      );
      return false;
    }
    return true;
  };

  const onFinishFailedDateTime = (val: any) => {
    console.log(val);
  };

  const DateAndTime = () => {
    return (
      <div style={{ marginTop: "1rem" }}>
        <Title level={4} style={{ fontWeight: "bold" }}>
          วันเวลาใช้งาน
        </Title>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Form.Item
            colon={false}
            label="วันที่ใช้งาน"
            name="stDate"
            rules={[{ required: true, message: "กรุณาเลือกวันที่!" }]}
            style={{ marginLeft: 20 }}
          >
            <DatePicker
              format="DD/MM/YYYY"
              disabled={PARAMS.action === "NEW" ? false : true}
            />
          </Form.Item>

          <Form.Item
            colon={false}
            label="เวลาที่ใช้งาน"
            name="stTime"
            rules={[{ required: true, message: "กรุณาเลือกเวลา!" }]}
          >
            <TimePicker
              format="HH:mm:ss"
              disabled={PARAMS.action === "NEW" ? false : true}
            />
          </Form.Item>
        </div>
      </div>
    );
  };

  const TruckType = () => {
    return (
      <div style={{ marginTop: 20 }}>
        <Title level={4} style={{ fontWeight: "bold" }}>
          ประเภทรถบรรทุก
        </Title>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Image width={200} src={truck.img} alt={truck.typeName} />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: 15,
                marginRight: 15,
                cursor: "pointer",
              }}
              onClick={() => setDrawerTruckVisible(true)}
            >
              <div
                style={{ fontWeight: "bold", fontSize: 14, marginBottom: 5 }}
              >
                {truck.typeName}
              </div>
              <div style={{ color: "gray", fontSize: 12 }}>{truck.remark}</div>
            </div>
          </div>
          <div>
            {PARAMS.action === "NEW" ? (
              <IoChevronForward
                size={30}
                style={{ cursor: "pointer" }}
                // onClick={() => toggleModalTruck()}
                onClick={() => setDrawerTruckVisible(true)}
              />
            ) : null}
          </div>
        </div>

        {/* Register and driver */}
        <div style={{ marginTop: 20 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Form.Item
              colon={false}
              label="ทะเบียนรถ"
              name="registId"
              rules={[{ required: true, message: "กรุณาเลือกทะเบียนรถ!" }]}
              style={{ width: "100%", marginLeft: 22 }}
            >
              {PARAMS.action === "NEW" ? (
                <Select
                  placeholder="ทะเบียนรถ"
                  // style={{ width: "100%" }}
                  // onChange={(val: any) => setRegistrationSelect(val)}
                >
                  {truckRegistrationList.map((item: any) => (
                    <Option key={item.id} value={item.id}>
                      {item.registId}
                    </Option>
                  ))}
                </Select>
              ) : (
                <Input readOnly />
              )}
            </Form.Item>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginBottom: 5,
            }}
          >
            <Form.Item
              colon={false}
              label="พนักงานขับรถ"
              name="driverId"
              rules={[{ required: true, message: "กรุณาเลือกพนักงานขับรถ!" }]}
              style={{ width: "100%", marginLeft: 4 }}
            >
              {PARAMS.action === "NEW" ? (
                <Select
                  placeholder="พนักงานขับรถ"
                  // style={{ width: "100%" }}
                  // onChange={(val: any) => setDriverSelect(val)}
                >
                  {driverList.map((item: any) => (
                    <Option
                      key={item.id}
                      value={item.id}
                    >{`${item.firstname} ${item.lastname}`}</Option>
                  ))}
                </Select>
              ) : (
                <Input readOnly />
              )}
            </Form.Item>
          </div>
        </div>
      </div>
    );
  };

  const ServiceAndPayment = () => {
    return (
      <div style={{ marginTop: 20 }}>
        <Title level={4} style={{ fontWeight: "bold" }}>
          บริการเสริมและการชำระเงิน
        </Title>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: 10,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              // cursor: "pointer",
            }}
            // onClick={() => toggleModalServiceAndPayment()}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <IoFilter size={40} color="gray" style={{ marginRight: 10 }} />
              <div>
                <div style={{ fontWeight: "bold", marginBottom: 5 }}>
                  บริการเสริม
                </div>
                {services.length > 0 ? (
                  <div>
                    {services.map((item: any) => (
                      <div key={item.id}>{item.value}</div>
                    ))}
                  </div>
                ) : (
                  <div>ไม่ระบุ</div>
                )}
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              cursor: "pointer",
            }}
            onClick={() => setDrawerServiceAndPaymentVisible(true)}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <IoWallet size={40} color="gray" style={{ marginRight: 10 }} />
              <div>
                <div style={{ fontWeight: "bold", marginBottom: 5 }}>
                  การชำระเงิน
                </div>
                {Object.keys(payment).length === 0 ? (
                  <div>ไม่ระบุ</div>
                ) : (
                  <div>{payment.value}</div>
                )}
              </div>
            </div>
            {PARAMS.action === "NEW" ? (
              <IoChevronForward
                size={30}
                style={{ cursor: "pointer" }}
                // onClick={() => toggleModalServiceAndPayment()}
                onClick={() => setDrawerServiceAndPaymentVisible(true)}
              />
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  const Agreenment = () => {
    return (
      <div style={{ marginTop: 20 }}>
        <Title level={4} style={{ fontWeight: "bold" }}>
          ข้อตกลง
        </Title>
        <div style={{ marginTop: 15 }}>
          <Checkbox
            onChange={onChangeAgreenment}
            // checked={PARAMS.action === "NEW" ? isAccept : true}
            checked={true}
          >
            ข้อตกลง
          </Checkbox>
          <span
            style={{
              color: "blue",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => toggleModalAgreenment()}
          >
            {PARAMS.action === "NEW" ? `อ่านข้อตกลง` : null}
          </span>
        </div>
      </div>
    );
  };

  const onChangeAgreenment = (e: any) => {
    // console.log(e.target.checked);
  };

  const hr = () => {
    return (
      <div
        style={{
          marginTop: "3rem",
          marginBottom: 10,
          height: 1,
          backgroundColor: "lightgray",
        }}
      />
    );
  };

  const confirmBooking = async (id: any) => {
    // console.log("Confirm booking >>>>> ", id);
    let data = {
      id: id,
    };
    const response = await bookingService.confirmBookingDistributeById(data);
    console.log(response);
  };

  const handleCancelBooking = async () => {
    const response = await bookingService.bookingStatus(
      CANCEL_ID,
      "distribute"
    );
    if (response.data) {
      const { status, jobNo } = response.data;
      setJobNo(jobNo);
      if (status === "new") {
        setDrawerCancelVisible(true);
      }
      // else if(status === "CC"){
      //   message.warning(`การจองนี้ถูกยกเลิกอยู่แล้ว!`, 4);
      // }
      else {
        message.warning(`การจองนี้ไม่สามารถยกเลิกได้!`, 4);
      }
    }
  };

  const onFinish = async (value: any) => {
    let data = {
      bookNo: PARAMS.bookNo,
      cancelRemark: value.text,
      cancelDate: GetCurrentDate(),
      cancelTime: GetCurrentTime(),
      cancelUserId: userSession.secUserId,
      cancelUserName: userSession.fullname,
      createUserid: userSession.secUserId,
      createUsername: userSession.fullname,
      cancelType: "distribute",
      id: CANCEL_ID,
      jobNo,
      updateUserid: userSession.secUserId,
      updateUsername: userSession.fullname,
      savStation: userSession.stationCode,
    };
    // console.log(data);
    const response = await bookingService.cancelBooking(data);
    if (response.data) {
      message.success("ยกเลิกสำเร็จ");
      history.goBack();
    } else {
      console.error(response.data);
    }
  };

  const onFinishFailed = (error: any) => {
    console.log(error);
  };

  const RDrawerCancel_ = () => {
    return (
      <RDrawer
        placement="right"
        title="ยกเลิกการจอง"
        visible={drawerCancelVisible}
        setVisible={setDrawerCancelVisible}
        width={400}
      >
        <div>
          <Form
            name="CancelForm"
            form={CancelForm}
            // labelCol={{ span: 8 }}
            // wrapperCol={{ span: 16 }}
            // initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              name="text"
              rules={[{ required: true, message: "กรุณาใส่หมายเหตุ!" }]}
            >
              <Input.TextArea placeholder="หมายเหตุ" />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Space>
                <Button
                  type="default"
                  htmlType="button"
                  onClick={() => setDrawerCancelVisible(false)}
                >
                  ยกเลิก
                </Button>
                <Button type="primary" htmlType="submit">
                  ตกลง
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </div>
      </RDrawer>
    );
  };

  const RDrawerTruck = () => {
    return (
      <RDrawer
        placement="right"
        title="ประเภทรถบรรทุก"
        visible={drawerTruckVisible}
        setVisible={setDrawerTruckVisible}
        width={500}
      >
        {truckList.map((item: any, index: any) => (
          <div
            key={item.id}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: 15,
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src={item.img}
                alt={item.typeName}
                width={80}
                style={{ marginRight: 10 }}
              />
              <div>
                <div
                  style={{ fontWeight: "bold", fontSize: 14, marginBottom: 2 }}
                >
                  {item.typeName}
                </div>
                <div style={{ color: "grey", fontSize: 12 }}>{item.remark}</div>
              </div>
            </div>
            <div>
              <IoCheckmarkCircle
                size={20}
                style={{ cursor: "pointer" }}
                color={item.isSelect ? "green" : "#EBEDEF"}
                onClick={() => handleSelectTruck(item.id)}
              />
            </div>
          </div>
        ))}
      </RDrawer>
    );
  };

  const RDrawerService = () => {
    return (
      <RDrawer
        placement="right"
        title="บริการเสริมและการชำระเงิน"
        visible={drawerServiceAndPaymentVisible}
        setVisible={setDrawerServiceAndPaymentVisible}
        width={300}
      >
        {/* {serviceList.map((item: any) => (
          <div
            key={item.id}
            style={{
              marginBottom: 10,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div>
                <IoChevronForward size={20} color="gray" />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: 15,
                  marginRight: 10,
                }}
              >
                <div> {item.value}</div>
              </div>
            </div>
            <div>
              <IoCheckmarkCircle
                size={20}
                style={{ cursor: "pointer" }}
                color={item.isSelect ? "green" : "#EBEDEF"}
                onClick={() => handleSelectService(item.id)}
              />
            </div>
          </div>
        ))}
        {hr()} */}
        {paymentList.map((item: any) => (
          <div
            key={item.id}
            style={{
              marginBottom: 5,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {/* <div>
                <IoChevronForward size={20} color="gray" />
              </div> */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  // marginLeft: 15,
                  marginRight: 10,
                }}
              >
                <div> {item.paymentName}</div>
              </div>
            </div>
            <div>
              <IoCheckmarkCircle
                size={20}
                style={{ cursor: "pointer" }}
                color={item.isSelect ? "green" : "#EBEDEF"}
                // onClick={() => handleSelectPayment(item.id)}
              />
            </div>
          </div>
        ))}
      </RDrawer>
    );
  };

  const RDrawerResult = () => {
    return (
      <RDrawer
        placement="right"
        title="รายละเอียดการจอง"
        visible={drawerResultVisible}
        setVisible={setDrawerResultVisible}
        width={400}
      >
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: 20,
            }}
          >
            <div style={{ fontWeight: "bold", fontSize: 18 }}>เลขที่การจอง</div>
            <div style={{ fontWeight: "bold", fontSize: 18 }}>
              {bookHead.bookNo}
            </div>
          </div>
          <div style={{ marginBottom: 10 }}>
            <div style={{ fontWeight: "bold" }}>จุดรับ-ส่ง</div>
            {bookRoutes.map((item: any, idx: any) => {
              return (
                <div
                  key={idx}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div style={{ marginRight: 10 }}>จุดที่ {idx + 1}</div>
                  <div>{item.locationName}</div>
                </div>
              );
            })}
          </div>
          <div style={{ marginBottom: 10 }}>
            <div style={{ fontWeight: "bold" }}>วันเวลา/ใช้งาน</div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 5,
              }}
            >
              <div style={{ marginRight: 10 }}>
                {`วันที่ใช้งาน ${
                  bookHead.startDate
                    ? moment(
                        new Date(StringDate(bookHead.startDate, "EN", "/"))
                      ).format("L")
                    : ""
                }`}
              </div>
              <div>{`เวลาที่ใช้งาน ${
                bookHead.startTime ? StringTime(bookHead.startTime) : ""
              }`}</div>
            </div>
          </div>

          <div style={{ marginBottom: 10 }}>
            <div style={{ fontWeight: "bold" }}>ประเภทรถ</div>
            <div style={{ marginRight: 10 }}>
              {bookHead.truck ? bookHead.truck.typeName : null}
            </div>
            <div>{bookHead.truck ? bookHead.truck.remark : null}</div>
          </div>

          <div style={{ marginBottom: 10 }}>
            <div style={{ fontWeight: "bold" }}>พนักงานขับรถ</div>
            <div>{`${bookHead.driverName}  ทะเบียนรถ ${bookHead.registId}`}</div>
          </div>

          <div style={{ marginBottom: 10 }}>
            <div style={{ fontWeight: "bold" }}>การชำระเงิน</div>
            <div>{bookHead.payment ? bookHead.payment.paymentName : null}</div>
          </div>
        </div>
        <div style={{ marginTop: 30, textAlign: "center" }}>
          <Button
            type="primary"
            onClick={() => {
              refreshPage();
              init();
            }}
          >
            กลับ
          </Button>
        </div>
      </RDrawer>
    );
  };

  const confirm = () => {
    handleCancelBooking();
  };

  const cancel = () => {};

  return (
    <>
      {loading ? (
        <div>
          <RSpin size="large" />
        </div>
      ) : (
        <div style={{ height: "100%", width: "100%" }}>
          {agreementModal()}
          {RDrawerCancel_()}
          {RDrawerTruck()}
          {RDrawerService()}
          {RDrawerResult()}
          <Form
            name="formDateTime"
            form={formDateTime}
            // layout="horizontal"
            // {...formItemLayout}
            // labelCol={{ span: 8 }}
            // wrapperCol={{ span: 8 }}
            // initialValues={{ remember: true }}
            onFinish={onFinishDateTime}
            onFinishFailed={onFinishFailedDateTime}
            autoComplete="off"
          >
            <Row style={{ height: "100%" }}>
              <Col
                span={9}
                style={{
                  backgroundColor: "#fff",
                  borderRadius: 5,
                  padding: 15,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Title level={4} style={{ fontWeight: "bold" }}>
                    รถวิ่งกระจาย
                  </Title>

                  <div>
                    <Space>
                      <Button
                        type="primary"
                        // style={{ marginLeft: 5 }}
                        onClick={() => history.goBack()}
                      >
                        กลับ
                      </Button>
                      {PARAMS.action !== "NEW" && (
                        <Popconfirm
                          title="คุณต้องการยกเลิกการจองนี้ใช่หรือไม่ !"
                          onConfirm={confirm}
                          onCancel={cancel}
                          okText="ใช่"
                          cancelText="ไม่"
                        >
                          {PARAMS.bookType !== "bookingDistribute" && (
                            <Button type="default">ยกเลิก</Button>
                          )}
                        </Popconfirm>
                      )}
                    </Space>
                  </div>
                </div>

                {/* Line haul */}
                <Title
                  level={4}
                  style={{ fontWeight: "bold", marginBottom: 10 }}
                >
                  จุดรับ-ส่งสินค้า
                </Title>

                {/* Root and destination */}
                {RootAndDestination()}

                {hr()}

                {/* Date time */}
                {DateAndTime()}

                {hr()}

                {/* Truck type */}
                {TruckType()}

                {hr()}

                {/* Service ro add-on */}
                {ServiceAndPayment()}

                {hr()}

                {/* Agreenment */}
                {Agreenment()}

                <div style={{ textAlign: "center" }}>
                  {PARAMS.action === "NEW" ? (
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        style={{ marginTop: 50 }}
                        // onClick={() => handleBooking()}
                      >
                        บันทึกการจอง
                      </Button>
                    </Form.Item>
                  ) : (
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="button"
                        style={{ marginTop: 30 }}
                        onClick={() => {
                          if (PARAMS.from === "dataDistribute") {
                            history.push({
                              pathname: "/app/booking/dataDistribute",
                              state: {},
                            });
                          } else {
                            history.push({
                              pathname: "/app/booking/dataDistribute",
                              state: { id: "2" },
                            });
                          }
                        }}
                      >
                        กลับ
                      </Button>
                    </Form.Item>
                  )}
                </div>
              </Col>
              <Col span={15}>
                <RMap
                  centerCoordinate={centerCoordinate}
                  data={mapData}
                  mapRef={mapRef}
                />
              </Col>
            </Row>
          </Form>
        </div>
      )}
    </>
  );
});

const BookingDistribute: React.FC = (props: any) => {
  const { state } = props.location;
  if (state !== undefined) {
    PARAMS = state;
    CANCEL_ID = state.id;
  } else if (state === undefined) {
    PARAMS = {
      bookType: "DISTRIBUTE",
      action: "NEW",
      id: null,
    };
  }
  const MapLoader: any = withScriptjs(Wrapper);
  return (
    <div>
      <MapLoader
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCY8tssCzJWXdG7BnWMr5EmiljzHL4zp0o&v=3.exp&libraries=geometry,drawing,places"
        loadingElement={<div style={{ height: `100%` }} />}
      />
    </div>
  );
};

export default BookingDistribute;
