import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Form,
  Row,
  Input,
  Modal,
  Typography,
  Table,
  Tabs,
  Select,
  Spin,
} from "antd";
import { Link, useHistory } from "react-router-dom";
import bookingService from "src/services/bookingService";
import { getUserSession } from "src/store/authen/action";

const TableData: React.FC<{
  data: any;
  remove?: any;
  callBack?: any;
}> = (props: any) => {
  const { callBack, data, remove } = props;
  const columns = [
    {
      width: 50,
      title: "ลำดับ",
      dataIndex: "seq",
      key: "seq",
    },
    {
      title: "ชื่อ - สกุล",
      dataIndex: "secFullname",
      key: "secFullname",
      render: (text: any, record: any, index: any) => {
        return (
          <div
            style={{ color: "#5DADE2", cursor: "pointer" }}
            onClick={() => callBack(record)}
          >
            {text}
          </div>
        );
      },
    },
    {
      title: "อีเมล์",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "ชื่อผู้ใช้",
      dataIndex: "username",
      key: "username",
    },
    {
      width: 120,
      title: "บทบาท",
      dataIndex: "role",
      key: "role",
    },
    // {
    //   title: "ลบ",
    //   key: "action",
    //   render: (text: any, recode: any) => (
    //     <Space size="middle">
    //       <Popconfirm
    //         title={`คุณต้องการลบ ${text.subDistrict} นี้ใช่ หรือ ไม่`}
    //         onConfirm={(e) => confirm(text.seq)}
    //         onCancel={(e) => cancel(e)}
    //         okText="ใช่"
    //         cancelText="ยกเลิก"
    //       >
    //         <IoCloseCircle
    //           size={20}
    //           color="#ff0000"
    //           style={{ cursor: "pointer" }}
    //         />
    //       </Popconfirm>
    //     </Space>
    //   ),
    // },
  ];

  const confirm = async (id: any) => {
    remove(id);
  };

  const cancel = (e: any) => {
    console.log("Cancel");
  };

  return (
    <>
      {data.length > 0 ? (
        <Table
          dataSource={data}
          columns={columns}
          size="small"
          rowKey={(obj: any) => obj.seq}
        />
      ) : (
        <Table
          // dataSource={null}
          columns={columns}
          size="small"
          // rowKey={(obj: any) => obj.id}
        />
      )}
    </>
  );
};

const CreateUser: React.FC = (props: any) => {
  const [visible, setVisible] = useState(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [staffList, setStaffList] = useState<any>([]);

  useEffect(() => {
    if (!loaded) {
      init();
      setLoaded(true);
    }
  }, []);

  const init = () => {
    setLoading(true);
    const { customerUId, stationId } = getUserSession();
    loadStaffInStation(customerUId, stationId);
    setLoading(false);
  };

  const loadStaffInStation = async (custUId: any, stationId: any) => {
    const response = await bookingService.loadStaffInStation(
      custUId,
      stationId
    );
    if (response.data.length > 0) {
      for (let i = 0; i < response.data.length; i++) {
        response.data[i].seq = i + 1;
      }
      setStaffList(response.data);
    }
  };

  const [form] = Form.useForm();

  const { Search } = Input;

  const { Title } = Typography;

  const history = useHistory();

  const onCancel = () => {
    setVisible(false);
  };

  const callback = (key: any) => {
    console.log(key);
  };

  const onSearch = (value: any) => {
    console.log(value);
  };

  const handleChange = (data: any) => {
    console.log(data);
  };

  const onCreate = async (values: any) => {
    setLoading(true);
    console.log("Received values of form: ", values);
    const { email, role } = values;
    const data = {
      mail: email,
      role,
      url: `http://localhost:3000/createUserRole?email=${email}&role=${role}`,
    };
    const response = await bookingService.sendEmailWithLink(data);
    // console.log("Send emai for invitation >>>>> ", response.data);
    const { success } = response.data;
    if (success) {
      setVisible(false);
      setLoading(false);
    } else {
    }
  };

  const handleEdit = (record: any) => {
    const { secUserId } = record;
    history.push({
      pathname: "/app/booking/addUserInStation",
      state: { id: secUserId, ACTION: "EDIT" },
    });
  };

  return (
    <div style={{ height: "100%" }}>
      {loading ? (
        <Row justify="center" style={{ marginTop: "20rem" }}>
          <Col span={10}></Col>
          <Col span={4}>
            <div style={{ textAlign: "center" }}>
              <Spin size="default" tip="Loading..." />
            </div>
          </Col>
          <Col span={10}></Col>
        </Row>
      ) : (
        <div
          style={{
            backgroundColor: "#fff",
            padding: 15,
            borderRadius: 5,
            height: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Title level={4} style={{ fontWeight: "bold" }}>
              กำหนดผู้ใช้
            </Title>
            <Button
              type="primary"
              onClick={() =>
                history.push({
                  pathname: "/app/booking/addUserInStation",
                  state: { ACTION: "NEW" },
                })
              }
            >
              เพิ่ม
            </Button>
          </div>

          <div style={{ marginTop: "2rem" }}>
            <div style={{ textAlign: "center" }}>
              <Search
                placeholder="ค้นหา"
                onSearch={onSearch}
                style={{ width: 200 }}
              />
            </div>

            <div style={{ marginTop: "2rem" }}>
              <TableData data={staffList} callBack={handleEdit} />
            </div>
            <div style={{ marginTop: "1rem" }}></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateUser;
