import React from "react";
import classes from "./SideNavModule.module.css";
import {
  BarChart,
  Copy,
  Command,
  Home,
  FilePlus,
  Layers,
  LogOut,
  Menu,
  Truck,
  Users,
} from "react-feather";
import { clearSessionUser } from "src/store/authen/action";
import profile from "../assets/images/pexels-mikhail-nilov-8297156.jpeg";
import { useHistory } from "react-router";
import { useAppDispatch, useAppSelector } from "src/store";

interface IProps {
  onSelectModule?: (code: string) => void;
  showMenu: () => void;
  menuModules: { id: number; name: string; icon: string; code: string }[];
}

const iconRecords: Record<string, any> = {
  BlockOutlined: BarChart,
  BookOutlined: Command,
  FeatherHome: Home,
  FeatherFilePlus: FilePlus,
  FeatherTruck: Truck,
  FeatherUsers: Users,
  TableOutlined: Layers,
  TeamOutlined: Copy,
};

const SideNavModule: React.FC<IProps> = (props) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { sessionUser } = useAppSelector((state) => state.auth);

  const getIcon = (item: any): any => {
    const Component = iconRecords[item.icon];
    return <Component strokeWidth="1" color="#fff" size={20} />;
  };

  const handleLogout = () => {
    dispatch(clearSessionUser());
    history.replace("/login");
  };

  return (
    <div className={classes.sideNavModule} style={{ height: "100%" }}>
      <div className={classes.sideNavModuleMenuWrapper}>
        <div className={classes.sideNavModuleMenuIcon}>
          <Menu
            strokeWidth="2"
            size={18}
            color="#3d87db"
            onClick={() => props.showMenu()}
          />
        </div>
      </div>
      <div style={{ flex: 1 }}>
        {props.menuModules.map((item) => (
          <div
            key={item.id}
            className={classes.sideNavModuleIcon}
            onClick={() =>
              props.onSelectModule && props.onSelectModule(item.code)
            }
          >
            {getIcon(item)}
          </div>
        ))}
      </div>
      <div className={classes.sideNavModuleFooterWrapper}>
        <div style={{ borderRadius: "50%" }}>
          <img
            src={profile}
            width="40px"
            height="40px"
            style={{
              borderRadius: "50%",
              objectFit: "cover",
              marginBottom: "20px",
            }}
          />
        </div>
        <div className={classes.sideNavModuleIcon}>
          <LogOut
            strokeWidth="1"
            color="#fff"
            size={20}
            onClick={() => handleLogout()}
          />
        </div>
      </div>
    </div>
  );
};

export default SideNavModule;
