import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Alert,
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Space,
} from "antd";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import { useAppDispatch, useAppSelector } from "src/store";
import {
  setSessionUser,
  getSessionUserFromLocal,
} from "src/store/authen/action";
import "./Login.css";
import bookingService from "src/services/bookingService";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

function validate(username: string, password: string): string {
  if (!(username === "admin" && password === "adminadmin")) {
    // return "Incorrect username or password";
    return "ชือผู้ใช้ หรือ รหัสผ่านไม่ถูกต้อง !";
  }

  return "";
}

const LoginForm = () => {
  const [error, setError] = useState<string>("");
  const history = useHistory();
  const dispatch = useAppDispatch();

  useEffect(() => {}, []);

  const onFinish = async (values: any) => {
    const { username, password } = values;
    const custStationId = 1;
    let response = await bookingService.loginBB(
      username,
      password,
      custStationId
    );

    if (response.data.length === 1) {
      dispatchData(response.data);
      history.replace("/app");
    } else if (response.data.length > 1) {
      history.replace({
        pathname: "/selectStation",
        state: { data: response.data },
      });
    } else {
      const error = validate(username, password);
      setError(error);
    }
  };

  const dispatchData = (data: any) => {
    const datas: any = {
      customerId: data[0].custId,
      customerUId: data[0].custUId,
      fullname: data[0].secUserFullname,
      lat: data[0].lat,
      lng: data[0].lng,
      role: data[0].secUserBusinessRole,
      secUserId: data[0].secUserId,
      stationId: data[0].custStationId,
      stationCode: data[0].custStationCode,
      stationName: data[0].custStationName,
      // username: data[0].secUserUsername,
      // email: data[0].secUserEmail,
    };
    dispatch(setSessionUser(datas));
  };

  const onFinishFailed = (errorInfo: ValidateErrorEntity<any>) => {
    // console.log("error x", errorInfo);
  };

  return (
    <>
      <Row justify="center" align="middle" style={{ height: "100vh" }}>
        <Col span={8}>
          <Card title="เข้าสู่ระบบ" className="login-form">
            {error && (
              <Alert
                message="ผิดพลาด!"
                description={error}
                type="error"
                closable
                // onClose={onClose}
              />
            )}
            <br />
            <Form
              {...layout}
              name="login"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                label="ชื่อผู้ใช้"
                name="username"
                rules={[{ required: true, message: "กรุณาระบุชื่อผู้ใช้ !" }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="รหัสผ่าน"
                name="password"
                rules={[{ required: true, message: "กรุณาระบุรหัสผ่าน !" }]}
              >
                <Input.Password />
              </Form.Item>

              {/* <Form.Item
                {...tailLayout}
                name="remember"
                valuePropName="checked"
              >
                <Checkbox>Remember me</Checkbox>
              </Form.Item> */}

              <Form.Item {...tailLayout}>
                <Space>
                  <Button type="primary" htmlType="submit">
                    เข้าสู่ระบบ
                  </Button>

                  <Button
                    type="default"
                    htmlType="button"
                    onClick={() => {
                      history.push("/bookingQuickListLineHall");
                    }}
                  >
                    โปรแกรมจองรถ
                  </Button>

                  {/* <Button
                    type="default"
                    htmlType="button"
                    // onClick={() => {
                    //   history.push("/bookingList");
                    // }}
                  >
                    จองรถกระจาย
                  </Button> */}
                </Space>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default LoginForm;
