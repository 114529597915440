import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Row,
  Select,
  Space,
  Switch,
  Typography,
} from "antd";
import {
  DirectionsRenderer,
  GoogleMap,
  InfoWindow,
  Marker,
  withScriptjs,
  withGoogleMap,
} from "react-google-maps";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng,
} from "react-google-places-autocomplete";
import { getUserSession } from "src/store/authen/action";
import bookingService from "src/services/bookingService";
import { generateOrderNumber } from "src/utils";
import { useAppSelector } from "src/store";

let ACTION = "";
let ID = "";

const RAutocomplete = (props: any) => {
  const { autoValue, setAutoValue } = props;
  return (
    <GooglePlacesAutocomplete
      // apiKey={MAP_API}
      apiOptions={{ language: "th", region: "th" }}
      debounce={300}
      onLoadFailed={(err) => message.warning(`${err}`, 10)}
      selectProps={{
        placeholder: "ค้นหาตำแหน่งสถานี",
        autoValue,
        onChange: setAutoValue,
      }}
    />
  );
};

const RMap: React.FC<{
  centerCoordinate: any;
  data?: any;
  directions?: any;
  setIsPin?: any;
  setData?: any;
  stationName?: any;
}> = (props: any) => {
  // console.log(props);
  const { centerCoordinate, data, setIsPin, setData, stationName } = props;

  const mapRef = useRef(null);

  const handleOnClickMap = async (e: any) => {
    if (stationName === "") {
      message.warning("กรุณาระบุชื่อสถานีก่อนกำหนดตำแหน่งสถานี!");
      return;
    }
    setIsPin(true);
    let lat = e.latLng.lat();
    let lng = e.latLng.lng();
    const data = {
      id: generateOrderNumber(),
      description: null,
      lat,
      lng,
      locationName: stationName,
      place_id: null,
      secondary_text: null,
      reference: null,
      terms: [],
    };
    setData([data]);
  };
  const Map: any = withGoogleMap((props) => (
    <GoogleMap
      ref={(map) => {
        const bounds = new window.google.maps.LatLngBounds();
        data &&
          data.map((item: any) => {
            bounds.extend(new window.google.maps.LatLng(item.lat, item.lng));
          });
        if (data.length > 0) {
          map && map.fitBounds(bounds);
        }
        // props.onMapMounted(map);
      }}
      defaultCenter={centerCoordinate}
      defaultZoom={15}
      onClick={handleOnClickMap}
    >
      {data &&
        data.map((item: any) => {
          if (item.lat === null && item.lng === null) {
            return;
          }
          const { terms } = item;
          // const bonds = new google.maps.LatLngBounds();
          // bonds.extend({ lat: Number(item.lat), lng: Number(item.lng) })

          return (
            <Marker
              key={item.id}
              position={{ lat: Number(item.lat), lng: Number(item.lng) }}
              // onMouseOver={(e) => console.log(e)}
            >
              <InfoWindow>
                <div>{item.locationName}</div>
              </InfoWindow>
            </Marker>
          );
        })}

      {/* {directions && (
        <DirectionsRenderer
          directions={directions}
          options={{
            polylineOptions: {
              geodesic: true,
              strokeOpacity: 1,
              strokeColor: "#0000ff",
              strokeWeight: 3,
            },
          }}
        />
      )} */}
    </GoogleMap>
  ));
  return (
    <Map
      ref={mapRef}
      containerElement={<div style={{ height: `690px`, width: "850px" }} />}
      mapElement={<div style={{ height: `100%` }} />}
    />
  );
};

const Wrapper: any = () => {
  // const { sessionUser } = useAppSelector((state) => state.auth);
  // const {
  //   customerId,
  //   fullname,
  //   roles,
  //   secUserId,
  //   stationId,
  //   stationName: stationName_,
  // } = sessionUser;
  const [autoValue, setAutoValue] = useState<any>(null);
  const [centerCoordinate, setCenterCoordinate] = useState<any>({
    lat: Number(18.80753508304137),
    lng: Number(99.01845056588674),
  });
  const [directions, setDirections] = useState<any>();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [location, setLocation] = useState<any>({});
  const [isPin, setIsPin] = useState<boolean>(false);
  const [isUse, setIsUse] = useState<boolean>(true);
  const [provinceList, setProvinceList] = useState<any>([]);
  const [districtList, setDistrictList] = useState<any>([]); // Amphoe
  const [mapData, setMapData] = useState<any>([]);
  const [subDistrictList, setSubDistrictList] = useState<any>([]); // Tambon
  const [stationName, setStationName] = useState<any>("");
  const [userSession, setUserSession] = useState<any>(getUserSession());

  const history = useHistory();

  const [form] = Form.useForm();

  const { Title } = Typography;
  const { Option } = Select;

  useEffect(() => {
    if (!loaded) {
      init();
      setLoaded(true);
    }
  }, []);

  const init = async () => {
    setLoading(true);
    if (ACTION === "create") {
      findProvinceAll();
    } else if (ACTION === "edit") {
      // console.log("EDIT ID : ", ID);
      const response = await bookingService.findStationById(ID);
      if (response.data) {
        // console.log(response.data);
        const {
          id,
          address,
          addrPath,
          amphoe,
          code,
          lat,
          lng,
          name,
          province,
          remark,
          tambon,
          tel,
          useFlg,
          zipcode,
        } = response.data;
        setIsUse(useFlg === 0 ? true : false);
        let provinceId = addrPath.substring(0, 2);
        let districtId = addrPath.substring(3, 7);
        let subDistrictId = addrPath.substring(8);
        form.setFieldsValue({
          district: amphoe,
          province,
          remark,
          subDistrict: tambon,
          stationAddress: address,
          stationCode: code,
          stationName: name,
          tel,
          zipcode,
        });
        setLocation({
          provinceCode: addrPath.substring(0, 2),
        });
        findProvinceAll();
        findDistrictAll(provinceId);
        findSubDistrictAll(provinceId, districtId);
        setStationName(name);
        setIsPin(true);
        const data = {
          id,
          lat,
          lng,
          locationName: name,
          term: [],
        };
        setMapData([data]);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      if (autoValue) {
        const { value } = autoValue;
        const {
          description,
          place_id,
          reference,
          structured_formatting,
          terms,
        } = value;
        const { main_text, secondary_text } = structured_formatting;
        const placeId = value.place_id;
        const res = await geocodeByPlaceId(placeId);
        const lat_lnt = await getLatLng(res[0]);
        const { lat, lng } = lat_lnt;
        form.setFieldsValue({
          stationName: main_text,
        });
        setStationName(main_text);
        setIsPin(true);
        const data = {
          id: generateOrderNumber(),
          description,
          lat,
          lng,
          locationName: main_text,
          place_id,
          secondary_text,
          reference,
          terms,
        };
        // console.log(data);
        const newData = [data];
        setMapData(newData);
      }
    })();
  }, [autoValue]);

  const findProvinceAll = async () => {
    const response = await bookingService.findProvinceAll();
    if (response.data) {
      setProvinceList(response.data);
    }
  };

  const findDistrictAll = async (provincCode: any) => {
    const response = await bookingService.findDistrict(provincCode);
    if (response.data) {
      setDistrictList(response.data);
    }
  };

  const findSubDistrictAll = async (provinceCode: any, districtCode: any) => {
    const response = await bookingService.findSubDistrict(
      provinceCode,
      districtCode
    );
    if (response.data) {
      setSubDistrictList(response.data);
    }
  };

  const handleChangeProvince = async (val: any) => {
    setLocation({
      provinceCode: val,
    });
    const response = await bookingService.findDistrict(val);
    if (response.data) {
      setDistrictList(response.data);
    }
  };

  const handleChangeDistrict = async (districtCode: any) => {
    let { provinceCode } = location;
    const response = await bookingService.findSubDistrict(
      provinceCode,
      districtCode
    );
    if (response.data) {
      setSubDistrictList(response.data);
    }
  };

  const handleChangeSubDistrict = async (tambonCode: any) => {
    const response = await bookingService.findZipCodeByTambonCode(tambonCode);
    if (response.data) {
      form.setFieldsValue({
        zipcode: response.data.zipCode,
      });
    }
  };

  const onFinish = async (values: any) => {
    if (isPin) {
      const {
        stationCode,
        stationName,
        stationAddress,
        province,
        district,
        subDistrict,
        zipcode,
        tel,
        remark,
      } = values;
      const { lat, lng } = mapData[0];
      const addrPath = getAddrPath(province, district, subDistrict);
      let data = {
        id: ACTION === "create" ? null : ID,
        address: stationAddress,
        addrPath,
        createUserid: userSession.secUserId,
        createUsername: userSession.fullname,
        custId: userSession.customerId,
        code: stationCode,
        lat,
        lng,
        locationCode: handleSubDistrict(subDistrict),
        name: stationName,
        remark: remark === "" ? "" : remark,
        savStation: userSession.stationCode,
        tel,
        updateUserid: userSession.secUserId,
        updateUsername: userSession.fullname,
        useFlg: isUse === true ? 0 : -1,
        zipcode,
      };
      // console.log(data);
      if (ACTION === "create") {
        const response = await bookingService.saveStation(data);
        if (response.data) {
          message.success("สร้างสถานีใหม่สำเร็จ", 5);
          setTimeout(() => {
            history.goBack();
          }, 6000);
        } else {
          console.log(response.data);
        }
      } else if (ACTION === "edit") {
        const response = await bookingService.updateStation(data);
        if (response.data) {
          message.success("แก้ใขข้อมูลสถานีสำเร็จ");
          setTimeout(() => {
            history.goBack();
          }, 3000);
        } else {
          console.log(response.data);
        }
      }
    } else {
      message.warning("กรุณากำหนดตำแหน่งสถานี!");
    }
  };

  const handleSubDistrict = (subDistrict: any) => {
    if (ACTION === "create") {
      return subDistrict;
    } else if (ACTION === "edit") {
      if (isNaN(Number(subDistrict.charAt(0)))) {
        let result = subDistrictList.filter(
          (item: any) => item.tambon === subDistrict
        );
        // console.log(result[0].tambonCode);
        return result[0].tambonCode;
      } else {
        return subDistrict;
      }
    }
  };

  const getAddrPath = (...val: any) => {
    if (ACTION === "create") {
      return `${val[0]}/${val[1]}/${val[2]}`;
    } else if (ACTION === "edit") {
      let provinceCode = "";
      let districtCode = "";
      let subDistrictCode = "";

      if (isNaN(Number(val[0].charAt(0)))) {
        let result = provinceList.filter(
          (item: any) => item.province === val[0]
        );
        provinceCode = result[0].provinceCode;
      } else {
        provinceCode = val[0].toString();
      }

      if (isNaN(Number(val[1].charAt(0)))) {
        let result = districtList.filter((item: any) => item.amphoe === val[1]);
        districtCode = result[0].amphoeCode;
      } else {
        districtCode = val[1].toString();
      }

      if (isNaN(Number(val[2].charAt(0)))) {
        let result = subDistrictList.filter(
          (item: any) => item.tambon === val[2]
        );
        subDistrictCode = result[0].tambonCode;
      } else {
        subDistrictCode = val[2].toString();
      }
      return `${provinceCode}/${districtCode}/${subDistrictCode}`;
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
    message.warning("กรุณาระบุข้อมูลให้สมบูรณ์!");
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Row style={{ height: "100%" }}>
        <Col
          span={7}
          style={{ backgroundColor: "#fff", padding: 10, borderRadius: 5 }}
        >
          <Space direction="vertical" style={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>
                <Title level={4} style={{ fontWeight: "bold" }}>
                  {ACTION === "create" ? "กำหนดสถานี" : "แก้ไขสถานี"}
                </Title>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Switch
                  checked={isUse}
                  onChange={(e) => setIsUse(e)}
                  size="default"
                  // checkedChildren="开启"
                  // unCheckedChildren="关闭"
                />
                <div style={{ marginLeft: 5, fontWeight: "bold" }}>ใช้งาน</div>
              </div>
            </div>
            <RAutocomplete autoValue={autoValue} setAutoValue={setAutoValue} />
            <div style={{ marginTop: 10 }}>
              <Form
                form={form}
                name="createStation"
                labelCol={{}}
                wrapperCol={{}}
                // initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                {/* <div style={{ fontWeight: "bold" }}>รหัสสถานี</div> */}
                <Form.Item
                  // label="Username"
                  name="stationCode"
                  rules={[{ required: true, message: "กรุณาระบุ รหัสสถานี!" }]}
                >
                  <Input placeholder="รหัสสถานี" />
                </Form.Item>

                {/* <div style={{ fontWeight: "bold" }}>ชื่อสถานี</div> */}
                <Form.Item
                  // label="Password"
                  name="stationName"
                  rules={[{ required: true, message: "กรุณาระบุ ชื่อสถานี!" }]}
                >
                  <Input placeholder="ชื่อสถานี" />
                </Form.Item>

                {/* <div style={{ fontWeight: "bold" }}>จังหวัด</div> */}
                <Form.Item
                  // label="Password"
                  name="province"
                  rules={[{ required: true, message: "กรุณาระบุ จังหวัด!" }]}
                >
                  {/* <Input placeholder="จังหวัด" /> */}
                  <Select placeholder="จังหวัด" onChange={handleChangeProvince}>
                    {provinceList.map((item: any, index: number) => (
                      <Option key={index} value={item.provinceCode}>
                        {item.province}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                {/* <div style={{ fontWeight: "bold" }}>อำเภอ</div> */}
                <Form.Item
                  // label="Password"
                  name="district"
                  rules={[{ required: true, message: "กรุณาระบุ อำเภอ!" }]}
                >
                  <Select placeholder="อำเภอ" onChange={handleChangeDistrict}>
                    {districtList.map((item: any, index: number) => (
                      <Option key={index} value={item.amphoeCode}>
                        {item.amphoe}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                {/* <div style={{ fontWeight: "bold" }}>ตำบล</div> */}
                <Form.Item
                  // label="Password"
                  name="subDistrict"
                  rules={[{ required: true, message: "กรุณาระบุ ตำบล!" }]}
                >
                  <Select placeholder="ตำบล" onChange={handleChangeSubDistrict}>
                    {subDistrictList.map((item: any, index: number) => (
                      <Option key={index} value={item.tambonCode}>
                        {item.tambon}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                {/* <div style={{ fontWeight: "bold" }}>ที่อยู่สถานี</div> */}
                <Form.Item
                  // label="Address"
                  name="stationAddress"
                  rules={[
                    { required: true, message: "กรุณาระบุ ที่อยู่สถานี!" },
                  ]}
                >
                  <Input
                    placeholder="ที่อยู่"
                    onChange={() =>
                      setStationName(form.getFieldValue("stationName"))
                    }
                  />
                </Form.Item>

                {/* <div style={{ fontWeight: "bold" }}>รหัสไปรษณีย์</div> */}
                <Form.Item
                  // label="Zipcode"
                  name="zipcode"
                  rules={[
                    { required: true, message: "กรุณาระบุ รหัสไปรษณีย์!" },
                  ]}
                >
                  <Input placeholder="รหัสไปรษณีย์" />
                </Form.Item>

                <Form.Item
                  // label="Telphone"
                  name="tel"
                  rules={[{ required: true, message: "กรุณาระบุ เบอร์โทร!" }]}
                >
                  <Input type="number" placeholder="เบอร์โทร" maxLength={10} />
                </Form.Item>

                <Form.Item
                  // label="Remark"
                  name="remark"
                  // rules={[
                  //   { required: true, message: "กรุณาระบุ รหัสไปรษณีย์!" },
                  // ]}
                >
                  <Input.TextArea placeholder="หมายเหตุ" />
                </Form.Item>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: 30,
                  }}
                >
                  <Space>
                    <Form.Item>
                      <Button
                        htmlType="button"
                        onClick={() => history.goBack()}
                      >
                        กลับ
                      </Button>
                    </Form.Item>
                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        บันทึก
                      </Button>
                    </Form.Item>
                  </Space>
                </div>
              </Form>
            </div>
          </Space>
        </Col>
        <Col span={17}>
          <RMap
            centerCoordinate={centerCoordinate}
            data={mapData}
            setIsPin={setIsPin}
            setData={setMapData}
            stationName={stationName}
          />
        </Col>
      </Row>
    </div>
  );
};

const CreateStation: React.FC = (props: any) => {
  // console.log(props.location.state.action);
  const { action, id } = props.location.state;
  ACTION = action;
  ID = id;
  const MapLoader: any = withScriptjs(Wrapper);

  return (
    <MapLoader
      googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCY8tssCzJWXdG7BnWMr5EmiljzHL4zp0o&v=3.exp&libraries=geometry,drawing,places"
      loadingElement={<div style={{ height: `100%` }} />}
    />
  );
};

export default CreateStation;
