import React from "react";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import ModernAppLayout from "../layouts/ModernAppLayout";

import Dashboard from "./Dashboard";
import CreateUser from "./fleet/CreateUser";
import BookingLineHaul from "./fleet/BookingLineHaul";
import CreateDailyRoute from "./fleet/CreateDailyRoute";
import BookingDistribute from "./fleet/BookingDistribute";
import BookingData from "./fleet/BookingData";
import CreateStation from "./fleet/CreateStation";
import Station from "./fleet/Station";
import ManageStation from "./fleet/ManageStation";
import CreateScope from "./fleet/CreateScope";
import CreateTambonInScope from "./fleet/CreateTambonInScope";
import AddUserInStation from "./fleet/AddUserInStation";
import DataLineHaul from "./fleet/DataLineHaul";
import DataDistribute from "./fleet/DataDistribute";
import Result from "./fleet/Result";
import BookingTest from "./BookingQuickLineHall";

const AppRoute: React.FC<RouteComponentProps> = (props) => {
  return (
    <ModernAppLayout>
      <Switch>
        {/* dashboard */}
        <Route exact path="/app/dashboard" component={Dashboard} />

        {/* Fleet booking */}
        <Route
          exact
          path="/app/booking/addUserInStation"
          component={AddUserInStation}
        />

        <Route
          exact
          path="/app/booking/createTambonInScope"
          component={CreateTambonInScope}
        />
        <Route
          exact
          path="/app/booking/createStation"
          component={CreateStation}
        />
        <Route exact path="/app/booking/createScope" component={CreateScope} />
        <Route path="/app/booking/bookingData" component={BookingData} />
        <Route
          exact
          path="/app/booking/bookingLineHaul/:id"
          component={BookingLineHaul}
        />
        <Route
          exact
          path="/app/booking/dataLineHaul"
          component={DataLineHaul}
        ></Route>
        <Route
          exact
          path="/app/booking/bookingDistribute"
          component={BookingDistribute}
        />
        <Route
          exact
          path="/app/booking/dataDistribute"
          component={DataDistribute}
        ></Route>
        <Route exact path="/app/booking/createUser" component={CreateUser} />
        <Route
          exact
          path="/app/booking/createDailyRoute"
          component={CreateDailyRoute}
        />
        <Route exact path="/app/booking/station" component={Station} />
        <Route
          exact
          path="/app/booking/manageStation"
          component={ManageStation}
        />
        <Route exact path={"/app/booking/result"} component={Result} />
        <Redirect path="/app" to="/app/dashboard" />

        {/* officer */}
        {/* <Route exact path="/app/officers/new" component={NewOfficer} />
        <Route exact path="/app/officers/list" component={ListOfficer} />
        <Route exact path="/app/officers/edit/:id" component={EditOfficer} />
        <Route exact path="/app/officers/view/:id" component={ViewOfficer} />
        <Redirect path="/app/officers" to="/app/officers/list" /> */}

        {/* other */}
        <Redirect path="/app" to="/app/dashboard" />
      </Switch>
    </ModernAppLayout>
  );
};

export default AppRoute;
